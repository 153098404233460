import React, { useRef, useState } from 'react'
import { Card, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'
import "./index.css"
import axios from 'axios'
import toast from 'react-hot-toast'
import { ratingReview } from '../../../ApiConfigs/ApiConfig'
import terrible from '../../../assets/images/logo/terrible.png'
import okay from '../../../assets/images/logo/ok.png'
import bad from '../../../assets/images/logo/Bad.png'
import good from '../../../assets/images/logo/good.png'
import amazing from '../../../assets/images/logo/Amazing.png'
import upload from '../../../assets/images/logo/Layer_1.png';
import { X } from 'react-feather'
import { multiImageUpload } from '../../admin/upload'

const UpdateReviewModal = ({ openModal, handleClose, refetch, id, value }) => {

    const [confirmOrder, setConfirmOrder] = useState(false)
    console.log(value)
    const [reason, setReason] = useState('')
    const [selectedRating, setSelectedRating] = useState(null);
    const [profileImages, setProfileImages] = useState([]);
    const [load, setLoad] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const [loading, setLoading] = useState(false)
    const inputRef1 = useRef(null);

    const toggleModal = () => {
        handleClose()
        setReason('')
        setSelectedRating('')
        setProfileImages([])
    }

    const handleSubmit = async () => {
        const payload = {
            order: id,
            rating: selectedRating,
            review: reason,
            attachments: profileImages,
        }
        console.log(payload)
        // try {
        //     const response = await axios.post(`${ratingReview}`, payload)
        //     setReason('')
        //     setSelectedRating('')
        //     setProfileImages([])
        //     refetch()
        //     handleClose()
        // } catch (error) {
        //     toast.error(error?.response?.data?.msg);
        // }
    }

    const isButtonDisabled = selectedRating === null;

    const handleImageUpload = async (e, onChange) => {
        if (e.target.files) {
            try {
                setLoading(true);
                const formData = new FormData();
                const uploadedFiles = [];

                // Append files to FormData
                for (let i = 0; i < e.target.files.length; i++) {
                    formData.append('files', e.target.files[i]);
                }

                // Upload images (assuming multiImageUpload uploads the images and returns their URLs)
                const uploadData = await multiImageUpload(formData);

                console.log('Upload Data:', uploadData);  // Debug the response

                if (uploadData && uploadData.result && uploadData.result.length) {
                    // Collect uploaded file URLs
                    uploadData.result.forEach((file) => {
                        uploadedFiles.push(file.location);  // Assuming 'location' is the URL from the server response
                    });

                    // Update state to display the uploaded images
                    setProfileImages((prev) => [...prev, ...uploadedFiles]);

                    // Call onChange to update form state with URLs
                    onChange([...profileImages, ...uploadedFiles]);
                } else {
                    throw new Error('Invalid upload data response');  // Explicit error if the structure isn't right
                }
            } catch (error) {

            } finally {
                setLoading(false);
            }
        }
    };

    const handleRemoveImage = (index) => {
        const updatedImages = [...profileImages];
        updatedImages.splice(index, 1);
        setProfileImages(updatedImages);
        setSelectedImageIndex(null);
    };


    const ratings = [
        { id: 1, label: 'Terrible', image: terrible },
        { id: 2, label: 'Bad', image: bad },
        { id: 3, label: 'Okay', image: okay },
        { id: 4, label: 'Good', image: good },
        { id: 5, label: 'Amazing', image: amazing }
    ];

    return (
        <div>
            <Modal isOpen={openModal} toggle={toggleModal} className="modal-dialog-centered modal-lg" style={{ border: 'none' }}>
                {/* <ModalHeader toggle={handleClose} className="text-center modal-header-custom">
                    Help us improve
                </ModalHeader> */}
                <div className="d-flex align-items-center justify-content-between mb-1 px-1 ps-2 pe-2" style={{ backgroundColor: 'white', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', borderBottom: '1px solid #EEEEEE' }}>
                    <h4 className="modal-title text-center" style={{ marginTop: '23px', marginBottom: '23px', paddingLeft: '10px', fontFamily: 'roboto', color: '#2B2B2D' }}>
                        Help us improve
                    </h4>
                    <X style={{ color: 'black', cursor: 'pointer' }} className="addmodal-close fw-normal mt-25 me-2" size={25} onClick={toggleModal} />
                </div>
                <ModalBody className='p-0 m-0'>
                    <div className='mt-3'>
                        <div>
                            <p className='text-center' style={{ fontSize: '18px', fontFamily: 'Lato', fontWeight: '400', color: '#333333', opacity: '0.7' }}>How satisfied are you with the quality of the products you received?</p>
                        </div>
                        <div className='d-flex justify-content-between ps-5 pe-5 mt-4'>
                            {ratings.map((rating) => (
                                <div
                                    key={rating.id}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => setSelectedRating(rating.id)}
                                >
                                    <div
                                        className='d-flex align-items-center justify-content-center'
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                            borderRadius: '50%',
                                            border: '1px solid #E4510B',
                                            backgroundColor: selectedRating === rating.id ? '#FBD7C6' : 'transparent'
                                        }}
                                    >
                                        <img
                                            src={rating.image}
                                            style={{ width: '90px', height: '90px' }}
                                            alt={rating.label}
                                        />
                                    </div>
                                    <p
                                        className='text-center mt-1'
                                        style={{ fontSize: '18px', fontWeight: '700', fontFamily: 'Lato' }}
                                    >
                                        {rating.label}
                                    </p>
                                </div>
                            ))}
                        </div>
                        <div>
                            <FormGroup className='px-4 pt-4'>
                                <Label>
                                    Add Your Review
                                </Label>
                                <Input
                                    id="exampleSelect"
                                    name="select"
                                    type="textarea"
                                    style={{ height: '80px' }}
                                    placeholder='Write Your Comments'
                                    onChange={(e) => setReason(e.target.value)}
                                >
                                </Input>
                            </FormGroup>
                            <FormGroup className='px-4 '>
                                <Label>
                                    Upload Attachments
                                </Label>
                                <Card className="p-3 mt-1 mt-md-3 w-25" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: '2px dashed #DFDFDF' }}>
                                    <div>
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {profileImages.map((image, index) => (
                                                <div key={index} className="m-2" style={{ position: 'relative' }}>
                                                    <img
                                                        alt={`preview image ${index + 1}`}
                                                        src={image}
                                                        style={{
                                                            width: '100px',
                                                            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => setSelectedImageIndex(index)}
                                                    />
                                                    <div className="cursor-pointer image-close" style={{ position: 'absolute', top: '1px', right: '1px' }} onClick={() => handleRemoveImage(index)}>
                                                        <X style={{ width: '16px', height: '16px' }} />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="d-flex justify-content-center" style={{ cursor: 'pointer' }}>
                                            <input
                                                style={{ display: 'none' }}
                                                type="file"
                                                ref={inputRef1}
                                                accept=".jpeg,.png,.jpg,.jfif"
                                                onChange={(e) => handleImageUpload(e)}  // Pass onChange to handleImageUpload
                                                multiple
                                            />
                                            {loading ? (
                                                <Spinner />
                                            ) : (
                                                <div onClick={() => inputRef1.current.click()}>
                                                    {profileImages.length === 0 ? (
                                                        <div className='text-center'>
                                                            <img src={upload} width={40} height={40} alt="" />
                                                            <p style={{ color: '#2B2B2D', opacity: '0.8', fontWeight: '600' }}>Upload Attachment</p>
                                                        </div>
                                                    ) : (
                                                        <p style={{ color: '#333333', opacity: '0.8' }}>Add More Images</p>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Card>
                            </FormGroup>
                        </div>
                        <div className='text-center d-flex mt-4 p-4'>
                            <button
                                style={{
                                    width: '49%',
                                    border: '1px solid #E4510B',
                                    color: '#E4510B',
                                    background: 'white',
                                    borderRadius: '5px'
                                }}
                                onClick={toggleModal}
                                type='button'
                            >
                                <b>Cancel</b>
                            </button>
                            <button
                                className='ms-2'
                                style={{
                                    width: '49%',
                                    border: 'none',
                                    background: '#cccccc',  // Change color when disabled
                                    color: '#666666',  // Change text color when disabled
                                    borderRadius: '5px'
                                }}
                                type='submit'
                                disabled
                            // onClick={handleSubmit}
                            >
                                {load ? (
                                    <Spinner size="sm" color="light" />
                                ) : (
                                    <b>Update Review</b>
                                )}
                            </button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default UpdateReviewModal