import React, { useEffect, useState } from 'react'
import Navbar from '../navbar'
import FooterSection from '../footer'
import toast from 'react-hot-toast';
import axios from 'axios';
import { menuProduct } from '../../ApiConfigs/ApiConfig';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Card, Col, Spinner } from 'reactstrap';
import star from "../../assets/images/logo/Star 2.png"
import star5 from "../../assets/images/logo/Star 5.png"
import { FaAngleRight } from "react-icons/fa6";


function MenuProducts() {

    const [menuGetData, setMenuGetData] = useState([]);
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()



    const handleMenuData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${menuProduct}/${id}`);
            setMenuGetData(response?.data?.result);
        } catch (error) {
            toast.error(error?.response?.data?.msg)

        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        handleMenuData()
    }, [])

    const navToHome = () => {
        navigate('/');
    };

    const navToPage = (product_url) => {
        navigate(`/products/viewProduct/${product_url}`);
    };

    const navToViewPage = (product_url) => {
        navigate(`/products/viewProduct/${product_url}`);
    };

    return (
        <div>
            <Navbar />
            {loading ? (
                <div style={{ height: "80vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Spinner
                        color="primary"
                    >
                        Loading...
                    </Spinner>
                </div>
            ) : (
                <div className='pt-3 ' style={{ paddingLeft: '8%', paddingRight: '8%', paddingBottom: '10%' }}>
                    <p className='pt-3' style={{ cursor: 'pointer' }}><span onClick={navToHome}>Home</span> / <span >{menuGetData?.menu_url}</span></p>
                    {menuGetData?.category?.map(details => (
                        <div>
                            <div key={details?._id} className='mt-4'>
                                <div style={{ width: '100%' }} className="d-flex align-items-center mt-5">
                                    <h1 className="product-overall-title">
                                        {" "}
                                        {details?.productCategory?.name}
                                    </h1>
                                    <hr className='ms-3 call2' style={{ height: '2px', width: '100%', background: 'black' }} />
                                </div>
                            </div>
                            <div className='product-card-con mt-4'>
                                {details?.products?.map((eachItem, i) => (

                                    <Col sm={12} md={6} lg={4} xl={3} key={i}>
                                        <Card style={{ border: '1px solid #F0F0F0', borderRadius: '15px' }} key={eachItem?._id} onClick={() => navToPage(eachItem?.product_url)} className='mt-3'>
                                            <div>
                                                <div style={{ padding: '5%' }}>
                                                    <div style={{ width: '100%' }} className='d-flex'>
                                                        <div className='position-relative w-100'>
                                                            <img
                                                                src={eachItem?.image}
                                                                style={{
                                                                    borderRadius: "4px",
                                                                    height: '250px',
                                                                    width: "100%"
                                                                }}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="d-block mt-3">
                                                        <div className='mt-2'>
                                                            <div className="d-flex justify-content-between">
                                                                <div>
                                                                    <h6
                                                                        style={{
                                                                            fontSize: "18px",
                                                                            cursor: "pointer",
                                                                            whiteSpace: "nowrap",
                                                                            overflow: "hidden",
                                                                            textOverflow: "ellipsis",
                                                                            color: '#2B2B2D'
                                                                        }}
                                                                    >
                                                                        {eachItem?.name?.substring(0, 15)}{" "}
                                                                        {eachItem?.name?.length > 15 ? "..." : ""}
                                                                    </h6>
                                                                </div>
                                                                <div style={{ marginTop: '-3px' }}>
                                                                    <img src={star} alt="star1" />
                                                                    <img className='ms-1' src={star} alt="star2" />
                                                                    <img className='ms-1' src={star} alt="star3" />
                                                                    <img className='ms-1' src={star} alt="star4" />
                                                                    <img className='ms-1' src={star5} alt="star5" />
                                                                </div>
                                                            </div>
                                                            <p
                                                                style={{
                                                                    fontSize: "16px",
                                                                    cursor: "pointer",
                                                                    lineHeight: '24px',
                                                                    color: '#858585',
                                                                    whiteSpace: "nowrap",
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                }}
                                                            >
                                                                {eachItem?.productCategory?.name?.substring(0, 30)}{" "}
                                                                {eachItem?.productCategory?.name?.length > 30 ? "..." : ""}
                                                            </p>
                                                        </div>
                                                        <div className="mt-3">
                                                            <p
                                                                className="m-0"
                                                                style={{
                                                                    fontSize: "14px",
                                                                    color: '#37A003',
                                                                    whiteSpace: "nowrap",
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    maxWidth: "100%",
                                                                }}
                                                            >
                                                                As Low As
                                                            </p>
                                                            <div className='d-flex justify-content-between'>
                                                                <div className="d-flex flex-column ">
                                                                    <h2
                                                                        className=""
                                                                        style={{
                                                                            fontSize: `${eachItem?.mimimumAmount &&
                                                                                eachItem.mimimumAmount.toString().length > 1
                                                                                ? eachItem.mimimumAmount.toString().length > 4
                                                                                    ? "18px"
                                                                                    : "24px"
                                                                                : "24px"
                                                                                }`,
                                                                            textAlign: `${eachItem?.mimimumAmount &&
                                                                                eachItem.mimimumAmount.toString().length === 1
                                                                                ? "start"
                                                                                : "start"
                                                                                }`,
                                                                            color: '#37A003',
                                                                        }}
                                                                    >
                                                                        {/* ₹{eachItem?.mimimumAmount * eachItem.100} */}

                                                                        {eachItem?.offer === true ? ((eachItem?.offerAmount * eachItem.mimimumAmount || 0).toLocaleString('en-IN', {
                                                                            maximumFractionDigits: 0,
                                                                            style: 'currency',
                                                                            currency: 'INR',
                                                                        })) : ((eachItem?.mimimumAmount * eachItem.mimimumAmount || 0).toLocaleString('en-IN', {
                                                                            maximumFractionDigits: 0,
                                                                            style: 'currency',
                                                                            currency: 'INR',
                                                                        }))}

                                                                    </h2>

                                                                    <span style={{ color: '#858585', fontSize: "16px", display: 'flex', marginTop: '0px' }} className=''>For {eachItem.mimimumAmount} Pieces</span>
                                                                </div>
                                                                <div className="d-flex align-items-center">
                                                                    <Link
                                                                        to={`/products/viewProduct/${eachItem?.product_url}`}
                                                                        className="" style={{ textDecoration: 'none' }}
                                                                    >
                                                                        <button style={{ minWidth: '100px' }} className='ms-4 show-now' onClick={() => navToViewPage(eachItem?.product_url)}>Shop Now <FaAngleRight size={15} /></button>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <img src={eachItem?.image} className='img-fluid' alt="product-image" style={{ height: '200px', width: '100%' }} />
                                        <div style={{ backgroundColor: '#f3f3f3', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} className='p-3'>
                                            <div className='p-1'>
                                                <h6 className='text-center product-card-title'>{eachItem?.name}</h6>
                                                <p className='text-center product-card-description'><span className='fw-bold'>₹{eachItem?.amount}</span> each for 100 pieces</p>
                                            </div>
                                        </div> */}
                                        </Card>
                                    </Col>
                                ))}
                            </div>
                        </div>
                    ))}

                </div>
            )}
            <FooterSection />
        </div>
    )
}

export default MenuProducts