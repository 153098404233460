import React, { useEffect, useState } from 'react';
import { productApi, productCategory, productSats, productStatus, productTable } from '../../../ApiConfigs/ApiConfig';
import DataTable from 'react-data-table-component';
import { Edit2, Eye, Plus, RotateCcw, Trash } from 'react-feather';
import axios from 'axios';
import './index.css';
import { toast } from 'react-hot-toast';
import { Button, Col, Input, Modal, ModalBody, Spinner } from 'reactstrap';
import { useNavigate } from 'react-router';
import Navbar from '../../navbar';
import FooterSection from '../../footer';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import { GrPowerReset } from "react-icons/gr";
import { FiChevronRight } from 'react-icons/fi';
import { FaPlus } from 'react-icons/fa6';
import { BiEditAlt } from 'react-icons/bi';
import { GoTrash } from 'react-icons/go';

function ProductIndex() {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [getData, setGetData] = useState([]);
    const [data1, setData1] = useState([]);
    const [searchKey, setSearchKey] = useState('');
    const [enteredProductName, setEnteredProductName] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteData, setDeleteData] = useState();
    const [resetKey, setResetKey] = useState(0);
    const [sorts, setSorts] = useState('')
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const toggleEdit = (id) => {
        navigate(`/admin/editProduct/${id}`);
    };
    const openProductType = (id) => {
        navigate(`/admin/productType/${id}`);
    };


    const productSchema = yup.object().shape({
        productCategory: yup.string().required('Product category is required')
    });

    const { reset, control, handleSubmit, formState: { errors } } = useForm({
        mode: 'onChange',
        resolver: yupResolver(productSchema)
    });


    const productsrtat = async () => {
        try {

            const response = await axios.get(productSats)
            setData1(response?.data?.result)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    }
    const getProduct = async () => {
        try {

            const response = await axios.get(productCategory)
            setGetData(response?.data?.result)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    }
    useEffect(() => {
        getProduct()
        productsrtat()
    }, [])

    const options = getData?.map((item) =>
    (
        {
            label: item?.name,
            value: item?._id
        }))




    const additionalData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${productTable}?search=${searchKey}&currentPage=${currentPage > 0 ? currentPage - 1 : currentPage}&perPage=${rowsPerPage}&sortBy=${sorts}`)
            setData(response?.data?.result)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        additionalData(searchKey)
    }, [sorts, searchKey, currentPage, rowsPerPage])


    const updateStatus = async (id) => {

        try {
            const response = await axios.post(`${productStatus}/${id}`,);
            additionalData()
            toast.success(response?.data?.msg);
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    };

    const handlePagination = (page) => {
        setCurrentPage(page.selected + 1);
    };

    const openDeleteModal = (id) => {
        setDeleteData(id);
        setDeleteModal(true);
    }

    const closeDeleteModal = () => {
        setDeleteModal(false);
        setEnteredProductName('');

    }




    const handleConfirmDelete = async () => {
        try {
            const response = await axios.delete(`${productApi}/${deleteData?._id}?productName=${enteredProductName}`)
            closeDeleteModal()
            additionalData()
            setEnteredProductName('');
            toast.success(response?.data?.msg)
        } catch (error) {
            toast.error(error?.response?.msg)
        }

    }
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [debounceTimeout, setDebounceTimeout] = useState(null);


    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchKey(value);

        // Clear previous timeout if there was one
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        // Set a new timeout to call the API after 1 second
        const timeout = setTimeout(() => {
            setSearchKey(value);
        }, 1000);

        setDebounceTimeout(timeout);
    };

    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };

    const truncateDescription = (description, maxLength) => {
        return description.length > maxLength
            ? `${description.slice(0, maxLength)}...`
            : description;
    };

    const handleReset = () => {
        setSearchKey('')
        setSorts('');
        additionalData();
        setResetKey((prevKey) => prevKey + 1);
    };

    const productSortData = [
        {
            name: "latest",
            value: "latest"
        },
        {
            name: "oldest",
            value: "oldest"
        }
    ]

    const additionalTable = [
        {
            name: 'S No.',
            maxWidth: '20px',
            cell: (row, i) =>
                <>
                    <p style={{ fontSize: '16px', fontWeight: '400', fontFamily: 'Lato' }}>{(i + 1).toString().padStart(2, '0')}</p>
                </>
        },
        {
            name: 'Product Name',
            sortable: false,
            maxWidth: '250px',
            cell: row =>
                <>
                    <p style={{ fontSize: '16px', fontWeight: '400', fontFamily: 'Lato' }}>{row?.name}</p>
                </>
        },
        {
            name: 'Product Image & Description',
            sortable: false,
            maxWidth: '400px',
            cell: row =>
                <>
                    <div className='d-flex'>
                        <img className='d-flex align-items-center' src={row?.image} height={60} width={60} alt="" style={{ padding: '5px' }} />
                        <span className='mt-2 mb-2 ms-2' style={{ fontSize: '16px', fontWeight: '400', fontFamily: 'Lato' }}>
                            {showFullDescription ? row?.fullDescription : truncateDescription(row?.fullDescription, 40)}</span>
                    </div>
                </>,
        },
        // {
        //     name: 'Description',
        //     sortable: false,
        //     maxWidth: '500px',
        //     cell: row => <span onClick={toggleDescription} className='mt-2 mb-2' style={{ cursor: 'pointer' }}>
        //         {showFullDescription ? row?.fullDescription : truncateDescription(row?.fullDescription, 50)}</span>,
        // },
        // {
        //     name: 'Description',
        //     sortable: false,
        //     maxWidth: '150px',
        //     cell: row => <span onClick={() => navigate(`/admin/product-description/${row?._id}`)} style={{ cursor: 'pointer' }}><Plus /></span>
        // },
        {
            name: 'Category',
            sortable: false,
            maxWidth: '250px',
            cell: row =>
                <>
                    <p style={{ fontSize: '16px', fontWeight: '400', fontFamily: 'Lato' }}>{row?.productCategory?.name}</p>
                </>
        },

        {
            name: 'FAQ',
            sortable: false,
            maxWidth: '150px',
            cell: row =>
                <>
                    <div className='d-flex'>
                        {row?.FAQ?.length > 0 ? (
                            <p
                                style={{ fontSize: '16px', fontWeight: '400', fontFamily: 'Lato', background: '#F6F6F6', height: '40px' }}
                                className='d-flex justify-content-center align-items-center p-1'>
                                {row?.FAQ?.length}FAQ'S
                            </p>
                        ) : (
                            <p
                                style={{ fontSize: '16px', fontWeight: '400', fontFamily: 'Lato', background: '#F6F6F6', height: '40px' }}
                                className='d-flex justify-content-center align-items-center p-1'>
                                --FAQ'S
                            </p>
                        )}
                        {/* <span  style={{ cursor: 'pointer', background: '#E4510B24', width: '40px', height: '40px' }}><Plus style={{ color: '#E4510B' }} /></span> */}
                        <button style={{ background: '#E4510B24', border: 'none', width: '40px', height: '40px' }} onClick={() => navigate(`/admin/product/faq/${row?._id}`)}>
                            <Plus color='#E4510B' size={25} />
                        </button>
                    </div>
                </>
        },
        {
            name: 'Status',
            maxWidth: '150px',
            cell: (row) => (
                <>
                    {/* <div>
                        <label class="switch">
                            <input type="checkbox" checked />
                            <span class="slider"></span>
                        </label>
                        <span class="status-text">Active</span>
                    </div>
                    <div> */}

                    <div className="switch d-flex align-items-center">
                        <label className="switch-label">
                            <input
                                type="checkbox"
                                className="switch-input"
                                id="switch-success"
                                name="success"
                                // onChange={async (e) => {
                                //     // Directly update the status without confirmation
                                //     await updateStatus(row._id);
                                // }}
                                onChange={async (e) => {
                                    const result = window.confirm(
                                        `Are you sure you want to make as ${row.status === 'active' ? 'Inactive' : 'Active'}`
                                    );
                                    if (result) {
                                        await updateStatus(row._id);
                                    } else {

                                        e.target.checked = !e.target.checked;
                                    }
                                }}
                                defaultChecked={row.status === 'active'}
                            />

                            <span className="slider"></span>
                        </label>
                        <span
                            className="ms-2 d-flex"
                            style={{
                                color: row.status === 'active' ? 'green' : 'red',
                                fontWeight: '400',
                                fontSize: '14px',
                                flexDirection: 'row' // Ensure horizontal alignment
                            }}
                        >
                            {row.status === 'active' ? 'Active' : 'Inactive'}
                        </span>
                    </div>

                    {/* </div> */}


                </>
            )
        },
        {
            name: "Action",
            maxWidth: "250px",
            cell: (row) => (
                <>
                    {/* <div className='me-2' style={{ cursor: 'pointer', width: '50px', height: '50px' }}><Eye /></div> */}
                    <button className='ms-2' style={{ background: '#F7F7F7', border: 'none', width: '40px', height: '40px' }} onClick={() => openProductType(row?._id)}>
                        <Eye color='#4E4E4E' size={25} />
                    </button>
                    {/* <div className='Edit cursor-pointer me-2' style={{ cursor: 'pointer' }} onClick={() => toggleEdit(row?._id)}> <Edit2 size={20} /></div> */}
                    {/* <div style={{ cursor: 'pointer' }} onClick={() => openDeleteModal(row)}><Trash size={20} color='red' /></div> */}

                    <button className='ms-2' style={{ background: '#EEEEEE', border: 'none', width: '40px', height: '40px' }} onClick={() => toggleEdit(row?._id)}>
                        <BiEditAlt color='#4E4E4E' size={25} />
                    </button>
                    <button className='ms-2' style={{ background: '#D5383826', border: 'none', width: '40px', height: '40px' }} onClick={() => openDeleteModal(row)}>
                        <GoTrash color='#E42525' size={25} />
                    </button>
                </>
            )
        }
    ]
    const navToProduct = () => {
        navigate('/addProduct')
    }

    const orderStatsData = [
        {
            title: 'Total Categories',
            count: data1[0]?.totalCategories,
            icon: data1[0]?.icon,
            date: data1[0]?.lastUpdated ? new Date(data1[0]?.lastUpdated).toLocaleDateString() : 'N/A',
        },
        {
            title: 'Total Products',
            count: data1[1]?.totalProducts,
            icon: data1[1]?.icon,
            date: data1[1]?.lastUpdated ? new Date(data1[1]?.lastUpdated).toLocaleDateString() : 'N/A'
        },
        {
            title: 'Active Products',
            count: data1[2]?.activeProducts,
            icon: data1[2]?.icon,
            date: data1[2]?.lastUpdated ? new Date(data1[2]?.lastUpdated).toLocaleDateString() : 'N/A'
        },
        {
            title: 'Inactive Products',
            count: data1[3]?.inactiveProducts,
            icon: data1[3]?.icon,
            date: data1[3]?.lastUpdated ? new Date(data1[3]?.lastUpdated).toLocaleDateString() : 'N/A'
        }
    ]

    const CustomPagination = (pageCount) => {
        return (
            <>
                <ReactPaginate
                    nextLabel="Next"
                    breakLabel="..."
                    previousLabel="Prev"
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePagination}
                    containerClassName="pagination justify-content-end p-1"
                    activeClassName="active"
                    pageClassName="page-item"
                    breakClassName="page-item"
                    pageLinkClassName="page-link"
                    breakLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    forcePage={currentPage - 1}
                />
            </>
        );
    };

    const customStyles = {
        headCells: {
            style: {
                fontSize: '16px',
                background: '#FFEFE6',
                fontWeight: '600',
                fontFamily: 'Lato',
                color: '#000000',
            },
        },
        // Optionally, you can add other custom styles for rows, cells, etc.
    };


    return (
        <>
            <Navbar />
            <div style={{ background: '#F8F8F8' }}>
                <div className='pt-1' style={{ paddingLeft: '8%', paddingRight: '8%' }}>
                    <div>
                        <p className='pt-3' style={{ cursor: 'pointer', marginLeft: '10px', color: '#333333' }}><span style={{ opacity: '0.6' }} onClick={() => navigate('/')}>Home</span><span style={{ opacity: '0.6' }}><FiChevronRight /></span><span style={{ color: '#E4510B' }}>Product</span></p>
                    </div>
                    <div style={{ width: '100%' }} className='d-flex align-items-center'>
                        <h1 className='product-overall-title' style={{ font: 'Manrope', }}>Products</h1>
                        <hr className='ms-3 call2' style={{ height: '2px', width: '100%', background: 'black' }} />
                    </div>
                    <div style={{ paddingRight: '6%' }} className='d-flex mt-3'>
                        {orderStatsData?.map((item, i) => (
                            <Col lg={3} mg={6} sm={12} className={i !== 0 ? 'ms-4' : ''} key={i}>  {/* Apply 'ms-4' only for the 0th index */}
                                <div className='order-stats-card shadow-sm'>
                                    <h1 className='h6 mb-2'>{item?.title}</h1>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h1 className='h3 p-0 m-0'>{item?.count}</h1>
                                        <img src={item?.icon} height={50} alt={item?.title} />
                                    </div>
                                    <div className='mt-3 d-flex justify-content-between align-items-center'>
                                        <p className='p-0 m-0' style={{ color: '#A5A4A4', fontSize: '13px' }}>
                                            Updated on {item?.date}
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </div>
                </div>

                <div className='pt-5' style={{ paddingLeft: '8%', paddingRight: '8%', paddingBottom: '10%' }}>
                    <div className='pb-2 d-block' style={{ display: 'flex', marginBottom: '-30px', background: 'white', border: '1px solid #DFDFDF', borderRadius: '12px', height: '150px' }}>
                        <div style={{ borderBottom: '1px solid #DFDFDF' }}>
                            {/* <div className='d-flex align-items-center justify-content-between p-3'>
                                <div className='d-flex align-items-center'>
                                    <div className='red-line me-2'></div>
                                    <h1 className='p-0 m-0 h5'>Product List</h1>
                                </div>
                            </div>
                             */}
                            <div className='d-flex align-items-center justify-content-between p-3'>
                                <div className='d-flex align-items-center'>
                                    <div className='red-line me-2'></div>
                                    <h1 className='p-0 m-0 h5'>Product List</h1>
                                </div>
                                <span onClick={handleReset} className='cursor-pointer'><RotateCcw color='#E4510B' /></span>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between mb-4 p-3'>
                            <div className='d-flex gap-3'>
                                <div className='d-flex flex-column'>
                                    <label style={{ color: '#333333' }} className='fw-medium'>Search</label>
                                    <input
                                        type='text'
                                        className='search-order'
                                        placeholder='Search by product name'
                                        value={searchKey}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className='d-flex'>
                                    <div className='d-flex flex-column'>
                                        <label style={{ color: '#333333' }} className='fw-medium'>Sort By</label>
                                        <select
                                            className='select-order'
                                            value={sorts}
                                            onChange={(e) => setSorts(e.target.value)}
                                        >
                                            <option value='latest'>Latest</option>
                                            <option value='oldest'>Oldest</option>
                                            {/* Add more options as needed */}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <button className='productCategoryBtn mt-3' onClick={navToProduct}><Plus size={20} /> Add Product</button>
                        </div>
                        {/* <div style={{ display: 'flex' }}>
                                <form className="search me-2"
                                >
                                    <input
                                        type="text"
                                        id="search-bar"
                                        key={`status${resetKey}`}
                                        placeholder="Search Name"
                                        onChange={(e) => setSearchKey(e.target.value)}
                                        style={{ color: 'black', border: '1px solid #BEBEBE', paddingRight: '50px' }}
                                    />
                                    <a href="#"><img style={{ left: '-300px', color: '#333333', opacity: '0.5' }} className="search-icon" src="http://www.endlessicons.com/wp-content/uploads/2012/12/search-icon.png" /></a>
                                </form>
                            </div>
                            <div className="ms-4" >
                                <Select
                                    className="react-select me-2"
                                    styles={{
                                        menu: provided => ({ ...provided, zIndex: 9999, }),
                                        border: '1px solid #BEBEBE'
                                    }}
                                    key={`status${resetKey}`}
                                    type='select'
                                    options={productSortData?.map((item) => ({
                                        label: item?.name,
                                        value: item?.value
                                    }))}
                                    placeholder='Sort By'
                                    onChange={(selectedOption) => setSorts(selectedOption.value)}
                                />
                            </div>
                            <div style={{ marginRight: "15px", marginTop: '10px', color: '#e4510b' }}>
                                <GrPowerReset size={25} onClick={handleReset} />
                            </div>
                            <div style={{ marginLeft: 'auto' }}>
                                <button className='productCategoryBtn' onClick={navToProduct}><FaPlus /> Add Product</button>
                            </div> */}

                        {/* <div style={{ display: 'flex', marginBottom: '-30px' }}>
                            
                        <div className='total-count'> {data?.pagination?.total}</div>
                        <div className="ms-4">
                            <Select
                                className="react-select me-2"
                                styles={{
                                    menu: provided => ({ ...provided, zIndex: 9999, }),
                                }}
                                key={`status${resetKey}`}
                                type='select'
                                options={productSortData?.map((item) => ({
                                    label: item?.name,
                                    value: item?.value
                                }))}
                                placeholder='Sort By'
                                onChange={(selectedOption) => setSorts(selectedOption.value)}
                            />
                        </div>
                        <div style={{ marginRight: "20px", marginTop: '10px', color: '#e4510b' }}>
                            <GrPowerReset size={25} onClick={handleReset} />
                        </div>
                        <div style={{ marginLeft: 'auto', display: 'flex' }}>
                            <form className="search me-2">
                                <input
                                    type="text"
                                    id="search-bar"
                                    placeholder="Search Name"
                                    onChange={(e) => setSearchKey(e.target.value)}
                                    style={{ color: 'black', borderColor: 'black', paddingRight: '50px' }}
                                />
                                <a href="#"><img className="search-icon" src="http://www.endlessicons.com/wp-content/uploads/2012/12/search-icon.png" /></a>
                            </form>
                            <button className='productCategoryBtn' onClick={navToProduct} >Add Product</button>
                        </div>
                    </div> */}
                    </div>
                    {loading ? (
                        <div style={{ height: "50vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Spinner
                                color="primary"
                            >
                                Loading...
                            </Spinner>
                        </div>
                    ) : (
                        <div className='mt-5'>
                            <DataTable
                                pagination
                                paginationServer
                                highlightOnHover
                                fixedHeader
                                fixedHeaderScrollHeight="130vh"
                                data={data?.rows || []}
                                columns={additionalTable}
                                paginationDefaultPage={currentPage}
                                paginationComponent={() => (
                                    <CustomPagination
                                        totalPages={data?.pagination?.pages || 1}
                                        currentPage={currentPage}
                                        setPage={setCurrentPage}
                                        rowsPerPage={rowsPerPage}
                                        setRowsPerPage={setRowsPerPage}
                                    />
                                )}
                                customStyles={customStyles}
                            />

                        </div>

                    )}
                </div>
            </div >
            <Modal isOpen={deleteModal} toggle={closeDeleteModal} className="modal-dialog-centered modal-xs">
                <ModalBody style={{ fontSize: '20px', textAlign: 'center', paddingTop: '30px', fontWeight: '400' }}>
                    Are you sure you want to delete this Product<br />"<label>{deleteData?.name}</label>" ?
                    <p className='mt-4' style={{ fontSize: '17px' }}>Enter Your Product Name<span style={{ color: 'red' }}>*</span></p>
                    <input
                        type='text'
                        className='delete-modal-input mb-4'
                        placeholder=' Product Name'
                        value={enteredProductName}
                        onChange={(e) => setEnteredProductName(e.target.value)}
                        style={{ borderRadius: '8px' }}
                    />                </ModalBody>
                <div style={{ justifyContent: 'center' }} className="modal-footer">
                    <Button style={{ backgroundColor: "#E4510B", border: 'none' }} disabled={deleteData?.name !== enteredProductName} onClick={handleConfirmDelete} >
                        Confirm
                    </Button>
                    <Button color="secondary" onClick={closeDeleteModal} >
                        Cancel
                    </Button>
                </div>
            </Modal>
            <FooterSection />
        </>
    )
}

export default ProductIndex
