import React from 'react'
import Navbar from '../../navbar';
import FooterSection from '../../footer';
import { Col } from 'reactstrap';
import { Phone } from 'react-feather';
import categoryLeft from "../../../assets/images/banner/Category-left.png";
import categoryRight from "../../../assets/images/banner/Category-right.png";
import contact from "../../../assets/images/banner/contact.png";
import { IoMailOutline } from "react-icons/io5";


function ReachUs() {

    // const [data, setData] = useState()
    // const [open, setOpen] = useState('1');
    // const toggle = (id) => {
    //     if (open === id) {
    //         setOpen();
    //     } else {
    //         setOpen(id);
    //     }
    // };

    // const navigate = useNavigate()


    // const navToHome = () => {
    //     navigate('/')
    // }
    // const navToHelp = () => {
    //     navigate('/reachUs')
    // }

    // const testing = async () => {
    //     try {
    //         const response = await axios.get('http://192.168.0.141:6011/auth/rishwan')
    //         setData(response?.data?.result)
    //     } catch (error) {
    //         toast.error(error?.response?.data?.msg)

    //     }
    // }

    // useEffect(() => {
    //     testing()
    // })

    return (
        <div>
            <Navbar />
            <div className='about1'>
                <Col lg={12} md={12} sm={12}>
                    <div className='d-flex justify-content-center align-items-center'>
                        <div className='d-flex categ11 mt-5'>
                            <div>
                                <img className='img1' src={categoryLeft} alt="" />
                            </div>
                            <div className=' ms-2 me-2 mt-2 d-flex justify-content-center align-items-center'>
                                <h3 style={{ fontWeight: '400', fontSize: '40px', font: 'Manrope' }}>Contact Center</h3>
                            </div>
                            <div>
                                <img className='img1' src={categoryRight} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='aout1-pa mt-2'>
                        <p className='text-center'>Our customer support team is here to assist you. If you can't find the answers you're looking for in our Help Center, feel free to reach out to us <br className='br' /> directly. We're dedicated to providing prompt and helpful assistance.</p>
                    </div>
                </Col>
            </div>
            <div style={{ paddingLeft: '5%', paddingRight: '5%', paddingBottom: '11%', background: '#FAFAFA' }}>
                <div className='d-lg-flex'>
                    <Col lg={7} md={12} sm={12}>

                        <div className=' d-flex flex-column'>
                            <Col className='con' lg={12} md={12} sm={12}>
                                <div className='d-flex flex-column'>
                                    <div className='d-flex align-items-center mt-3'>
                                        <h4 className=' contact-head'>Call Us For  Immediate Assistance</h4>
                                        <hr className='ms-3 call' style={{ height: '2px', width: '50%', background: 'black' }} />
                                    </div>
                                    <p style={{ color: '#525252' }} className='mt-3'><Phone color='#E45D25' /> : +91 98960 66994</p>
                                    <p style={{ color: '#525252' }} className=''>(Mon - Sat: 10:00AM - 07:00PM)</p>
                                </div>
                            </Col>
                            <Col lg={12} md={12} sm={12}>
                                <div className='d-flex flex-column'>
                                    <div className='d-flex align-items-center mt-3'>
                                        <h4 className=' contact-head'>E-Mail Us</h4>
                                        <hr className='ms-3 call1' style={{ height: '2px', width: '77%', background: 'black' }} />
                                    </div>
                                    <p style={{ color: '#525252' }} className='mt-3'>Sales enquiries & Customer Support :</p>
                                    <p style={{ color: '#525252' }} className=' text-decoration-underline'><IoMailOutline size={30} color='#E45D25' /> <span style={{}}>Order@printon.co.in</span></p>
                                </div>
                            </Col>
                            <Col lg={12} md={12} sm={12}>
                                <div className='d-flex flex-column mt-3'>
                                    <div className='d-flex align-items-center'>
                                        <h4 className='contact-head'>Visit Us</h4>
                                        <hr className='ms-3 call1' style={{ height: '2px', width: '80%', background: 'black' }} />
                                    </div>
                                    <p style={{ color: '#525252' }} className='mt-3'>PrintOn Services Pvt.Ltd.</p>
                                    <p style={{ color: '#525252' }} className=''>No. 01/A, 5th A Cross, 24th Main, Manjunatha Colony,<br /> JP Nagar 2nd
                                        Phase, Bangalore, Bengaluru <br /> (Bangalore) Rural, Karnataka, 560078</p>
                                </div>
                            </Col>
                        </div>
                    </Col>
                    <Col lg={5} md={12} sm={12}>
                        <div className='contact-img mt-5'>
                            <img className='contact mt-3' src={contact} alt="" />
                        </div>
                    </Col>
                </div>
                {/* <ReportCard /> */}
            </div>
            <FooterSection />
        </div>
    )
}

export default ReachUs;
