import React, { useEffect, useState } from 'react'
import Navbar from '../../navbar'
import FooterSection from '../../footer'
import { FiChevronRight } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { Col, Input, Row } from 'reactstrap'
import axios from 'axios'
import { dashboardStats, orderPost, productStatus, productTable } from '../../../ApiConfigs/ApiConfig'
import toast from 'react-hot-toast'
import StatsCard from '../orders/StatsCard'
import { Eye, RotateCcw } from 'react-feather'
import DataTable from 'react-data-table-component'
import SalesChart from './LineChartSales'
import ColumnChartRevenue from './ColumnChartRevenue'

function NewDashboardIndex() {
    const navigate = useNavigate()
    const [statsData, setStatsData] = useState();
    const [data, setData] = useState([])
    const [productData, setProductData] = useState([])

    const getStatsData = async () => {
        try {
            const response = await axios.get(`${dashboardStats}`)
            setStatsData(response?.data?.result)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    }
    const getdata = async () => {
        try {
            const response = await axios.get(`${orderPost}`)
            setData(response?.data?.result?.rows)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    }

    useEffect(() => {
        getdata()
        getStatsData()
    }, [])

    const dataTable = [
        {
            name: 'Order ID',
            minWidth: '60px',
            cell: (row, i) => <span>{row?.orderId}</span>,
        },
        {
            name: 'Product',
            sortable: false,
            minWidth: '100px',
            cell: row => (<span>{row?.products[0]?.product?.name}</span>),
        },
        {
            name: 'Mobile',
            sortable: false,
            minWidth: '100px',
            cell: row => row?.shipping?.mobile,
        },
        {
            name: 'Ordered',
            sortable: false,
            minWidth: '100px',
            cell: row => new Date(row?.date).toLocaleDateString()
        },
        {
            name: 'Exp Delivery',
            sortable: false,
            minWidth: '100px',
            cell: row => new Date(row?.expectedDeliveryDate).toLocaleDateString()
        },

    ];


    const customStyles = {
        rows: {
            style: {
                minHeight: '39px !important', // Override the row height
            },
        },
        headCells: {
            style: {
                backgroundColor: '#FCEDE6',
                color: '#333333',
                fontWeight: '500',
                fontSize: '16px',
                padding: '10px !important'
            },
        },
        cells: {
            style: {
                padding: '8px !important',  // Adjust cell padding to reduce overall row height
            },
        },
    };


    const getProductData = async () => {
        try {
            const response = await axios.get(`${productTable}`)
            setProductData(response?.data?.result)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    }

    useEffect(() => {
        getProductData()
    }, [])

    const updateStatus = async (id) => {
        try {
            const response = await axios.post(`${productStatus}/${id}`);
            getProductData()
            toast.success(response?.data?.msg);
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    };

    const [showFullDescription, setShowFullDescription] = useState(false);

    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };

    const truncateDescription = (description, maxLength) => {
        return description.length > maxLength
            ? `${description.slice(0, maxLength)}...`
            : description;
    };

    const additionalTable = [
        {
            name: 'S No.',
            minWidth: '20px',
            cell: (row, i) =>
                <>
                    <p style={{ fontSize: '16px', fontWeight: '400', fontFamily: 'Lato' }}>{(i + 1).toString().padStart(2, '0')}</p>
                </>
        },
        {
            name: 'Product Name',
            sortable: false,
            minWidth: '180px',
            cell: row =>
                <>
                    <p style={{ fontSize: '16px', fontWeight: '400', fontFamily: 'Lato' }}>{row?.name}</p>
                </>
        },
        {
            name: 'Product Image & Description',
            sortable: false,
            minWidth: '250px',
            cell: row =>
                <>
                    <div className='d-flex'>
                        <img src={row?.image} height={60} width={60} alt="" style={{ padding: '5px' }} />
                        <span onClick={toggleDescription} className='mt-2 mb-2 ms-2' style={{ cursor: 'pointer', fontSize: '16px', fontWeight: '400', fontFamily: 'Lato' }}>
                            {showFullDescription ? row?.fullDescription : truncateDescription(row?.fullDescription, 60)}</span>
                    </div>
                </>,
        },
        {
            name: 'Category',
            sortable: false,
            minWidth: '220px',
            cell: row =>
                <>
                    <p style={{ fontSize: '16px', fontWeight: '400', fontFamily: 'Lato' }}>{row?.productCategory?.name}</p>
                </>
        },
        {
            name: 'Status',
            minWidth: '100px',
            cell: (row) => (
                <>
                    <div className="switch d-flex align-items-center">
                        <Input
                            type="switch"
                            id="switch-success"
                            name="success"
                            onChange={async (e) => {
                                const result = window.confirm(
                                    `Are you sure you want to make this ${row.status === 'active' ? 'Inactive' : 'Active'}?`
                                );
                                if (result) {
                                    await updateStatus(row._id);
                                } else {
                                    // Revert the switch if user cancels the action
                                    e.target.checked = !e.target.checked;
                                }
                            }}
                            defaultChecked={row.status === 'active'}
                        />
                        <span
                            className="ms-2"
                            style={{
                                color: row.status === 'active' ? 'green' : 'red',
                                fontWeight: 'bold',
                                fontSize: '16px'
                            }}
                        >
                            {row.status === 'active' ? 'Active' : 'Inactive'}
                        </span>
                    </div>


                </>
            )
        },
    ]

    const sampleWidth = [80, 60, 55, 40]

    return (
        <div>
            <Navbar />
            <div className='pt-5' style={{ paddingLeft: '8%', paddingRight: '8%', paddingBottom: '10%', backgroundColor: '#F8F8F8' }}>
                <div>
                    <p className='pt-3' style={{ cursor: 'pointer', marginLeft: '10px', color: '#333333' }}><span style={{ opacity: '0.6' }} onClick={() => navigate('/')}>Home</span><span style={{ opacity: '0.6' }}><FiChevronRight /></span><span style={{ color: '#E4510B' }}>Dashboard</span></p>
                </div>
                <div style={{ width: '100%' }} className='d-flex align-items-center mt-4'>
                    <h1 className='product-overall-title' style={{ font: 'Manrope', }}>Dashboard</h1>
                    <hr className='ms-3 call2' style={{ height: '2px', width: '100%', background: 'black' }} />
                </div>
                <div className='mt-3 s'>
                    <Row>
                        <Col sm={12} xl={6}>
                            <StatsCard md={6} xl={4} data={statsData} />
                        </Col>
                        <Col sm={12} xl={6}>
                            <div className='order-table-con  shadow-sm'>
                                <div className='d-flex align-items-center justify-content-between p-3'>
                                    <div className='d-flex align-items-center'>
                                        <div className='red-line me-2'></div>
                                        <h1 className='p-0 m-0 h5'>Recent Orders</h1>
                                    </div>
                                    <span onClick={() => navigate('/admin/order-management')} className='cursor-pointer' style={{ color: '#E4510B', textDecoration: 'underline' }}>View All</span>
                                </div>
                                <DataTable
                                    paginationServer
                                    noHeader
                                    data={data.slice(0, 5)}
                                    columns={dataTable}
                                    customStyles={customStyles}
                                />
                            </div>
                        </Col>
                        <Col sm={12} xl={6}>
                            <div className='order-table-con  shadow-sm mt-3'>
                                <div className='d-flex align-items-center justify-content-between p-3'>
                                    <div className='d-flex align-items-center'>
                                        <div className='red-line me-2'></div>
                                        <h1 className='p-0 m-0 h5'>Top Selling Categories</h1>
                                    </div>
                                    <span onClick={() => navigate('/admin/order-management')} className='cursor-pointer' style={{ color: '#E4510B', textDecoration: 'underline' }}>View All</span>
                                </div>
                                <div className='line-hr-new'></div>
                                <div className='p-3'>
                                    <div className='d-flex gap-3'>
                                        <img style={{ height: '62px', width: '62px', borderRadius: '4px' }} src="https://samsel.s3.ap-south-1.amazonaws.com/printonId%20Card%20%20and%20Lanyard.jpg" />
                                        <div className='w-100'>
                                            <div className='d-flex justify-content-between align-items-end'>
                                                <div>
                                                    <h1 className='fs-5 fw-normal p-0 m-0'>Books</h1>
                                                    <p style={{ fontSize: '14px', color: '#A5A4A4' }} className='p-0 m-0 mb-1'>Brouchers and Booklets</p>
                                                </div>
                                                <div className='mb-2 ps-2 pe-2 p-1' style={{ backgroundColor: '#F0F7F5', borderRadius: '4px', fontWeight: '500', color: '#52BBA7' }}>
                                                    {sampleWidth[0]}%
                                                </div>
                                            </div>
                                            <div style={{ height: '12px', width: '100%', backgroundColor: '#F0F7F5', borderRadius: '10px' }}>
                                                <div style={{ width: `${sampleWidth[0]}%`, height: '12px', backgroundColor: '#52BBA7', borderRadius: '10px' }}></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex gap-3 mt-3'>
                                        <img style={{ height: '62px', width: '62px', borderRadius: '4px' }} src="https://samsel.s3.ap-south-1.amazonaws.com/printonMetalic%20Gold%20Visiting%20Card.jpg" />
                                        <div className='w-100'>
                                            <div className='d-flex justify-content-between align-items-end'>
                                                <div>
                                                    <h1 className='fs-5 fw-normal p-0 m-0'>ID Cards</h1>
                                                    <p style={{ fontSize: '14px', color: '#A5A4A4' }} className='p-0 m-0 mb-1'>Brouchers and Booklets</p>
                                                </div>
                                                <div className='mb-2 ps-2 pe-2 p-1' style={{ backgroundColor: '#F1F0FB', borderRadius: '4px', fontWeight: '500', color: '#8276DF' }}>
                                                    {sampleWidth[1]}%
                                                </div>
                                            </div>
                                            <div style={{ height: '12px', width: '100%', backgroundColor: '#F1F0FB', borderRadius: '10px' }}>
                                                <div style={{ width: `${sampleWidth[1]}%`, height: '12px', backgroundColor: '#8276DF', borderRadius: '10px' }}></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex gap-3 mt-3'>
                                        <img style={{ height: '62px', width: '62px', borderRadius: '4px' }} src="https://samsel.s3.ap-south-1.amazonaws.com/bottle%20printing%20services.jpg" />
                                        <div className='w-100'>
                                            <div className='d-flex justify-content-between align-items-end'>
                                                <div>
                                                    <h1 className='fs-5 fw-normal p-0 m-0'>Steel Bottles</h1>
                                                    <p style={{ fontSize: '14px', color: '#A5A4A4' }} className='p-0 m-0 mb-1'>Brouchers and Booklets</p>
                                                </div>
                                                <div className='mb-2 ps-2 pe-2 p-1' style={{ backgroundColor: '#f9ebdf', borderRadius: '4px', fontWeight: '500', color: '#FFAB4F' }}>
                                                    {sampleWidth[2]}%
                                                </div>
                                            </div>
                                            <div style={{ height: '12px', width: '100%', backgroundColor: '#FADFC8', borderRadius: '10px' }}>
                                                <div style={{ width: `${sampleWidth[2]}%`, height: '12px', backgroundColor: '#FFAB4F', borderRadius: '10px' }}></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex gap-3 mt-3'>
                                        <img style={{ height: '62px', width: '62px', borderRadius: '4px' }} src="https://samsel.s3.ap-south-1.amazonaws.com/printonroll%20up%20standee%20near%20me.jpg" />
                                        <div className='w-100'>
                                            <div className='d-flex justify-content-between align-items-end'>
                                                <div>
                                                    <h1 className='fs-5 fw-normal p-0 m-0'>Roll-up standees</h1>
                                                    <p style={{ fontSize: '14px', color: '#A5A4A4' }} className='p-0 m-0 mb-1'>Brouchers and Booklets</p>
                                                </div>
                                                <div className='mb-2 ps-2 pe-2 p-1' style={{ backgroundColor: '#FFF2ED', borderRadius: '4px', fontWeight: '500', color: '#F87C59' }}>
                                                    {sampleWidth[3]}%
                                                </div>
                                            </div>
                                            <div style={{ height: '12px', width: '100%', backgroundColor: '#FFF2ED', borderRadius: '10px' }}>
                                                <div style={{ width: `${sampleWidth[3]}%`, height: '12px', backgroundColor: '#F87C59', borderRadius: '10px' }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} xl={6}>
                            <div className='order-table-con  shadow-sm mt-3'>
                                <div className='d-flex align-items-center justify-content-between p-3'>
                                    <div className='d-flex align-items-center'>
                                        <div className='red-line me-2'></div>
                                        <h1 className='p-0 m-0 h5'>Stats Overview</h1>
                                    </div>
                                    <span onClick={() => navigate('/admin/order-management')} className='cursor-pointer' style={{ color: '#E4510B', textDecoration: 'underline' }}>View All</span>
                                </div>
                                <div>
                                    <SalesChart />
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} xl={8}>
                            <div className='order-table-con  shadow-sm mt-3'>
                                <div className='d-flex align-items-center justify-content-between p-3'>
                                    <div className='d-flex align-items-center'>
                                        <div className='red-line me-2'></div>
                                        <h1 className='p-0 m-0 h5'>Product List</h1>
                                    </div>
                                    <span onClick={() => navigate('/admin/order-management')} className='cursor-pointer' style={{ color: '#E4510B', textDecoration: 'underline' }}>View All</span>
                                </div>
                                <DataTable
                                    data={productData?.rows || []}
                                    columns={additionalTable}
                                    customStyles={customStyles} // Add this line for custom styles
                                />
                            </div>
                        </Col>
                        <Col sm={12} xl={4}>
                            <div className='order-table-con  shadow-sm mt-3'>
                                <div className='d-flex align-items-center justify-content-between p-3'>
                                    <div className='d-flex align-items-center'>
                                        <div className='red-line me-2'></div>
                                        <h1 className='p-0 m-0 h5'>Order Overview</h1>
                                    </div>
                                    <span onClick={() => navigate('/admin/order-management')} className='cursor-pointer' style={{ color: '#E4510B', textDecoration: 'underline' }}>View All</span>
                                </div>
                                <div>
                                    <ColumnChartRevenue />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <FooterSection />
        </div >
    )
}

export default NewDashboardIndex
