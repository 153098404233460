import React, { useEffect, useState } from "react";
import star from "../../assets/images/logo/Star 2.png"
import star5 from "../../assets/images/logo/Star 5.png"
import { FaAngleRight } from "react-icons/fa6";
import {
  Card,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import "./index.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  product,
  productCategory,
} from "../../ApiConfigs/ApiConfig";
import axios from "axios";
import FooterSection from "../footer";
import Navbar from "../navbar";
import { ArrowRight } from "react-feather";
import emptyCart from "../../assets/images/banner/2875607_8503 [Converted].png";
import toast from "react-hot-toast";
import Demo_json from '../home/landingPage/Demo.json';
import Lottie from 'react-lottie'

function ProductType() {
  const [loading, setLoading] = useState(false);
  const [dataa, setData] = useState([]);
  const { id } = useParams();
  const [open, setOpen] = useState("1");

  // const toggleAccordion = (id) => {
  //   if (open === id) {
  //     setOpen();
  //   } else {
  //     setOpen(id);
  //   }
  // };

  const firstObject = dataa[0];

  const [categoryDataa, setCategorData] = useState();

  const categoryData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${productCategory}/${id}`);
      setCategorData(response?.data?.result);
    } catch (error) {
      toast.error(error?.response?.data?.msg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    categoryData();
  }, []);

  const additionalData = async () => {
    try {
      setLoading(true);
      let url = product;
      const response = await axios.get(`${url}?productCategory=${id}`);
      setData(response?.data?.result);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    additionalData();
  }, []);

  const navigate = useNavigate();

  // const navToPage = (dataa) => {
  //     const productName = dataa?.name.replace(/\s+/g, '-').toLowerCase(); // Replace spaces with hyphens and convert to lowercase
  //     const productId = dataa?._id;
  //     navigate(`/products/viewProduct/${productName}/${productId}`);
  // };
  // const navToPage = (product_url) => {
  //   navigate(`/products/viewProduct/${product_url}`);
  // };

  const navToHome = () => {
    navigate(`/`);
  };

  const navToProduct = () => {
    navigate("/products");
  };

  const [showFullDescription, setShowFullDescription] = useState(false);

  // const toggleDescription = () => {
  //   setShowFullDescription(!showFullDescription);
  // };

  // const truncateDescription = (description, maxLength) => {
  //   return description.length > maxLength
  //     ? `${description.slice(0, maxLength)}...`
  //     : description;
  // };

  // const truncateTitle = (title) => {
  //   return title.length > 70 ? `${title.slice(0, 70)}...` : title;
  // };

  const navToViewPage = (product_url) => {
    navigate(`/products/viewProduct/${product_url}`);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Demo_json,
  };

  return (
    <>
      <Navbar />
      {loading ? (
        <div
          style={{
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner color="primary">Loading...</Spinner>
        </div>
      ) : (
        <div
          className="pt-3"
          style={{ paddingLeft: "5%", paddingRight: "5%", paddingBottom: '10%' }}
        >
          <p className="pt-3" style={{ cursor: "pointer", color: '#333333', opacity: '0.8' }}>
            <span onClick={navToHome}>Home</span> /{" "}
            <span onClick={navToProduct}>Products</span> /{" "}
            <span style={{ color: '#E4510B', opacity: '1' }}>{firstObject?.productCategory?.name}</span>{" "}
          </p>
          {/* <div className="d-flex align-items-center mt-5">
            <h1 className="product-overall-title">
              {" "}
              {firstObject?.productCategory?.name}
            </h1>
            <hr className='ms-3 call2' style={{ height: '2px', width: '70%', background: 'black' }} />
          </div> */}
          {dataa.length === 0 ? (
            <div>
              <h2 className="text-center pt-3 mb-5" style={{ color: "grey" }}>
                "Oops! It seems like we're out of stock in this category. <br />{" "}
                Explore other categories or visit us again soon."
              </h2>
              <div className="d-flex justify-content-center">
                <img src={emptyCart} style={{ height: "250px" }} alt="empty image" />
              </div>
              <div className="d-flex justify-content-center mt-3">
                <Link to="/products">
                  <button className="success-modal-btn">
                    Continue Shopping <ArrowRight size={17} />
                  </button>
                </Link>
              </div>
            </div>
          ) : (
            <>
              <div style={{ width: '100%' }} className="d-flex align-items-center mt-5">
                <h1 className="product-overall-title a">
                  {" "}
                  {firstObject?.productCategory?.name}
                </h1>
                <hr className='ms-3 call2' style={{ height: '2px', width: '100%', background: 'black' }} />
              </div>
              <div className="product-card-con">
                <Row>
                  {dataa?.map((eachItem, index) => (
                    <Col sm={12} md={6} lg={4} xl={3} key={index} className="mb-4">
                      <Card
                        onClick={() => navigate(`/products/viewProduct/${eachItem?.product_url}`)}
                        className="fast-card"
                        style={{
                          border: "none",
                          marginTop: "4%",
                          width: '100%',
                          cursor: 'pointer'
                        }}
                      >
                        <div className='ms-lg-3 mt-4' style={{ width: '100%', border: '1px solid #F0F0F0', borderRadius: '10px', boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)" }}>
                          <div style={{ padding: '5%' }}>
                            <div style={{ width: '100%' }} className='d-flex'>
                              <div className='position-relative w-100'>
                                <img
                                  src={eachItem?.image}
                                  style={{
                                    borderRadius: "4px",
                                    height: '250px',
                                    width: "100%"
                                  }}
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="d-block mt-3">
                              <div className='mt-2'>
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <h6
                                      style={{
                                        fontSize: "18px",
                                        cursor: "pointer",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        color: '#2B2B2D'
                                      }}
                                    >
                                      {eachItem?.name?.substring(0, 15)}{" "}
                                      {eachItem?.name?.length > 15 ? "..." : ""}
                                    </h6>
                                  </div>
                                  <div style={{ marginTop: '-5px' }}>
                                    <img src={star} alt="star1" />
                                    <img className='ms-1' src={star} alt="star2" />
                                    <img className='ms-1' src={star} alt="star3" />
                                    <img className='ms-1' src={star} alt="star4" />
                                    <img className='ms-1' src={star5} alt="star5" />
                                  </div>

                                </div>
                                <p
                                  style={{
                                    fontSize: "16px",
                                    cursor: "pointer",
                                    lineHeight: '24px',
                                    color: '#858585',
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {eachItem?.productCategory?.name?.substring(0, 30)}{" "}
                                  {eachItem?.productCategory?.name?.length > 30 ? "..." : ""}
                                </p>
                              </div>
                              {/* <div style={{ marginTop: '-10px' }}>
                            <img src={star} alt="star1" />
                            <img className='ms-1' src={star} alt="star2" />
                            <img className='ms-1' src={star} alt="star3" />
                            <img className='ms-1' src={star} alt="star4" />
                            <img className='ms-1' src={star5} alt="star5" />
                          </div> */}
                              <div className="mt-3">
                                <p
                                  className="m-0"
                                  style={{
                                    fontSize: "14px",
                                    color: '#37A003',
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    maxWidth: "100%",
                                  }}
                                >
                                  As Low As
                                </p>
                                <div className='d-flex justify-content-between'>
                                  <div className="d-block">
                                    <div className="d-flex">
                                      <h2
                                        className=""
                                        style={{
                                          fontSize: `${eachItem?.mimimumAmount &&
                                            eachItem.mimimumAmount.toString().length > 1
                                            ? eachItem.mimimumAmount.toString().length > 4
                                              ? "18px"
                                              : "24px"
                                            : "24px"
                                            }`,
                                          textAlign: `${eachItem?.mimimumAmount &&
                                            eachItem.mimimumAmount.toString().length === 1
                                            ? "center"
                                            : "center"
                                            }`,
                                          color: '#37A003'
                                        }}
                                      >
                                        {/* ₹{eachItem?.mimimumAmount * eachItem.100} */}

                                        {eachItem?.offer === true ? ((eachItem?.offerAmount * eachItem?.minimunQuantity || 0).toLocaleString('en-IN', {
                                          maximumFractionDigits: 0,
                                          style: 'currency',
                                          currency: 'INR',
                                        })) : ((eachItem?.mimimumAmount * eachItem?.minimunQuantity || 0).toLocaleString('en-IN', {
                                          maximumFractionDigits: 0,
                                          style: 'currency',
                                          currency: 'INR',
                                        }))}

                                      </h2>
                                      {eachItem?.offer === true ? (<span style={{ fontSize: "16px", display: 'flex', marginTop: '2px' }} className='ms-2 old-price'>{(eachItem?.mimimumAmount * eachItem?.minimunQuantity).toLocaleString('en-IN', {
                                        maximumFractionDigits: 0,
                                        style: 'currency',
                                        currency: 'INR',
                                      })}</span>) : (null)}
                                    </div>

                                    <span style={{ color: '#858585', fontSize: "16px", display: 'flex' }} className=''>For {eachItem?.minimunQuantity} Pieces</span>
                                  </div>
                                  <Link
                                    to={`/products/viewProduct/${eachItem?.product_url}`}
                                    className="" style={{ textDecoration: 'none' }}
                                  >
                                    <div className="mt-4" style={{ width: '100%' }}>
                                      <button className='ms-4 ' style={{ width: '100px', height: '40px', marginTop: '-30px', fontSize: "14px", fontWeight: '700', font: 'Lato', color: 'white', background: '#E4510B', borderRadius: '4px', border: 'none', alignItems: 'start' }} onClick={() => navToViewPage(eachItem?.product_url)}>Shop Now<FaAngleRight size={15} /></button>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </Col>
                    // </Link>
                  ))}
                </Row>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center ">
                <h1 className="fs-3 mb-4 lg-mb-5 text-center">Discover How to Customize Your Design Like a Pro!</h1>
                <div className="animate-video-width mb-5">
                  <div>
                    <Lottie style={{ borderRadius: '15px' }} options={defaultOptions} className="w-full" />
                  </div>
                </div>
              </div>
            </>
          )}
        </div >
      )
      }

      <div>
        {/* <InteractiveHome /> */}
        <FooterSection />
      </div>
    </>
  );
}

export default ProductType;
