import React, { useRef, useState } from 'react'
import * as yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { X } from 'react-feather'
import toast from 'react-hot-toast';
import upload from '../../../../assets/images/logo/Layer_1.png';
import axios from 'axios';
import { Button, Card, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'
import { multiImageUpload } from '../../upload/index';
import { orderStatus } from '../../../../ApiConfigs/ApiConfig';
import Select, { } from 'react-select';

const ChangestatusPage = ({ handlechange, open, getdata, dataId, status, address, deliveryDate1 }) => {
    // const { id } = useParams();

    const formSchema = yup.object().shape({

    });


    const [profileImages, setProfileImages] = useState([]);
    const [load, setLoad] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const [loading, setLoading] = useState(false)
    const inputRef1 = useRef(null);
    const [deliveryDate, setDeliveryDate] = useState(deliveryDate1 || '');
    const {
        control,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm({ mode: 'onChange', resolver: yupResolver(formSchema) });

    const watchedFields = watch(["type", "logisticName", "trackingNumber", "weight", "weightType", "date", "amount", "pickupLocation", "deliveryAddress"]);

    // const isButtonDisabled = !watchedFields.every(field => field !== "" && field !== undefined) || load;
    const isButtonDisabled = status === "process"
        ? !watchedFields.every(field => field !== "" && field !== undefined) || load
        : load;


    const shippingFrom = {
        companyName: "Printon India pvt ltd",
        mobile: "6379714874",
        email: "email@example.com",
        address: "1/335, Boyampalayam",
        locality: "North Area",
        landmark: "Saran Medical Center",
        city: "Bangalore",
        state: "Karnataka",
        pincode: "641602"
    };

    const pickupLocation = {
        companyName: `${shippingFrom?.companyName}`,
        mobile: `${shippingFrom?.mobile}`,
        email: `${shippingFrom?.email}`,
        address: `${shippingFrom?.address}`,
        locality: `${shippingFrom?.locality}`,
        landmark: `${shippingFrom?.landmark}`,
        city: `${shippingFrom?.city}`,
        state: `${shippingFrom?.state}`,
        pincode: `${shippingFrom?.pincode}`,

    }

    const deliveryLocation = {
        firstName: `${address?.firstName}`,
        lastName: `${address?.lastName}`,
        mobile: `${address?.mobile}`,
        email: `${address?.email}`,
        address: `${address?.address}`,
        locality: `${address?.locality}`,
        landmark: `${address?.landmark}`,
        city: `${address?.city}`,
        state: `${address?.state}`,
        pincode: `${address?.pincode}`,
    }

    const submitForm = async (data) => {
        setLoad(true); // Start loading spinner

        let payload = {};

        switch (status) {
            case "ordercompleted":
                payload = {
                    status: 'process',
                    // deliveryDate: data?.date,
                };
                break;
            case "process":
                payload = {
                    status: 'shipped',
                    type: data?.type?.value,
                    logisticName: data?.logisticName,
                    weight: data?.weight,
                    weightType: data?.weightType?.value,
                    trackingNumber: data?.trackingNumber,
                    amount: data?.amount,
                    pickupLocation: pickupLocation,
                    deliveryAddress: deliveryLocation,
                    deliveryDate: deliveryDate,
                    attachments: data?.attachments,
                };
                break;
            case "shipped":
                payload = {
                    status: 'delivered',
                };
                break;
            default:
                toast.error("Invalid status provided");
                setLoad(false); // Stop loading spinner if invalid status
                return;
        }

        try {
            const response = await axios.post(`${orderStatus}/${dataId}`, payload);
            toast.success(response?.data?.msg);
            handlechange();
            getdata();
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        } finally {
            setLoad(false); // Stop loading spinner after form submission
        }
    };

    const options1 = [
        {
            label: "Manual",
            value: "manual"
        },
        {
            label: "Automatic",
            value: "automatic"
        }
    ];

    const weightType = [
        {
            label: "gm",
            value: "gm"
        },
        {
            label: "Kg",
            value: "kg"
        }
    ];

    const handleImageUpload = async (e, onChange) => {
        if (e.target.files) {
            try {
                setLoading(true);
                const formData = new FormData();
                const uploadedFiles = [];

                // Append files to FormData
                for (let i = 0; i < e.target.files.length; i++) {
                    formData.append('files', e.target.files[i]);
                }

                // Upload images (assuming multiImageUpload uploads the images and returns their URLs)
                const uploadData = await multiImageUpload(formData);

                if (uploadData && uploadData.result && uploadData.result.length) {
                    // Collect uploaded file data with URLs
                    uploadData.result.forEach((file) => {
                        uploadedFiles.push({
                            name: file.originalname,
                            url: file.location,  // This is the URL from the server response
                        });
                    });

                    // Update state to display the uploaded images
                    setProfileImages(prev => [...prev, ...uploadedFiles]);

                    // Call onChange to update form state with URLs
                    const imageUrls = uploadedFiles.map(file => file.url);
                    onChange([...profileImages.map(image => image.url), ...imageUrls]);  // Update form with URLs
                }
            } catch (error) {
                toast.error('Something went wrong...');
            } finally {
                setLoading(false);
            }
        }
    };


    const handleRemoveImage = (index) => {
        const updatedImages = [...profileImages];
        updatedImages.splice(index, 1);
        setProfileImages(updatedImages);
        setSelectedImageIndex(null);
    };


    return (
        <div>
            <Modal isOpen={open} toggle={handlechange} className='sidebar-lg' modalClassName='modal-slide-in sidebar-todo-modal' contentClassName='p-0' size={status === 'process' ? 'md' : 'sm'} >
                <ModalHeader toggle={handlechange}>
                    <div className='d-flex align-items-center justify-content-between mb-1 px-1' style={{ backgroundColor: '#ffff' }}>
                        <p className='modal-title' style={{ fontFamily: 'Lato', fontSize: '20px', fontWeight: '400' }}>
                            <b>Confirm Status Update</b>
                        </p>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit(submitForm)}>
                        {status === "ordercompleted" &&
                            <div className='d-flex flex-column '>
                                {/* <div className='mb-2 d-flex flex-column justify-content-center w-100'>
                                    <Label style={{ color: '#333333' }} className='w-100'>Select Expected Delivery Date:</Label>
                                    <Controller
                                        name='date'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input
                                                className='w-100'
                                                {...field}
                                                type="date"
                                                style={{
                                                    width: '290px',
                                                    borderRadius: '10px',
                                                    border: '3px solid #ebeff1',
                                                    background: '#ebeff1',
                                                }}
                                                invalid={errors.date && true}
                                                placeholder="Select Date"
                                            />
                                        )}
                                    />
                                    {errors.date && <FormFeedback>{errors.date.message}</FormFeedback>}
                                </div> */}
                                <div className='d-bloick mt-2'>
                                    <p style={{ color: '#D34C4D', fontWeight: '600', fontFamily: 'Manrope', fontSize: '16px' }}>Note*</p>
                                    <p style={{ color: '#333333', opacity: '0.8', fontSize: '16px', fontWeight: '400' }}>Are you sure you want to update the order status to 'Processing'?</p>
                                </div>
                            </div>
                        }

                        {status === "process" &&
                            <div className='d-flex flex-column '>

                                <div className='mb-2 d-flex flex-column justify-content-center w-100'>
                                    <Label style={{ color: '#333333' }} className='w-100'>Shipping Type:</Label>
                                    <Controller
                                        name='type'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Select
                                                isSearchable={false}
                                                style={{
                                                    width: '290px',
                                                    borderRadius: '10px',
                                                    border: '3px solid #ebeff1',
                                                    background: '#ebeff1',
                                                }}
                                                type="select"
                                                {...field}
                                                options={options1}
                                                invalid={errors.gstType && true}
                                            >
                                            </Select>
                                        )}
                                    />
                                    {errors.date && <FormFeedback>{errors.type.message}</FormFeedback>}
                                </div>
                                <div className='mb-2 d-flex flex-column justify-content-center w-100'>
                                    <Label style={{ color: '#333333' }} className='w-100'>Enter Logistic Name:</Label>
                                    <Controller
                                        name='logisticName'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input
                                                className='w-100'
                                                {...field}
                                                type="text"
                                                style={{
                                                    width: '290px',
                                                    borderRadius: '5px',
                                                    border: '1px solid #DFDFDF',
                                                    background: 'white',
                                                }}
                                                invalid={errors.logisticName && true}
                                                placeholder="Enter Logistic Name"
                                            />
                                        )}
                                    />
                                    {errors.logisticName && <FormFeedback>{errors.logisticName.message}</FormFeedback>}
                                </div>
                                <div className='mb-2 d-flex flex-column justify-content-center w-100'>
                                    <Label style={{ color: '#333333' }} className='w-100'>Tracking Number</Label>
                                    <Controller
                                        name='trackingNumber'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input
                                                className='w-100'
                                                {...field}
                                                type="text"
                                                style={{
                                                    width: '290px',
                                                    borderRadius: '5px',
                                                    border: '1px solid #DFDFDF',
                                                    background: 'white',
                                                }}
                                                invalid={errors.trackingNumber && true}
                                                placeholder="Enter Tracking ID"
                                            />
                                        )}
                                    />
                                    {errors.trackingNumber && <FormFeedback>{errors.trackingNumber.message}</FormFeedback>}
                                </div>
                                <div className='mb-2 d-flex flex-column justify-content-center w-100'>
                                    <Label style={{ color: '#333333' }} className='w-100'>Weight:</Label>
                                    <div className='d-flex'>
                                        <Controller
                                            name='weight'
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <Input
                                                    {...field}
                                                    type="number"
                                                    style={{
                                                        width: '200px',
                                                        borderRadius: '5px',
                                                        border: '1px solid #DFDFDF',
                                                        background: 'white',
                                                    }}
                                                    invalid={errors.weight && true}
                                                    placeholder="Enter Product Weight"
                                                />
                                            )}
                                        />
                                        {errors.weight && <FormFeedback>{errors.weight.message}</FormFeedback>}
                                        <Controller
                                            name='weightType'
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <Select
                                                    className='ms-2'
                                                    isSearchable={false}
                                                    style={{
                                                        width: '90px',
                                                        borderRadius: '5px',
                                                        border: '1px solid #DFDFDF',
                                                        background: 'white',
                                                    }}
                                                    type="select"
                                                    {...field}
                                                    options={weightType}
                                                    invalid={errors.weightType && true}
                                                    placeholder="Select Unit"
                                                >
                                                </Select>
                                            )}
                                        />
                                        {errors.weightType && <FormFeedback>{errors.weightType.message}</FormFeedback>}
                                    </div>

                                </div>
                                <div className='mb-2 d-flex flex-column justify-content-center w-100'>
                                    <Label style={{ color: '#333333' }} className='w-100'>Delivery Date:</Label>
                                    <Controller
                                        name='date'
                                        control={control}
                                        defaultValue={deliveryDate1}
                                        render={({ field }) => (
                                            <Input
                                                className='w-100'
                                                {...field}
                                                type="date"
                                                value={deliveryDate}
                                                onChange={(e) => {
                                                    setDeliveryDate(e.target.value); // Update state
                                                    field.onChange(e.target.value); // Update form state
                                                }}
                                                style={{
                                                    width: '290px',
                                                    borderRadius: '5px',
                                                    border: '1px solid #DFDFDF',
                                                    background: 'white',
                                                }}
                                                invalid={errors.date ? true : false}
                                                placeholder="Select Date"
                                            />
                                        )}
                                    />
                                    {errors.date && <FormFeedback>{errors.date.message}</FormFeedback>}
                                </div>
                                <div className='mb-2 d-flex flex-column justify-content-center w-100'>
                                    <Label style={{ color: '#333333' }} className='w-100'>Amount:</Label>
                                    <Controller
                                        name='amount'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input
                                                className='w-100'
                                                {...field}
                                                type="number"
                                                style={{
                                                    width: '290px',
                                                    borderRadius: '5px',
                                                    border: '1px solid #DFDFDF',
                                                    background: 'white',
                                                }}
                                                invalid={errors.amount && true}
                                                placeholder="Enter Shipping Amount"
                                            />
                                        )}
                                    />
                                    {errors.amount && <FormFeedback>{errors.amount.message}</FormFeedback>}
                                </div>
                                <div className='mb-2 d-flex flex-column justify-content-center w-100'>
                                    <Label style={{ color: '#333333' }} className='w-100'>Shipping From:</Label>
                                    <Controller
                                        name='pickupLocation'
                                        control={control}
                                        defaultValue={`${shippingFrom?.companyName || ''}, ${shippingFrom?.mobile || ''}, ${shippingFrom?.email || ''}, ${shippingFrom?.address || ''}, ${shippingFrom?.locality || ''}, ${shippingFrom?.landmark || ''}, ${shippingFrom?.city || ''}, ${shippingFrom?.state || ''}, ${shippingFrom?.pincode || ''}`}
                                        render={({ field }) => (
                                            <Input
                                                className='w-100'
                                                {...field}
                                                value={field.value || `${shippingFrom?.companyName || ''}, ${shippingFrom?.mobile || ''}, ${shippingFrom?.email || ''}, ${shippingFrom?.address || ''}, ${shippingFrom?.locality || ''}, ${shippingFrom?.landmark || ''}, ${shippingFrom?.city || ''}, ${shippingFrom?.state || ''}, ${shippingFrom?.pincode || ''}`}
                                                readOnly
                                                type="textarea"
                                                style={{
                                                    width: '290px',
                                                    borderRadius: '5px',
                                                    height: '150px',
                                                    border: '1px solid #DFDFDF',
                                                    background: 'white',
                                                }}
                                                invalid={errors.pickupLocation && true}
                                                placeholder="Enter Logistic Name"
                                            />
                                        )}
                                    />
                                    {errors.pickupLocation && <FormFeedback>{errors.pickupLocation.message}</FormFeedback>}
                                </div>

                                <div className='mb-2 d-flex flex-column justify-content-center w-100'>
                                    <Label style={{ color: '#333333' }} className='w-100'>Shipping To:</Label>
                                    <Controller
                                        name='deliveryAddress'
                                        control={control}
                                        defaultValue={`${address?.firstName || ''}, ${address?.lastName || ''},${address?.mobile || ''}, ${address?.email || ''}, ${address?.address || ''}, ${address?.locality || ''}, ${address?.landmark || ''}, ${address?.city || ''}, ${address?.state || ''}, ${address?.pincode || ''}`}
                                        render={({ field }) => (
                                            <Input
                                                className='w-100'
                                                {...field}
                                                value={field.value || `${address?.firstName || ''}, ${address?.lastName || ''},${address?.mobile || ''}, ${address?.email || ''}, ${address?.address || ''}, ${address?.locality || ''}, ${address?.landmark || ''}, ${address?.city || ''}, ${address?.state || ''}, ${address?.pincode || ''}`}
                                                readOnly
                                                type="textarea"
                                                style={{
                                                    width: '290px',
                                                    height: '130px',
                                                    borderRadius: '5px',
                                                    border: '1px solid #DFDFDF',
                                                    background: 'white',
                                                }}
                                                invalid={errors.deliveryAddress && true}
                                                placeholder="Enter Logistic Name"
                                            />
                                        )}
                                    />
                                    {errors.deliveryAddress && <FormFeedback>{errors.deliveryAddress.message}</FormFeedback>}
                                </div>
                                <div className='mb-2 d-flex flex-column justify-content-center w-100'>
                                    <Label style={{ color: '#333333' }} className='w-100'>Attachment:</Label>
                                    <Controller
                                        name="attachments"
                                        control={control}
                                        defaultValue={[]}  // Default as empty array
                                        render={({ field: { onChange } }) => (
                                            <Card className="p-3 mt-1 mt-md-3" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <div>
                                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                        {profileImages.map((image, index) => (
                                                            <div key={index} className="m-2" style={{ position: 'relative' }}>
                                                                <img
                                                                    alt={`preview image ${index + 1}`}
                                                                    src={image.url}
                                                                    style={{
                                                                        width: '100px',
                                                                        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    onClick={() => setSelectedImageIndex(index)}
                                                                />
                                                                <div className="cursor-pointer image-close" style={{ position: 'absolute', top: '1px', right: '1px' }} onClick={() => handleRemoveImage(index)}>
                                                                    <X style={{ width: '16px', height: '16px' }} />
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="d-flex justify-content-center" style={{ cursor: 'pointer' }}>
                                                        <input
                                                            style={{ display: 'none' }}
                                                            type="file"
                                                            ref={inputRef1}
                                                            accept=".jpeg,.png,.jpg,.jfif"
                                                            onChange={(e) => handleImageUpload(e, onChange)}  // Pass onChange to handleImageUpload
                                                            multiple
                                                        />
                                                        {loading ? (
                                                            <Spinner />
                                                        ) : (
                                                            <div onClick={() => inputRef1.current.click()}>
                                                                {profileImages.length === 0 ? (
                                                                    <div className='text-center'>
                                                                        <img src={upload} width={40} height={40} alt="" />
                                                                        <p style={{ color: '#2B2B2D', opacity: '0.8', fontWeight: '600' }}>Upload Image</p>
                                                                        <p style={{ color: '#333333', opacity: '0.8' }}>Supported file formats : PNG, JPG, PDF</p>
                                                                        <p style={{ color: '#333333', opacity: '0.8' }}>(Maximum 05 MB /Minimum 224px x 224px)</p>
                                                                    </div>
                                                                ) : (
                                                                    <p style={{ color: '#333333', opacity: '0.8' }}>Add More Images</p>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </Card>
                                        )}
                                    />




                                    {errors.attachments && <FormFeedback>{errors.attachments.message}</FormFeedback>}
                                </div>


                                <div className='d-bloick mt-2'>
                                    <p style={{ color: '#D34C4D', fontWeight: '600', fontFamily: 'Manrope', fontSize: '16px' }}>Note*</p>
                                    <p style={{ color: '#333333', opacity: '0.8', fontSize: '16px', fontWeight: '400' }}>Are you sure you want to update the order status to 'Shipping' ?</p>
                                </div>
                            </div>
                        }

                        {status === "shipped" &&
                            <div className='d-flex flex-column '>

                                <div className='d-bloick mt-2'>
                                    <p style={{ color: '#D34C4D', fontWeight: '600', fontFamily: 'Manrope', fontSize: '16px' }}>Note*</p>
                                    <p style={{ color: '#333333', opacity: '0.8', fontSize: '16px', fontWeight: '400' }}>Are you sure you want to update the order status to 'Delivered'?</p>
                                </div>
                            </div>
                        }

                        <div className='text-center d-flex mt-4'>
                            <button
                                style={{
                                    width: '49%',
                                    border: '1px solid #E4510B',
                                    color: '#E4510B',
                                    background: 'white'
                                }}
                                onClick={handlechange}
                                type='button'
                            >
                                <b>Cancel</b>
                            </button>
                            <button
                                className='ms-2'
                                style={{
                                    width: '49%',
                                    border: 'none',
                                    background: isButtonDisabled ? '#cccccc' : '#E4510B',  // Change color when disabled
                                    color: isButtonDisabled ? '#666666' : 'white'  // Change text color when disabled
                                }}
                                type='submit'
                                disabled={isButtonDisabled}
                            >
                                {load ? (
                                    <Spinner size="sm" color="light" />
                                ) : (
                                    <b>Update</b>
                                )}
                            </button>
                        </div>

                    </Form>
                </ModalBody>
            </Modal>
        </div >
    );
};

export default ChangestatusPage;