import React from 'react'
import { Col, Row } from 'reactstrap'
import './index.css'

const StatsCard = ({ data, md, xl }) => {
    return (
        <Row>
            {data?.map((item, i) => (
                <Col key={i} sm={12} md={md} xl={xl}>
                    <div className='order-stats-card shadow-sm mb-3'>
                        <h1 className='h6 mb-2' >{item?.title}</h1>
                        <div className='d-flex justify-content-between align-items-center'>
                            <h1 className='h3 p-0 m-0'>{item?.count}</h1>
                            <img src={item?.icon} height={50} />
                        </div>
                        <div className='mt-3 d-flex justify-content-between align-items-center'>
                            <p className='h6 p-0 m-0' style={{ color: '#00BA16' }}>+{item?.percentage}%</p>
                            <p className='p-0 m-0' style={{ color: '#A5A4A4', fontSize: '13px' }}>than yesterday</p>
                        </div>
                    </div>
                </Col>
            ))}
        </Row>
    )
}

export default StatsCard
