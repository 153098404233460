import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { productCategory } from '../../ApiConfigs/ApiConfig';
import axios from 'axios'
import { Col, Row, Spinner } from 'reactstrap';
import Navbar from '../navbar';
import FooterSection from '../footer';
import { FaAngleRight } from "react-icons/fa6";
import toast from 'react-hot-toast';

const AllCategory = () => {
    const [loading, setLoading] = useState(false);
    const [dataa, setData] = useState([]);
    const navigate = useNavigate()

    const additionalData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(productCategory);
            setData(response?.data?.result);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        additionalData();
    }, []);

    const navToHome = () => {
        navigate('/');
    };

    const navToProduct = () => {
        navigate('/products');
    };

    const navToViewPage = (product_url) => {
        navigate(`/products/viewProduct/${product_url}`);
    };

    const [showFullDescription, setShowFullDescription] = useState(false);

    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };

    const truncateDescription = (description, wordLimit) => {
        const words = description?.split(' ');
        if (words.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return description;
    };
    return (
        <>
            <Navbar />
            {loading ? (
                <div style={{ height: "80vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Spinner color="primary">
                        Loading...
                    </Spinner>
                </div>
            ) : (
                <div className=' des1'>
                    <p className='pt-3' style={{ cursor: 'pointer', color: '#333333', opacity: '0.8' }}><span onClick={navToHome}>Home</span> / <span style={{ color: '#E4510B' }} onClick={navToProduct}>All Category</span></p>
                    <div style={{ width: '100%' }} className='d-flex align-items-center mt-5'>
                        <h1 className='product-overall-title'>All Category</h1>
                        <hr className='ms-3 call2' style={{ height: '2px', width: '100%', background: 'black' }} />
                    </div>
                    <Row className="mt-2">
                        {dataa?.map((eachItem, i) => (
                            <Col className='d-flex' xl={3} lg={4} md={6} sm={12}>
                                <div className='mt-3 ms-2' style={{ padding: '13px', border: '1px solid #F0F0F0', borderRadius: '15px', background: 'white' }}>
                                    <img style={{ borderRadius: '4px', width: '350px', height: '250px' }} src={eachItem?.image} className="img-fluid" alt="" />
                                    <div className='mt-2'>
                                        <h6 style={{ font: 'Lato', fontSize: '18px', fontw: '500' }}>{eachItem?.name}</h6>
                                        <p
                                            className="m-0 pt-1 pb-2"
                                            // onClick={toggleDescription}
                                            style={{
                                                cursor: "pointer",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                fontSize: '16px',
                                                color: '#858585'
                                            }}
                                            title={eachItem?.description}
                                        >
                                            {showFullDescription
                                                ? eachItem?.description
                                                : truncateDescription(eachItem?.description, 4)}
                                        </p>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <div>
                                            <p style={{ color: '#37A003', font: 'Lato', fontWeight: '400', fontSize: '14px' }}>Starts From</p>
                                            <h2 className='category-card-amt'
                                                style={{
                                                    fontSize: `${eachItem?.minimumPrice &&
                                                        eachItem.minimumPrice.toString().length > 1
                                                        ? eachItem.minimumPrice.toString().length > 4
                                                            ? "18px"
                                                            : "24px"
                                                        : "24px"
                                                        }`,
                                                    textAlign: `${eachItem?.minimumPrice &&
                                                        eachItem.minimumPrice.toString().length === 1
                                                        ? "strat"
                                                        : "start"
                                                        }`,
                                                }}
                                            >
                                                ₹{eachItem?.minimumPrice}
                                            </h2>
                                        </div>
                                        <Link
                                            to={`/products/${eachItem?.category_url}`}
                                            className="mt-2 product-card-link"
                                            style={{ textDecoration: 'none', color: 'inherit' }}
                                        >
                                            <div>
                                                <p className='explore' style={{ marginTop: '8px' }}>Explore Now<FaAngleRight className='d-flex justify-content-center align-items-center mt-1' size={15} /></p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            )}
            <FooterSection />
        </>
    )
}

export default AllCategory