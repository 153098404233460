import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import { ArrowLeft, Edit, Edit2, PlusCircle, RefreshCw } from 'react-feather';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import * as yup from 'yup';
import { productRateUpdate, productType, fieldOption, productFieldType, productTypeSearch } from '../../../../ApiConfigs/ApiConfig';
import Navbar from '../../../navbar';
import { FiChevronRight } from 'react-icons/fi';
import FooterSection from '../../../footer';
import toast from 'react-hot-toast';
import "./index.css"
import AddProductType from '../addProductType';
import DataTable from 'react-data-table-component';

function ProductTypeIndex() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const [editData, setEditData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [bulkData, setBulkData] = useState([]);
    const [setFieldType, setSetFieldType] = useState([]);
    const [fieldOptions, setFieldOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);
    const formSchema = yup.object().shape({
        amount: yup.number().required('Amount is required').positive('Amount must be positive'),
    });

    const {
        reset,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: 'onChange', resolver: yupResolver(formSchema) });

    const handleReset = () => {
        const initialSelectedOptions = setFieldType.map(() => ({ value: '' }));
        setSelectedOption(initialSelectedOptions);
        getData()
    };

    const openModal = (singleId, bulkId) => {
        setEditData(singleId);
        setBulkData(bulkId);
        setModal(true);
    };

    const closeModal = () => {
        setModal(false);
    };

    const submitForm = async (formData) => {
        try {
            const response = await axios.put(`${productRateUpdate}/${bulkData}?type=${editData}`, formData);
            getData();
            closeModal();
            toast.success(response?.data?.msg);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };

    const getData = async () => {
        try {
            const response = await axios.get(`${productType}/?product=${id}`);
            setData(response?.data?.result);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };

    useEffect(() => {
        getData()
    }, []);


    const [optionModal, setOptionModal] = useState(false);
    const openOptionAddModal = (bulkId) => {
        setOptionModal(!optionModal);
        setBulkData(bulkId);
    };

    const closeOptionModal = () => {
        setOptionModal(false);
    };

    const getFieldType = async () => {
        try {
            const response = await axios.get(`${productFieldType}/${id}`);
            setSetFieldType(response?.data?.result);
            const initialSelectedOptions = response?.data?.result.map(() => ({ value: '' }));
            setSelectedOption(initialSelectedOptions);
        } catch (error) {
            console.error(error);
        }
    };

    const getTypeSearch = async () => {
        try {
            const payload = {
                product: id,
                field: selectedOption.map((option, index) => ({
                    fieldType: setFieldType[index]?._id,
                    option: option?.value || '',
                })),
            };
            const response = await axios.post(productTypeSearch, payload);
            setData(response?.data?.result)
            const updatedFieldOptions = response.data.map((result) => result.options);
            setFieldOptions(updatedFieldOptions);
        } catch (error) {
        }
    };

    useEffect(() => {
        getTypeSearch()
    }, [selectedOption]);

    useEffect(() => {
        getFieldType();
    }, [id]);

    const getFieldOption = async (id, index) => {
        try {
            const response = await axios.get(`${fieldOption}?fieldType=${id}`);
            setFieldOptions((prevFieldOptions) => {
                const newFieldOptions = [...prevFieldOptions];
                newFieldOptions[index] = response?.data?.result;
                return newFieldOptions;
            });
        } catch (error) {
            console.error("Error fetching field options:", error);
        }
    };
    const handleChange = (event, index) => {
        const { value } = event.target;
        const updatedSelectedOption = [...selectedOption];
        updatedSelectedOption[index] = { value };
        setSelectedOption(updatedSelectedOption);
    };
    useEffect(() => {
        const fetchDataForAllFieldTypes = async () => {
            try {
                for (const [index, fieldType] of setFieldType.entries()) {
                    await getFieldOption(fieldType._id, index);
                }
            } catch (error) {
                console.error("Error fetching data for all field types:", error);
            }
        };
        fetchDataForAllFieldTypes();
    }, [setFieldType]);

    useEffect(() => {
        reset({
            amount: data?.amount,
        });
    }, [data]);

    const additionalTable = [
        {
            name: 'Type',
            maxWidth: '170px',
            cell: (row) => <p>{row?.type}</p>,
        },
        {
            name: 'Quantity From',
            sortable: false,
            maxWidth: '250px',
            cell: (row) => <p>{row?.from}</p>,
        },
        {
            name: 'Quantity To',
            sortable: false,
            maxWidth: '300px',
            cell: (row) => <p>{row?.to}</p>,
        },
        {
            name: 'Amount',
            sortable: false,
            maxWidth: '300px',
            cell: (row) => (
                <div className='d-flex'>
                    <p style={{ marginLeft: "10px" }}>
                        {new Intl.NumberFormat('en-IN', {
                            style: 'currency',
                            currency: 'INR',
                        }).format(row?.amount)}
                    </p>
                </div>
            ),
        },
        {
            name: 'Action',
            sortable: false,
            maxWidth: '250px',
            cell: (row) => (
                <Col lg={2}>
                    <div onClick={() => openModal(row?._id, data?._id)} style={{ cursor: 'pointer', marginLeft: "20%", paddingBottom: "13px" }}>
                        <Edit2 />
                    </div>
                </Col>
            ),
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontSize: '16px',
                background: '#FFEFE6',
                fontWeight: '600',
                fontFamily: 'Lato',
                color: '#000000',
            },
        },
        cells: {
            style: {
                padding: '10px', // Add padding for better spacing
            },
        },
    };

    return (
        <div>
            <Navbar />
            <div className='pt-3' style={{ paddingLeft: '8%', paddingRight: '8%', paddingBottom: '10%' }}>
                <div>
                    <p className='pt-3' style={{ cursor: 'pointer', marginLeft: '10px', color: '#333333' }}><span style={{ opacity: '0.6' }} onClick={() => navigate('/')}>Home</span><span style={{ opacity: '0.6' }}><FiChevronRight /></span><span style={{ opacity: '0.6' }} onClick={() => navigate('/admin/product')}>Product</span><span style={{ opacity: '0.6' }}><FiChevronRight /></span><span style={{ color: '#E4510B' }}>View Type Based Price</span></p>
                </div>
                <div style={{ width: '100%' }} className='d-flex align-items-center'>
                    <h1 className='product-overall-title' style={{ font: 'Manrope', }}>Type Based Price</h1>
                    <hr className='ms-3 call2' style={{ height: '2px', width: '100%', background: 'black' }} />
                </div>
                <div className='p-2 mt-3'>
                    <div>
                        <div className='pb-2 d-block' style={{ display: 'flex', marginBottom: '-30px', background: 'white', border: '1px solid #DFDFDF', borderRadius: '12px', height: '200px' }}>
                            <div style={{ borderBottom: '1px solid #DFDFDF' }}>
                                <div className='d-flex align-items-center justify-content-between p-3'>
                                    <div className='d-flex align-items-center'>
                                        <div className='red-line me-2'></div>
                                        <h1 className='p-0 m-0 h5'>Product : {data[0]?.product?.name}</h1>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between p-4'>
                                <div style={{ marginLeft: '0px' }}>
                                    <Row style={{ marginLeft: '-30px' }}>
                                        {setFieldType.map((item, index) => (
                                            <Col key={index} md={4} sm={6} lg={4}>
                                                <div className='materialProducts'>
                                                    <Label>{item?.name}</Label>
                                                    <Box sx={{ minWidth: 200, height: '30px' }}>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                style={{ height: '30px', width: '250px' }}
                                                                // labelId={`demo-custom-select-label-${index}`}
                                                                id={`demo-custom-select-${index}`}
                                                                value={selectedOption[index]?.value || ''} // Set default to an empty string
                                                                label='Field Type'
                                                                onChange={(event) => handleChange(event, index)}
                                                                onClick={getTypeSearch}
                                                            >
                                                                {fieldOptions[index]?.map((el) => (
                                                                    <MenuItem key={el?._id} value={el?._id}>
                                                                        {el?.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </div>
                                            </Col>
                                        ))}

                                    </Row>
                                </div>
                                <div className='d-flex justify-content-end align-items-center' onClick={handleReset}>
                                    <a style={{ color: "red", cursor: "pointer" }}><RefreshCw /> Reset</a>
                                </div>
                            </div>
                        </div>
                        {data?.map((item) => {
                            return (
                                <Card className='mt-5' style={{
                                    margin: "5px",
                                    border: "none",
                                    background: "white",
                                    border: '1px solid #DFDFDF',
                                    borderRadius: '10px',
                                    marginTop: "1%",
                                    overflow: 'hidden' // Prevent overflow from content
                                }}>
                                    <Row>
                                        <Col lg={12} md={12} sm={12}>
                                            <div className='d-flex p-3' key={item?._id} style={{
                                                position: 'relative',
                                                width: '100%',
                                                height: '100%',
                                                display: 'flex',
                                                borderBottom: '1px solid #DFDFDF'
                                            }}>
                                                <img
                                                    className='d-flex align-items-center justify-content-center'
                                                    src={item?.image}
                                                    style={{
                                                        width: "120px",
                                                        height: "120px",
                                                        borderRadius: '5px'
                                                    }}
                                                />
                                                <div className='d-block ms-5'>
                                                    {item?.field?.map((fieldItem) => (
                                                        <div className='d-flex' key={fieldItem?.fieldType?._id}>
                                                            <p style={{
                                                                width: '150px',
                                                                color: '#333333',
                                                                opacity: '0.7',
                                                                fontSize: '20px',
                                                                fontFamily: 'Lato',
                                                                fontWeight: '500'
                                                            }}>{fieldItem?.fieldType?.name}</p>
                                                            <p style={{
                                                                width: '150px',
                                                                color: '#333333',
                                                                fontSize: '20px',
                                                                fontFamily: 'Lato',
                                                                fontWeight: '500'
                                                            }}>: {fieldItem?.option?.name}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={12} md={12} style={{ padding: "5%" }}>
                                            <Row style={{ marginTop: "20px" }}>
                                                <Col lg={12} md={12}>
                                                    {loading ? (
                                                        <div style={{
                                                            height: "50vh",
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}>
                                                            <Spinner color="primary">Loading...</Spinner>
                                                        </div>
                                                    ) : (
                                                        <div className='' style={{ width: "100%" }}> {/* Ensure full width for the table */}
                                                            <DataTable
                                                                columns={additionalTable}
                                                                data={item?.quantityBasedAmount || []} // Pass the full array of amounts here
                                                                noHeader
                                                                customStyles={customStyles}
                                                                style={{
                                                                    border: '1px solid #EEEEEE',
                                                                    width: "100%", // Ensure the table takes up the full column width
                                                                    overflowX: 'auto' // Allow horizontal scrolling on small screens
                                                                }}
                                                                responsive // Make the DataTable responsive
                                                            />
                                                        </div>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card>
                            );
                        })}
                    </div>
                    <Modal
                        isOpen={modal}
                        toggle={closeModal}
                    >
                        <ModalHeader style={{ background: ' #e4510b', color: 'white' }} toggle={closeModal}>Edit Amount</ModalHeader>
                        <ModalBody>
                            <Form onSubmit={handleSubmit(submitForm)}>
                                <div className='mb-1'>
                                    <Label style={{ fontWeight: "bolder" }}>Rate <span className='text-danger'>*</span></Label>
                                    <Controller
                                        name='amount'
                                        id='amount'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (<Input type="number" {...field} invalid={errors.amount && true} placeholder="Enter Amount" />)} />
                                    {errors.amount && <FormFeedback>{errors.amount.message}</FormFeedback>}
                                </div>
                                <Button type='submit' className='mt-2' style={{ background: '#e4510b', border: 'none', width: '100%' }}>Sumbit</Button>
                            </Form>
                        </ModalBody>
                    </Modal>
                    <AddProductType modal={optionModal} toggle={closeOptionModal} bulkData={bulkData} getData={getData} />
                </div >

            </div>
            <FooterSection />
        </div >
    )
}
export default ProductTypeIndex
