import React, { useEffect, useState } from 'react'
import Navbar from '../../navbar'
import FooterSection from '../../footer'
import { Card, Col, Collapse, Row, Spinner } from 'reactstrap'
import './index.css'
import { Check, ChevronDown, ChevronRight } from 'react-feather'
import { cancelReason, customerProducts, trackingDetails } from '../../../ApiConfigs/ApiConfig'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import MyOrderCard, { getDeliveryStatus } from '../MyOrderCard'
import { MdOutlineFeedback } from "react-icons/md";
import CancelModal from './CancelModal'
import { convertTo12HourFormat, formatDate } from '..'
import ChangeModel from '../changeAddress'
import ReviewModal from './ReviewModal'
import UpdateReviewModal from './UpdateReviewModal'

function ViewOrderDetail() {
    const [dataa, setData] = useState([]);
    const [trackingData, setTrackingData] = useState();
    const [cancelData, setCancelData] = useState();
    const [loading, setLoading] = useState(false);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [openChangeAddressModal, setOpenChangeAddressModal] = useState(false);
    const [openReviewModal, setOpenReviewModal] = useState(false);
    const [openEditReviewModal, setOpenEditReviewModal] = useState(false);
    const { id } = useParams()
    const [pdffile, setpdffile] = useState('')
    const toggleAccordion = (id) => {
        setOpenAccordion(openAccordion === id ? null : id);
    };
    const [openAccordion, setOpenAccordion] = useState(null);

    const additionalData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${customerProducts}/${id}`)
            setData(response?.data?.result)
            setpdffile(response?.data?.result?.invoice)
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        } finally {
            setLoading(false);
        }
    }

    const trackingDataGet = async () => {
        try {
            const response = await axios.get(`${trackingDetails}/${id}`)
            setTrackingData(response?.data?.result)
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    }
    const cancelDataGet = async () => {
        try {
            const response = await axios.get(`${cancelReason}`)
            setCancelData(response?.data?.result)
        } catch (error) {
            // toast.error(error?.response?.data?.msg);
            toast.error(error?.response?.data?.msg);
        }
    }

    useEffect(() => {
        additionalData()
        trackingDataGet()
        cancelDataGet()
    }, [])
    // useEffect(() => {
    //     additionalData()
    //     trackingDataGet()
    //     cancelDataGet()
    // }, [id])

    const addressDetails = dataa?.shippingAddress

    const refetchFunc = () => {
        additionalData()
        trackingDataGet()
        cancelDataGet()
    }

    return (
        <>
            <Navbar />
            {loading ? (
                <div
                    style={{
                        height: "80vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Spinner color="primary">Loading...</Spinner>
                </div>
            ) : (
                <div className='pppaa mb-md-0 mb-5' style={{ paddingLeft: '5%', paddingRight: '5%', paddingBottom: '10%', background: '#FAFAFA' }}>
                    <div style={{ width: '100%' }} className='d-flex align-items-center pt-md-5 pt-2 mb-3'>
                        <h1 className='product-overall-title' style={{ font: 'Manrope', }}>My Orders {dataa?.otherProducts?.length > 0 ? (<span>({dataa?.otherProducts?.length + 1})</span>) : ''}</h1>
                        <hr className='ms-3 call2' style={{ height: '2px', width: '100%', background: 'black' }} />
                    </div>
                    <div className='d-flex justify-content-end mb-4'>
                        {dataa?.status === "delivered" && (
                            <a
                                style={{ fontSize: '15px', fontFamily: 'Lato', fontWeight: '500', color: '#E4510B', cursor: 'pointer' }}
                                onClick={() => {
                                    if (dataa?.ratingReview) {
                                        setOpenEditReviewModal(true);  // Open edit modal if a review exists
                                    } else {
                                        setOpenReviewModal(true);  // Open add modal if no review exists
                                    }
                                }}
                            >
                                <MdOutlineFeedback style={{ color: '#E4510B' }} />
                                {dataa?.ratingReview ? " Edit Your Review" : " Add Your Review"}
                            </a>
                        )}

                    </div>
                    <Row className='mb-md-0 mb-5'>
                        <Col sm={12} lg={8}>
                            <div className='view-order-card p-3'>
                                <div className='d-flex justify-content-between carda'>
                                    <div className='d-md-flex '>
                                        <div>
                                            {dataa?.customizeImages?.[0] ? (
                                                <img
                                                    alt={`product`}
                                                    src={dataa?.customizeImages?.[0]}
                                                    className='my-order-img'
                                                />
                                            ) : (
                                                <img
                                                    alt={`product`}
                                                    src={dataa?.file?.[0].endsWith('.pdf') ? 'https://aimmgr.s3.ap-south-1.amazonaws.com/mgr/public/socialpost/file-blvvhhfusm11y4jw4.png' : dataa?.file?.[0]}
                                                    className='my-order-img'
                                                />
                                            )}
                                            {(dataa?.status !== "ordercompleted" && dataa?.status !== "cancelled") &&
                                                <div className='d-flex mt-2'>
                                                    <button style={{ border: 'none', background: 'white' }}>
                                                        <a
                                                            target='_blank'
                                                            href={pdffile}
                                                            rel="noreferrer"
                                                            style={{ background: 'transparent', color: '#E4510B', border: 'none', textDecoration: 'underline' }}
                                                            className='text-center'
                                                        >
                                                            Download Invoice
                                                        </a>
                                                    </button>
                                                </div>
                                            }

                                        </div>
                                        <div className='ms-md-3 heaa s'>
                                            <h1 className='h5'>{`${dataa?.product?.name}`}</h1>
                                            <p style={{ color: '#858585' }} className='p-0 m-0 mb-2'>Quantity : {`${dataa?.quantity}`}</p>
                                            <div className='mt-2'>
                                                <div className='accordion-header' style={{ cursor: 'pointer' }} >
                                                    <div className='d-flex' onClick={() => toggleAccordion(dataa?._id)}>
                                                        <h6 style={{ color: '#858585', fontWeight: '400' }} className=' p-0'>Product Specifications</h6>
                                                        <span style={{ marginTop: '-2px' }} className='ms-2 p-0'><ChevronDown size={20} /></span>
                                                    </div>
                                                </div>
                                                <Collapse isOpen={openAccordion === dataa?._id}>
                                                    <div className='accordion-body'>
                                                        <div>
                                                            {/* <p style={{ color: '#e6703a' }}>Edit Details</p> */}

                                                            {dataa?.field?.map((eachDetail, k) => (
                                                                <div className='d-flex justify-content-between' key={k}>
                                                                    <p className='text-secondary'><ChevronRight /> {eachDetail?.fieldType?.name}</p>
                                                                    <p className='pe-5 text-dark' style={{ fontWeight: '500' }}>- {eachDetail?.option?.name}</p>
                                                                </div>
                                                            ))}

                                                        </div>
                                                    </div>
                                                </Collapse>
                                            </div>
                                            <h1 className='h6'>Order placed on : {`${convertTo12HourFormat(dataa?.date)}, ${formatDate(dataa?.date)}`}</h1>
                                            <div className='d-flex text-center'>
                                                <h1 className='h5 text-success p-0 m-0'>
                                                    ₹{`${parseFloat(dataa?.total).toFixed(2)}`}
                                                </h1>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='d-flex flex-column justify-content-between align-items-end state'>
                                        <div className='mb-2'>   {getDeliveryStatus(dataa?.status)}</div>
                                        {trackingData?.[4]?.date && (
                                            <h1 className='h6 m-0 p-0'>Delivery expected by {formatDate(trackingData?.[4]?.date)}</h1>
                                        )}
                                    </div>
                                </div>
                                <div className='mt-4 mb-3' style={{ backgroundColor: 'rgb(225, 225, 225)', height: '1px', width: '100%' }}></div>
                                {dataa?.isCancelled ? (
                                    <div className='w-100'>
                                        <div>
                                            <div className='d-flex justify-content-between' >
                                                <h1 className='h6 ms-md-3'>Order Confirmed</h1>
                                                <h1 className='h6 me-md-2'>Cancelled</h1>
                                            </div>
                                            <div className='d-flex justify-content-between me-lg-4 ms-lg-5 mt-2 me-sm-2 ms-sm-3'>
                                                {/* First Tick Box */}
                                                <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <div className="tick-red ms-md-1 ms-3">
                                                        <span><Check size={26} /></span>
                                                    </div>

                                                    <div
                                                        className="bar-line"
                                                        style={{
                                                            flexGrow: 1,   // This makes the line grow to fill the space between the ticks
                                                            height: '6px', // Thickness of the line
                                                            backgroundColor: 'red'
                                                        }}
                                                    ></div>

                                                    <div className="tick-red me-md-1 me-3">
                                                        <span><Check size={26} /></span>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className='d-flex justify-content-between s' >
                                                <h1 style={{ color: '#858585' }} className='h6 ms-md-3 mt-3 time'>{convertTo12HourFormat(dataa?.date)}, {formatDate(dataa?.date)}</h1>
                                                <h1 style={{ color: '#858585' }} className='h6 mt-3 time'>{convertTo12HourFormat(dataa?.cancelDetails?.date)}, {formatDate(dataa?.cancelDetails?.date)}</h1>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='d-flex align-items-end justify-content-between position-relative'>
                                        <div className='overflow-auto'>
                                            <div className='d-flex' style={{ gap: '130px' }}>
                                                {trackingData?.slice(0, 4)?.map((item, i) => (
                                                    <h1 key={i} className='h6'>{item?.title}</h1>
                                                ))}
                                            </div>
                                            <div className='d-flex ms-3 mt-2'>
                                                {trackingData?.slice(0, 4)?.map((item, i) => (
                                                    <div>
                                                        <div className='d-flex align-items-center justify-content-end'>
                                                            {i !== 0 && (
                                                                <div className={`bar-line ${item?.status ? 'bar-line-clr-2' : 'bar-line-clr-1'}`}></div>
                                                            )}
                                                            <div className={` ${item?.status ? 'tick-green' : 'tick-normal'}`}>
                                                                <span><Check size={26} /></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='d-flex' style={{ gap: '100px' }}>
                                                {trackingData?.slice(0, 4)?.map((item, i) => (
                                                    <h1 key={i} style={{ color: '#858585', minWidth: '100px' }} className='h6 mt-3 time'>{item?.date ? (<span>{convertTo12HourFormat(item?.date)}, <br /> {formatDate(item?.date)}</span>) : ''}</h1>
                                                ))}
                                            </div>
                                        </div>
                                        {trackingData?.[1]?.status !== true && (
                                            <button
                                                className='order-cancel-btn'
                                                onClick={() => setOpenCancelModal(true)}
                                            >
                                                Cancel
                                            </button>
                                        )}
                                    </div>
                                )}
                            </div>
                        </Col>
                        <Col sm={12} lg={4}>
                            <div className='mt-3 mt-lg-0' style={{ border: '1px solid rgb(225, 225, 225)', borderRadius: '15px' }}>
                                <div style={{ borderBottom: '1px solid #DFDFDF' }} className=' p-3'>
                                    <h5>Order Summary</h5>
                                    <hr className='p-0 m-0 mb-3' />
                                    <div className='d-flex justify-content-between'>
                                        <p className='cart-summary-para'>Item Subtotal</p>
                                        <p>{dataa?.total?.toLocaleString('en-IN', {
                                            maximumFractionDigits: 2,
                                            style: 'currency',
                                            currency: 'INR'
                                        })}</p>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <p className='cart-summary-para'>Shipping Charge</p>
                                        <p>{dataa?.shippingCharge?.toLocaleString('en-IN', {
                                            maximumFractionDigits: 2,
                                            style: 'currency',
                                            currency: 'INR'
                                        })}</p>
                                    </div>
                                    <hr className='p-0 m-0 mb-3' />
                                    <div className='d-flex justify-content-between'>
                                        <h6>Total <span style={{ color: '#888888' }}>(inclusive of all tax)</span></h6>
                                        <h5 style={{ color: '#37A003' }} className='fw-bold ms-3'>{dataa?.totalAmount?.toLocaleString('en-IN', {
                                            maximumFractionDigits: 2,
                                            style: 'currency',
                                            currency: 'INR'
                                        })}</h5>
                                    </div>
                                </div>
                                <div className='mt-3 p-3'>
                                    <h5>Payment Information</h5>
                                    <hr className='p-0 m-0 mb-3' />
                                    <div className='d-flex justify-content-between'>
                                        <p className='cart-summary-para'>Payment Method :</p>
                                        <p>{dataa?.paymentDetails?.method}</p>
                                    </div>
                                    {dataa?.paymentDetails?.method !== "cash" &&
                                        <div className='d-flex justify-content-between'>
                                            <p className='cart-summary-para'>Transaction ID :</p>
                                            <p>{dataa?.paymentDetails?.transactionId}</p>
                                        </div>
                                    }

                                </div>
                            </div>
                        </Col>
                        <Col sm={12} lg={8} className='mt-3'>
                            <div className='view-order-card p-3 h-100 mt-md-0'>
                                <h1 className='h5'>Delivery Address</h1>
                                <div className='d-flex justify-content-between align-items-center mt-3'>
                                    <h1 style={{ fontSize: '16px' }} className='p-0 m-0'>{addressDetails?.firstName} {addressDetails?.lastName}</h1>
                                    {trackingData?.[1]?.status !== true && (
                                        <button
                                            className='order-change-btn'
                                            onClick={() => setOpenChangeAddressModal(true)}
                                        >
                                            Change
                                        </button>
                                    )}
                                </div>
                                <div className='w-75 mt-4'>
                                    <h1 style={{ fontSize: '16px' }} className=''>{addressDetails?.address}, {addressDetails?.landmark}, {addressDetails?.city} - {addressDetails?.pincode}, {addressDetails?.state}.</h1>
                                </div>

                                <h1 style={{ fontSize: '16px' }} className='mt-4'>Phone Number : {addressDetails?.mobile} </h1>
                                <h1 style={{ fontSize: '16px' }} className=''>Alternate Phone Number : {addressDetails?.alternateMobile} </h1>

                                {(dataa?.status === "delivered" || dataa?.status === "shipped") && (
                                    <>
                                        <h1 className='h5 mt-4'>Shipping Details</h1>

                                        <h1 style={{ fontSize: '16px' }} className='mt-3'>Tracking Number : {dataa?.shipmentDetails?.trackingId} </h1>
                                        <h1 style={{ fontSize: '16px' }} className=''>Logistics Nmae  : {dataa?.shipmentDetails?.logisticName} </h1>
                                    </>
                                )}
                            </div>
                        </Col>
                    </Row>
                    {dataa?.otherProducts?.length > 0 && (
                        <>
                            <div style={{ width: '100%' }} className='d-flex align-items-center mt-5 mb-3'>
                                <h1 className='product-overall-title' style={{ font: 'Manrope', }}>Other items in this order</h1>
                                <hr className='ms-3 call2' style={{ height: '2px', width: '100%', background: 'black' }} />
                            </div>
                            <div>
                                {dataa?.otherProducts?.map((item, i) => (
                                    <div key={i}>
                                        <MyOrderCard data={item} />
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div >
            )}
            <FooterSection />
            <ReviewModal openModal={openReviewModal} id={dataa?._id} handleClose={() => setOpenReviewModal(!openReviewModal)} refetch={refetchFunc} />
            <UpdateReviewModal openModal={openEditReviewModal} id={dataa?._id} handleClose={() => setOpenEditReviewModal(!openEditReviewModal)} refetch={refetchFunc} value={dataa?.ratingReview} />
            <CancelModal openModal={openCancelModal} id={dataa?._id} data={cancelData} handleClose={() => setOpenCancelModal(!openCancelModal)} refetch={refetchFunc} />
            <ChangeModel openAddressModal={openChangeAddressModal} handleAddressClose={() => setOpenChangeAddressModal(!openChangeAddressModal)} productId={id} refetch={refetchFunc} />
        </>
    )
}

export default ViewOrderDetail
