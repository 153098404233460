import React, { useEffect, useState } from 'react'
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Label, Button, Row, Col } from 'reactstrap'
import toast from 'react-hot-toast';
import axios from 'axios';
import { orderPost } from '../../../ApiConfigs/ApiConfig';
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../../navbar';
import FooterSection from '../../footer';
import { FaRegFileLines } from "react-icons/fa6";
import { MdOutlineFileDownload } from "react-icons/md";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { Eye } from 'react-feather';
import { BiCheck } from "react-icons/bi";
import { FiChevronRight } from "react-icons/fi";
import step2 from "../../../assets/images/banner/step2.png";
import step1 from "../../../assets/images/banner/step1.png"
import './index.css'
import PrintoutPage from './printout';
import ChangestatusPage from './changestatus';
import Preview from '../../myOrder/preview';

export const getDeliveryStatus = (status) => {
    switch (status) {
        case 'ordercompleted':
            return (
                <div className='disc-container-1 disc-con-clr-4 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-4'></div>
                    <p className='p-0 m-0'>New Order</p>
                </div>
            );
        case 'process':
            return (
                <div className='disc-container-1 disc-con-clr-1 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-1'></div>
                    <p className='p-0 m-0'>On Progress</p>
                </div>
            );
        case 'shipped':
            return (
                <div className='disc-container-1 disc-con-clr-5 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-5'></div>
                    <p className='p-0 m-0'>Shipped</p>
                </div>
            );
        case 'delivered':
            return (
                <div className='disc-container-1 disc-con-clr-2 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-2'></div>
                    <p className='p-0 m-0'>Delivered</p>
                </div>
            );
        case 'cancelled':
            return (
                <div className='disc-container-1 disc-con-clr-3 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-3'></div>
                    <p className='p-0 m-0'>Cancelled</p>
                </div>
            );
        default:
            return (
                <div className='disc-container-1 disc-con-clr-1 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-1'></div>
                    <p className='p-0 m-0'>In Progress</p>
                </div>
            );
    }
};


function DashBoardViewPage() {

    const { id } = useParams();
    const [data, setData] = useState([])
    const [pdffile, setpdffile] = useState('')
    const navigate = useNavigate()

    const handlechange = () => setAddModal(!addModal)

    const [addModal, setAddModal] = useState(false);
    const [modal, setModal] = useState(false);
    const [customizeImages, setCustomizeImages] = useState([]);

    const formSchema = yup.object().shape({
    })

    const {
        setValue,
    } = useForm({ mode: 'onChange', resolver: yupResolver(formSchema) });

    const status = data?.status;


    const getdata = async () => {
        try {
            const response = await axios.get(`${orderPost}/${id}`)
            setData(response?.data?.result)
            setpdffile(response?.data?.result?.invoice)
            // toast.success(response?.data?.msg)
        } catch (error) {
            toast.error(error.response.data.msg)
        }
    }
    useEffect(() => {
        getdata();
    }, [])

    const toggle = () => setModal(!modal);

    const viewCustom = (item) => {

        if (item?.customizeImages?.length > 1) {
            setCustomizeImages(item?.customizeImages);
            toggle()
        } else {
            setCustomizeImages(item?.file);
            toggle()
        }

    };

    // const handleprint = async () => {
    //     try {
    //         const response = await axios.get(`${orderPost}/${id}`)

    //     } catch (error) {
    //         toast.error(error.response.data.msg)
    //     }
    // }


    // const sortData = [
    //     {
    //         name: "cart",
    //         value: "cart"
    //     },
    //     {
    //         name: "ordercompleted",
    //         value: "ordercompleted"
    //     },
    //     {
    //         name: "process",
    //         value: "process"
    //     },
    //     {
    //         name: "delivered",
    //         value: "delivered"
    //     },
    //  ]

    // const getStatusColor = (paymentStatus) => {
    //     switch (paymentStatus) {
    //         case 'PAID':
    //             return 'success';
    //         case 'UNPAID':
    //             return 'danger';
    //         default:
    //             return 'danger';
    //     }
    // };

    // let totalAmount = data?.products ? data.products.reduce((total, product) => total + (product?.IGST || 0), 0).toFixed(2) : '0.00'
    let totalAmount = data?.totalGst

    // useEffect(() => {
    //     setValue('status', { label: data?.status, value: data?.status })
    // }, [data, setValue])
    // const customStyles = {
    //     rows: {
    //         style: {
    //             backgroundColor: '#ffffff',
    //             color: 'black'
    //         },
    //     },
    //     headCells: {
    //         style: {
    //             backgroundColor: '#ebeff1',
    //         },
    //     },
    //     cells: {
    //         style: {
    //             color: 'black',
    //         },
    //     },
    // };

    let dataTable = [
        {
            name: ' No',
            minWidth: '50px',
            cell: (row, i) => i + 1,
        },
        {
            name: 'Product Image',
            sortable: false,
            minWidth: '150px',
            cell: row => {
                const productpic = row?.customizeImages?.length > 0 ? (row?.customizeImages[0]) : (row?.file[0]);
                return (
                    <div>
                        <img src={productpic} alt="Product Image" style={{ width: '50px', height: '50px', padding: '5px' }} />
                        <div className='d-flex' style={{ alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                            <span style={{ cursor: 'pointer' }} onClick={() => viewCustom(row)}><Eye className='w-25' /> View</span>
                        </div>
                    </div>)
            },
        },
        {
            name: 'Product Name',
            sortable: false,
            minWidth: '150px',
            cell: row => row?.product?.name,
        },
        {
            name: 'Unit Price',
            sortable: false,
            minWidth: '150px',
            cell: row => (row?.singlePrice ? row.singlePrice.toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
            }) : '0.00')
        },
        {
            name: 'Quantity',
            sortable: false,
            minWidth: '150px',
            cell: row => (row?.quantity)
        },
        {
            name: 'Size',
            sortable: 'false',
            minWidth: '150px',
            cell: row => row?.size ? row.size : '-'
        },
        {
            name: 'Amount',
            sortable: false,
            minWidth: '150px',
            cell: row => (row?.grossAmount ? row.grossAmount.toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
            }) : '0.00')
        },
        {
            name: 'Discount',
            sortable: false,
            minWidth: '150px',
            cell: row => (row?.discountAmount ? row.discountAmount.toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
            }) : '0.00')
        },
        {
            name: 'Taxable Value',
            sortable: false,
            minWidth: '150px',
            cell: row => (row?.taxableAmount ? row.taxableAmount.toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
            }) : '0.00')
        },
        {
            name: 'IGST',
            sortable: false,
            minWidth: '150px',
            cell: row => (row?.IGST ? row.IGST.toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
            }) : '0.00')
        },
        {
            name: 'Total',
            sortable: false,
            minWidth: '150px',
            cell: row => (row?.total ? row.total.toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
            }) : '0.00')
        },
    ]



    if (data?.shipping?.state === 'Karnataka') {
        dataTable = [
            {
                name: ' No',
                minWidth: '50px',
                cell: (row, i) => i + 1,
            },
            {
                name: 'Product Image',
                sortable: false,
                minWidth: '150px',
                cell: row => {
                    const productpic = row?.customizeImages?.length > 0 ? (row?.customizeImages[0]) : (row?.file[0]);
                    return (
                        <div>
                            <img src={productpic} alt="Product Image" style={{ width: '50px', height: '50px', padding: '5px' }} />
                            <div className='d-flex' style={{ alignItems: 'center', justifyContent: 'center' }}>
                                <span style={{ cursor: 'pointer' }} onClick={() => viewCustom(row)}><Eye className='w-25' /> View</span>
                            </div>
                        </div>)
                },
            },
            {
                name: 'Product Name',
                sortable: false,
                minWidth: '150px',
                cell: row => row?.product?.name,
            },
            {
                name: 'Unit Price',
                sortable: false,
                minWidth: '150px',
                cell: row => (row?.singlePrice ? row.singlePrice.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                }) : '0.00')
            },

            {
                name: 'Quantity',
                sortable: false,
                minWidth: '150px',
                cell: row => (row?.quantity)
            },


            {
                name: 'Amount',
                sortable: false,
                minWidth: '150px',
                cell: row => (row?.grossAmount ? row.grossAmount.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                }) : '0.00')
            },
            {
                name: 'Discount',
                sortable: false,
                minWidth: '150px',
                cell: row => (row?.discountAmount ? row.discountAmount.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                }) : '0.00')
            },
            {
                name: 'Taxable Value',
                sortable: false,
                minWidth: '150px',
                cell: row => (row?.taxableAmount ? row.taxableAmount.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                }) : '0.00')
            },
            {
                name: 'SGST',
                sortable: false,
                minWidth: '150px',
                cell: row => (row?.SGST ? row.SGST.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                }) : '0.00')
            },
            {
                name: 'CGST',
                sortable: false,
                minWidth: '150px',
                cell: row => (row?.CGST ? row.CGST.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                }) : '0.00')
            },
            {
                name: 'Total',
                sortable: false,
                minWidth: '150px',
                cell: row => (row?.total ? row.total.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                }) : '0.00')
            },
        ];
        // totalAmount = data?.products ? data.products.reduce((total, product) => total + (product?.SGST + product?.CGST || 0), 0).toFixed(2) : '0.00'
        totalAmount = data?.totalGst
    }

    const handleDownload = () => {
        const files = [...(data?.products[0]?.file || []), ...(data?.products[0]?.customizeImages || [])];

        if (files.length === 0) {
            alert("No files available to download.");
            return;
        }

        files.forEach(async (fileUrl) => {
            try {
                const response = await fetch(fileUrl, {
                    mode: 'cors', // Ensure CORS is handled for cross-origin files
                });
                if (!response.ok) {
                    throw new Error(`Failed to fetch ${fileUrl}`);
                }

                const blob = await response.blob();
                const blobUrl = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = blobUrl;

                // Extract the file name from the URL
                const fileName = fileUrl.split('/').pop() || 'downloaded_file';
                link.setAttribute('download', fileName);

                // Append link to the DOM
                document.body.appendChild(link);

                // Trigger click programmatically for the download
                link.click();

                // Cleanup the link and blob URL
                document.body.removeChild(link);
                window.URL.revokeObjectURL(blobUrl);
            } catch (error) {
                console.error('Error downloading file:', error);
                alert('Download failed. Please try again.');
            }
        });
    };


    return (
        <>
            <Navbar />
            <div style={{ background: '#F8F8F8', paddingBottom: '10%' }}>
                <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                    <div>
                        <p className='pt-3' style={{ cursor: 'pointer', marginLeft: '10px', color: '#333333' }}><span style={{ opacity: '0.6' }} onClick={() => navigate('/')}>Home</span><span style={{ opacity: '0.6' }}><FiChevronRight /></span><span style={{ opacity: '0.6' }} onClick={() => navigate('/admin/order-management')}>Order Management</span><span style={{ opacity: '0.6' }}><FiChevronRight /></span><span style={{ color: '#E4510B' }}>Action</span></p>

                    </div>
                    <div className='d-flex cent'>
                        <h3 style={{ fontSize: '28px' }}>Order Details</h3>
                        <hr className='ms-3 hrrr1' style={{ height: '2px', width: '85%', background: 'black' }} />
                    </div>
                </div>
                <span className='d-none'><PrintoutPage data={data} /></span>
                <div className='container mt-5' style={{
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                    color: '#000',
                    borderRadius: '10px',
                    border: ' 1px solid #DFDFDF',
                    padding: '30px'
                }}>
                    <div style={{ marginLeft: '10px' }}>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex'>
                                <p style={{ fontSize: '20px', fontWeight: '500', font: 'Manrope' }}>Order ID :</p>
                                <div className='ms-3 mt-1'>
                                    <p style={{ color: "#262525", fontSize: '20px', fontWeight: '500' }}>{data?.orderId}</p>
                                </div>
                            </div>
                            <div className='d-flex'>
                                <p style={{ fontSize: '20px', fontWeight: '500', font: 'Manrope' }}>Order Status :</p>
                                <div className='ms-3'>
                                    {getDeliveryStatus(data?.status)}
                                </div>
                            </div>
                            {data?.status !== "ordercompleted" &&
                                <div className='d-flex'>
                                    <Button style={{ background: '#E4510B', color: 'white', border: 'none' }}>
                                        <a target='_blank' href={pdffile} style={{ background: '#E4510B', color: 'white', border: 'none' }} className='text-center text-decoration-none'>Download Invoice <FaRegFileLines size={20} /></a>
                                    </Button>
                                </div>
                            }

                        </div>
                        {data?.status === "ordercompleted" &&
                            <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                                <div style={{ paddingLeft: '5%', paddingRight: '3%', width: '100%' }} className='d-flex mt-4'>
                                    <div>
                                        <p className='d-flex justify-content-center align-items-center mt-1 stage1' >1</p>
                                    </div>
                                    <img className='d-flex proces mt-3' src={step1} alt="" />
                                    <div>
                                        <p className='d-flex justify-content-center align-items-center mt-1 stage2' >2</p>
                                    </div>
                                    <img className='d-flex proces mt-3' src={step1} alt="" />
                                    <div>
                                        <p className='d-flex justify-content-center align-items-center mt-1 stage3' >3</p>
                                    </div>
                                    <hr />
                                </div>
                                <div style={{ paddingLeft: '2%', paddingRight: '2%' }} className='d-flex justify-content-between'>
                                    <div>
                                        <Button className='statusUpdate1' onClick={handlechange}>
                                            Update Status as Process
                                        </Button>
                                        <ChangestatusPage open={addModal} handlechange={handlechange} getdata={getdata} dataId={id} status={status} />
                                    </div>
                                    <Button className='statusUpdate2'>Update Status as Shipped</Button>
                                    <Button className='statusUpdate3'>Update Status as Delivered</Button>
                                </div>
                            </div>
                        }

                        {data?.status === "process" &&
                            <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                                <div style={{ paddingLeft: '5%', paddingRight: '3%', width: '100%' }} className='d-flex mt-4'>
                                    <div>
                                        <p className='d-flex justify-content-center align-items-center mt-1 stage4' ><BiCheck style={{ color: 'white' }} /></p>
                                    </div>
                                    <img className='d-flex proces mt-3' src={step2} alt="" />
                                    <div>
                                        <p className='d-flex justify-content-center align-items-center mt-1 stage1' >2</p>
                                    </div>
                                    <img className='d-flex proces mt-3' src={step1} alt="" />
                                    <div>
                                        <p className='d-flex justify-content-center align-items-center mt-1 stage3' >3</p>
                                    </div>
                                    <hr />
                                </div>
                                <div style={{ paddingLeft: '2%', paddingRight: '2%' }} className='d-flex justify-content-between'>
                                    <div className='text-center' style={{ width: '200px', marginLeft: '-50px' }}>
                                        <p className='papa'>
                                            Processed<br />
                                            on {new Date(data?.tracking?.find(status => status.status === 'process')?.date).toLocaleDateString()}
                                            <br />
                                            {new Date(data?.tracking?.find(status => status.status === 'process')?.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                        </p>
                                    </div>
                                    <div>
                                        <Button onClick={handlechange} className='statusUpdate1 ms-3'>Update Status as Shipped</Button>
                                        <ChangestatusPage open={addModal} handlechange={handlechange} getdata={getdata} dataId={id} status={status} address={data?.shipping} deliveryDate1={data?.expectedDeliveryDate?.split('T')[0]} />
                                    </div>
                                    <Button className='statusUpdate3'>Update Status as Delivered</Button>
                                </div>
                            </div>
                        }

                        {data?.status === "shipped" &&
                            <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                                <div style={{ paddingLeft: '5%', paddingRight: '3%', width: '100%' }} className='d-flex mt-4'>
                                    <div>
                                        <p className='d-flex justify-content-center align-items-center mt-1 stage4' ><BiCheck style={{ color: 'white' }} /></p>
                                    </div>
                                    <img className='d-flex proces mt-3' src={step2} alt="" />
                                    <div>
                                        <p className='d-flex justify-content-center align-items-center mt-1 stage4' ><BiCheck style={{ color: 'white' }} /></p>
                                    </div>
                                    <img className='d-flex proces mt-3' src={step2} alt="" />
                                    <div>
                                        <p className='d-flex justify-content-center align-items-center mt-1 stage1' >3</p>
                                    </div>
                                    <hr />
                                </div>
                                <div style={{ paddingLeft: '2%', paddingRight: '2%' }} className='d-flex justify-content-between'>
                                    <div className='text-center' style={{ width: '200px', marginLeft: '-45px' }}>
                                        <p className='papa'>Processed<br />on {new Date(data?.tracking?.find(status => status.status === 'process')?.date).toLocaleDateString()}
                                            <br />
                                            {new Date(data?.tracking?.find(status => status.status === 'process')?.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
                                    </div>
                                    <div className='text-center' style={{ width: '200px', marginLeft: '-45px' }}>
                                        <p className='papa'>Shipped<br />on {new Date(data?.tracking?.find(status => status.status === 'shipped')?.date).toLocaleDateString()}
                                            <br />
                                            {new Date(data?.tracking?.find(status => status.status === 'shipped')?.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>

                                    </div>
                                    <div>
                                        <Button style={{ marginRight: '-70px' }} onClick={handlechange} className='statusUpdate1'>Update Status as Delivered</Button>
                                        <ChangestatusPage open={addModal} handlechange={handlechange} getdata={getdata} dataId={id} status={status} />
                                    </div>
                                </div>
                            </div>
                        }

                        {data?.status === "delivered" &&
                            <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                                <div style={{ paddingLeft: '5%', paddingRight: '3%', width: '100%' }} className='d-flex mt-4'>
                                    <div>
                                        <p className='d-flex justify-content-center align-items-center mt-1 stage4' ><BiCheck style={{ color: 'white' }} /></p>
                                    </div>
                                    <img className='d-flex proces mt-3' src={step2} alt="" />
                                    <div>
                                        <p className='d-flex justify-content-center align-items-center mt-1 stage4' ><BiCheck style={{ color: 'white' }} /></p>
                                    </div>
                                    <img className='d-flex proces mt-3' src={step2} alt="" />
                                    <div>
                                        <p className='d-flex justify-content-center align-items-center mt-1 stage4' ><BiCheck style={{ color: 'white' }} /></p>
                                    </div>
                                    <hr />
                                </div>
                                <div style={{ paddingLeft: '2%', paddingRight: '2%' }} className='d-flex justify-content-between'>
                                    <div className='text-center' style={{ width: '200px', marginLeft: '-45px' }}>
                                        <p className='papa'>Processed<br />on {new Date(data?.tracking?.find(status => status.status === 'process')?.date).toLocaleDateString()}
                                            <br />
                                            {new Date(data?.tracking?.find(status => status.status === 'process')?.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
                                    </div>
                                    <div className='text-center' style={{ width: '200px', marginLeft: '-10px' }}>
                                        <p className='papa'>Shipped<br />on {new Date(data?.tracking?.find(status => status.status === 'shipped')?.date).toLocaleDateString()}
                                            <br />
                                            {new Date(data?.tracking?.find(status => status.status === 'shipped')?.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>

                                    </div>
                                    <div className='text-center' style={{ width: '200px', marginRight: '-60px' }}>
                                        <p className='papa'>Delivered<br />on {new Date(data?.tracking?.find(status => status.status === 'delivered')?.date).toLocaleDateString()}
                                            <br />
                                            {new Date(data?.tracking?.find(status => status.status === 'delivered')?.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>

                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                    <hr></hr>
                    <div id="invoice-container" >

                        <div style={{ marginLeft: '10px', marginTop: '30px' }} >
                            <Row>
                                <Col lg={12}>
                                    <p style={{ color: '#333333', fontSize: '20px', fontWeight: '700', fontFamily: 'Lato' }}>Order info</p>
                                </Col>
                                <Col className='mt-2' lg={4} >
                                    <div >
                                        <Label>
                                            <div className='d-flex'>
                                                <p className='title' style={{ width: '190px' }}>GST Number<span ></span></p>
                                                <p className='title1'>: {data?.gstDetails?.gstNo}</p>
                                            </div>
                                            {/* <span style={{ color: 'gray', fontSize: '15px' }}> </span>
                                        <span>  </span> */}
                                        </Label>
                                    </div>
                                    <div >
                                        <Label>
                                            <div className='d-flex'>
                                                <p className='title' style={{ width: '190px' }}>HSN<span ></span></p>
                                                <p className='title1'>
                                                    :{data?.products?.length > 0 ? data.products[0].product?.hsnCode : "HSN Code not available"}
                                                </p>

                                            </div>
                                            {/* <span style={{ color: 'gray', fontSize: '15px' }}> </spa
                                        <span>  </span> */}
                                        </Label>
                                    </div>
                                    <div>
                                        <Label>
                                            <div className='d-flex'>
                                                <p className='title' style={{ width: '190px' }}>Ordered Date<span ></span></p>
                                                <p className='title1'>: {new Date(data?.createdAt).toLocaleDateString()}</p>
                                            </div>
                                        </Label>
                                    </div>
                                    <div >
                                        <Label>
                                            <div className='d-flex'>
                                                <p className='title' style={{ width: '190px' }}>Expected Delievery Date<span ></span></p>
                                                <p className='title1'>: {new Date(data?.expectedDeliveryDate).toLocaleDateString()}</p>
                                            </div>
                                        </Label>
                                    </div>

                                </Col>
                                <Col lg={4} >
                                    <div className='mb-1 d-flex' >
                                        <Label>
                                            <div className='d-flex'>
                                                <p className='title' style={{ width: '150px' }}>Name<span ></span></p>
                                                <p className='title1'>: {data?.shipping?.firstName} {data?.shipping?.lastName}</p>
                                            </div>
                                        </Label>
                                    </div>
                                    <div className='mb-1 d-flex' >
                                        <Label>
                                            <div className='d-flex'>
                                                <p className='title' style={{ width: '150px' }}>Email ID<span ></span></p>
                                                <p className='title1'>: {data?.shipping?.email} </p>
                                            </div>
                                        </Label>
                                    </div>
                                    <div className='mb-1 d-flex' >
                                        <Label>
                                            <div className='d-flex'>
                                                <p className='title' style={{ width: '150px' }}>Mobile Number<span ></span></p>
                                                <p className='title1'>: {data?.shipping?.mobile}</p>
                                            </div>
                                        </Label>
                                    </div>
                                    <div className='mb-1 d-flex' >
                                        <Label>
                                            <div className='d-flex'>
                                                <p className='title' style={{ width: '150px' }}>Alternate Number<span ></span></p>
                                                <p className='title1'>: {data?.shipping?.alternateMobile}</p>
                                            </div>
                                        </Label>
                                    </div>


                                </Col>
                                <Col lg={4} >
                                    <div >
                                        <Label>
                                            <div className='d-flex'>
                                                <p className='title' style={{ width: '90px' }}>Address</p>
                                                <p className='ms-4 title1' >: {data?.shipping?.address}, {data?.shipping?.landmark},{data?.shipping?.city},{data?.shipping?.state}</p>
                                            </div>
                                        </Label>
                                    </div>
                                    <div >
                                        <Label>
                                            <div className='d-flex'>
                                                <p className='title' style={{ width: '90px' }}>Land Mark<span ></span></p>
                                                <p className='title1'>: {data?.shipping?.landmark}</p>
                                            </div>
                                        </Label>
                                    </div>
                                    <div>
                                        <Label>
                                            <div className='d-flex'>
                                                <p className='title' style={{ width: '90px' }}>Pincode<span ></span></p>
                                                <p className='title1'>: {data?.shipping?.pincode}</p>
                                            </div>
                                        </Label>
                                    </div>


                                </Col>

                            </Row>
                        </div>
                        <hr></hr>
                        {/* <div >
                            <div >
                                <div style={{ fontFamily: 'Roboto, sans-serif', display: 'flex', alignItems: 'center' }}>
                                </div>
                                <div className='mt-5'>
                                    <Row>
                                        <Col lg={4} >
                                            <div style={{ fontFamily: 'Roboto, sans-serif', display: 'flex', alignItems: 'center' }}>
                                                <img src={contact1} alt="Logo" style={{ width: '50px', height: '50px', marginRight: '5px' }} />
                                                <h5 style={{ marginRight: '5px' }}>CUSTOMER INFO</h5>
                                            </div>
                                            <div className=' mt-3 ' style={{ marginLeft: '10px' }}>
                                                <div className='mb-1 d-flex' >
                                                    <Label>
                                                        <span style={{ color: 'gray' }}>Name :</span>
                                                        <span> {data?.customer?.name} </span>
                                                    </Label>
                                                </div>
                                                <div className='mb-1 d-flex' >
                                                    <Label>
                                                        <span style={{ color: 'gray' }}>Email :</span> <span> {data?.customer?.email} </span>
                                                    </Label>
                                                </div>
                                                <div className='mb-1 d-flex' >
                                                    <Label>
                                                        <span style={{ color: 'gray' }}>Mobile :</span> <span> {data?.customer?.mobile} </span>
                                                    </Label>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={4} >
                                            <div style={{ fontFamily: 'Roboto, sans-serif', display: 'flex', alignItems: 'center' }}>
                                                <img src={shop1} alt="Logo" style={{ width: '50px', height: '50px', marginRight: '5px' }} />
                                                <h5 style={{ marginRight: '5px' }}>SHIPPING INFO</h5>
                                            </div>
                                            <div className='mt-3' style={{ marginLeft: '10px' }}>
                                                <div className='mb-1 ' >
                                                    <Label style={{ maxWidth: '250px', minWidth: '250px' }} >
                                                        <span style={{ color: 'gray' }}>Address :</span> <span> {data?.shipping?.address} </span>
                                                        <span>{data?.shipping?.landmark}</span>,
                                                        <span>{data?.shipping?.locality}</span>,
                                                        <span>{data?.shipping?.city}</span>,
                                                        <span>{data?.shipping?.state}</span>
                                                    </Label><br />
                                                    <Label>
                                                        <span style={{ color: 'gray' }}>LandMark :</span>
                                                        <span> {data?.shipping?.landmark} </span>
                                                    </Label>  <br />

                                                    <Label>
                                                        <span style={{ color: 'gray' }}>Pincode :</span>
                                                        <span> {data?.shipping?.pincode} </span>
                                                    </Label> <br />

                                                    <Label>
                                                        <span style={{ color: 'gray' }}>Mobile Number :</span>
                                                        <span> {data?.shipping?.alternateMobile} </span>
                                                    </Label> <br />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={4} >
                                            <div >
                                                <div style={{ fontFamily: 'Roboto, sans-serif', display: 'flex', alignItems: 'center', }}>
                                                    <img src={payment1} alt="Logo" style={{ width: '50px', height: '50px', marginRight: '5px' }} />
                                                    <h5 style={{ marginRight: '5px' }}>PAYMENT INFO</h5>
                                                </div>
                                                <div className='mt-3' style={{ marginLeft: '10px' }}>
                                                    <div>
                                                        <Label>
                                                            <span style={{ color: 'gray' }}>Payment Method :</span>
                                                            <span> {data?.payment?.mode} </span>
                                                        </Label>
                                                    </div>
                                                    <div className='mb-1 '>
                                                        <Label>
                                                            <span style={{ color: 'gray' }}>Payment Status :</span>
                                                            <span>

                                                                <Badge className='Customer-Badge'
                                                                    color={getStatusColor(data?.paymentStatus)}>{data?.paymentStatus}</Badge>
                                                            </span>
                                                        </Label>
                                                    </div>
                                                    <div>
                                                        <Label>
                                                            <span style={{ color: 'gray' }}>Total Payment :</span>
                                                            <span>{data?.totalAmount ? data.totalAmount.toLocaleString("en-IN", {
                                                                style: "currency",
                                                                currency: "INR",
                                                            }) : '0.00'}</span>
                                                        </Label>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div> */}
                        <Col lg={12}>
                            <p style={{ color: '#333333', fontSize: '20px', fontWeight: '700', fontFamily: 'Lato' }}>Product</p>
                        </Col>
                        <div>
                            <Row>
                                <Col lg={8}>
                                    {/* <DataTable
                                            noHeader
                                            highlightOnHover
                                            fixedHeader
                                            fixedHeaderScrollHeight='450px'
                                            data={data?.products}
                                            columns={dataTable}
                                            customStyles={customStyles}
                                            responsive={true}
                                        // columns={modifiedColumns}
                                        /> */}
                                    <div style={{ border: '1px solid #F0F0F0', borderRadius: '15px', padding: '20px', height: '260px' }} className="d-block">
                                        {data?.products && data.products.length > 0 ? (
                                            <>

                                                <div>
                                                    <p style={{ fontSize: '16px', fontWeight: '700', fontFamily: 'Lato', color: '#2B2B2D' }}>
                                                        {data.products[0]?.product?.name}
                                                    </p>
                                                </div>
                                                <div className='d-flex'>
                                                    <div className="d-flex">
                                                        <div>
                                                            {data.products[0]?.file?.[0] ? (
                                                                // Check if the file is a PDF
                                                                data.products[0]?.file?.[0].endsWith('.pdf') ? (
                                                                    // If it's a PDF, display a PDF icon instead of the file itself
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <img
                                                                            src={data.products[0]?.file?.[0].endsWith('.pdf') ? 'https://aimmgr.s3.ap-south-1.amazonaws.com/mgr/public/socialpost/file-blvvhhfusm11y4jw4.png' : data.products[0]?.file?.[0]}
                                                                            alt="PDF Icon"
                                                                            style={{ width: '70px', height: '70px' }}
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    // If it's an image, display the image
                                                                    <img
                                                                        style={{ width: '150px' }}
                                                                        src={data.products[0]?.file?.[0]}
                                                                        alt={data.products[0]?.product?.name || 'Product Image'}
                                                                    />
                                                                )
                                                            ) : (
                                                                <p>No file available</p>
                                                            )}
                                                        </div>

                                                        <div className="d-block ms-3">
                                                            {data.products[0]?.field?.map((field, i) => (
                                                                <div style={{ marginTop: i !== 0 ? '-10px' : '0' }} key={i}>
                                                                    <div className="d-flex">
                                                                        <p className="title" style={{ width: '120px', fontWeight: '400' }}>
                                                                            {field?.fieldType?.name}
                                                                        </p>
                                                                        <p className="title1">: <span className="ms-2">{field?.option?.name}</span></p>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className="d-block ms-5">
                                                            <div className="d-flex">
                                                                <p className="title" style={{ width: '120px', fontWeight: '400' }}>Quantity</p>
                                                                <p className="title1">: <span className="ms-2">{data.products[0]?.quantity}</span></p>
                                                            </div>
                                                            <div style={{ marginTop: '-10px' }}>
                                                                <div className="d-flex">
                                                                    <p className="title" style={{ width: '120px', fontWeight: '400' }}>Total Amount</p>
                                                                    <p className="title1">: <span className="ms-2">{data.products[0]?.total}</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='d-block ms-5'>
                                                        <div>
                                                            <Button style={{ color: 'white', background: '#E4510B', border: 'none' }} onClick={handleDownload}>
                                                                {/* Print <IoPrintOutline size={25} style={{ color: 'white' }} /> */}
                                                                Download <MdOutlineFileDownload size={25} style={{ color: 'white' }} />
                                                            </Button>
                                                        </div>
                                                        <div>
                                                            <Button className='mt-2' style={{ color: '#E4510B', background: 'white', border: '1px solid #E4510B', fontWeight: '400' }} onClick={() => viewCustom(data?.products[0])}>
                                                                {/* Print <IoPrintOutline size={25} style={{ color: 'white' }} /> */}
                                                                View <MdOutlineRemoveRedEye style={{ color: '#E4510B' }} />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <p>No product data available.</p>
                                        )}
                                    </div>
                                </Col>
                                <Col lg={4} >
                                    <div style={{ border: '1px solid #F0F0F0', borderRadius: '15px', padding: '20px' }}>
                                        <Col lg={12}>
                                            <p style={{ color: '#333333', fontSize: '20px', fontWeight: '700', fontFamily: 'Lato' }}>Payment info</p>
                                        </Col>
                                        <div className='mt-4' style={{ marginLeft: '10px' }}>
                                            <div style={{ marginTop: '-15px' }}>
                                                <Label>
                                                    <div className='d-flex'>
                                                        <p className='title' style={{ width: '130px', fontWeight: '400' }}>Payment Method<span ></span></p>
                                                        <p className='title1'>: <span className='ms-2'>{data?.payment?.mode}</span></p>
                                                    </div>
                                                </Label>
                                            </div>
                                            {data?.payment?.mode !== "cash" &&
                                                <>
                                                    <div style={{ marginTop: '-15px' }}>
                                                        <Label>
                                                            <div className='d-flex'>
                                                                <p className='title' style={{ width: '130px', fontWeight: '400' }}>Transaction Id<span ></span></p>
                                                                <p className='title1'>: <span className='ms-2'>{data?.payment?.paymentId?.bank_transaction_id}</span></p>
                                                            </div>
                                                        </Label>
                                                    </div>
                                                    <div style={{ marginTop: '-15px' }}>
                                                        <Label>
                                                            <div className='d-flex'>
                                                                <p className='title' style={{ width: '130px', fontWeight: '400' }}>Paid On<span ></span></p>
                                                                <p className='title1'>: <span className='ms-2'>{new Date(data?.payment?.paymentId?.paidOn).toLocaleDateString()}</span></p>
                                                            </div>
                                                        </Label>
                                                    </div>
                                                </>
                                            }
                                            <div style={{ marginTop: '-15px' }}>
                                                <Label>
                                                    <div className='d-flex'>
                                                        <p className='title' style={{ width: '130px', fontWeight: '400' }}>Product Amount<span ></span></p>
                                                        <p className='title1'>:  <span className='ms-2'>{data?.totalGrossAmount ? data?.totalGrossAmount.toLocaleString("en-IN", {
                                                            style: "currency",
                                                            currency: "INR",
                                                        }) : '0.00'}</span></p>
                                                    </div>
                                                </Label>
                                            </div>
                                            <div style={{ marginTop: '-15px' }}>
                                                <Label>
                                                    <div className='d-flex'>
                                                        <p className='title' style={{ width: '130px', fontWeight: '400' }}>Discount<span ></span></p>
                                                        <p style={{ color: '#308505' }} className='title1'>:<span className='ms-2'>- {data?.totalDiscount ? data?.totalDiscount.toLocaleString("en-IN", {
                                                            style: "currency",
                                                            currency: "INR",
                                                        }) : '0.00'}</span></p>
                                                    </div>
                                                </Label>
                                            </div>
                                            <div style={{ marginTop: '-15px' }}>
                                                <Label>
                                                    <div className="d-flex">
                                                        <p className="title" style={{ width: '130px', fontWeight: '400' }}>
                                                            Delivery Charge<span></span>
                                                        </p>
                                                        <p className="title1">
                                                            : <span className="ms-2" style={{ color: data?.shippingCharge === 0 ? '#308505' : 'inherit' }}>
                                                                {data?.shippingCharge === 0
                                                                    ? 'Free'
                                                                    : data?.shippingCharge?.toLocaleString("en-IN", {
                                                                        style: "currency",
                                                                        currency: "INR",
                                                                    }) || '0.00'}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </Label>
                                            </div>
                                            <div style={{ marginTop: '-15px' }}>
                                                <Label>
                                                    <div className='d-flex'>
                                                        <p className='title' style={{ width: '130px', fontWeight: '400' }}>Total Amount<span ></span></p>
                                                        <p className='title1'>:  <span className='ms-2'>{data?.totalAmount ? data?.totalAmount.toLocaleString("en-IN", {
                                                            style: "currency",
                                                            currency: "INR",
                                                        }) : '0.00'}</span></p>
                                                    </div>
                                                </Label>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div >
                    {/* <div style={{ marginBottom: '80px' }}>
                        <div style={{ fontFamily: 'Roboto, sans-serif', display: 'flex', alignItems: 'center' }}>
                            <img src={note1} alt="Logo" style={{ width: '50px', height: '50px', marginRight: '5px' }} />
                            <h5 style={{ marginRight: '5px' }}>ORDER STATUS</h5>

                            <div style={{ marginLeft: 'auto' }}>
                                <Button color='primary'>
                                    <a className='text-decoration-none' style={{ color: 'white' }} target='_blank' href={pdffile} type="button">Download Invoice </a>
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: '50px' }}>
                        <Row>
                            <Col lg={12}>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>STATUS</th>
                                            <th>DATE</th>
                                            <th>DESCRIPTION</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.tracking?.map((track, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td><p>{track?.status}</p></td>
                                                <td><p>{new Date(track?.date).toLocaleDateString()}</p></td>
                                                <td><p>{track?.description}</p></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </div> */}
                    <Preview modal={modal} toggle={toggle} cardImages={customizeImages} />
                </div >
            </div >
            <FooterSection />
        </>
    )
}

export default DashBoardViewPage
