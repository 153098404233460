import React, { useEffect, useRef, useState } from 'react'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Card, Col, Form, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap'
import { ChevronDown, X, ArrowLeft } from 'react-feather'
import "./index.css"
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import axios from "axios"
import { addCart, CheckPincode, getCartRate, product, productOG, quantityCountApi, ratingReview, wishList } from '../../ApiConfigs/ApiConfig'
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import img1 from "../../assets/images/banner/gallery1.jpg"
import img2 from "../../assets/images/banner/gallery2.jpg"
import img3 from "../../assets/images/banner/gallery3.jpg"
import star from "../../assets/images/logo/Star 2.png"
import star5 from "../../assets/images/logo/Star 5.png"
import emptyStarIcon from "../../assets/images/logo/emptystar.svg"
import line from "../../assets/images/logo/Line 1.png"
import { IoIosAddCircle } from "react-icons/io";
import man from '../../assets/images/banner/man.jpg'
import man1 from '../../assets/images/banner/man1.jpg'
import man2 from '../../assets/images/banner/man2.jpg'
import man3 from '../../assets/images/banner/man3.jpg'
import man4 from '../../assets/images/banner/man4.jpg'
import man5 from '../../assets/images/banner/serious-man-looking-camera_23-2147799044.avif'
import review1 from "../../assets/images/banner/review1.png";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { GoDotFill } from "react-icons/go";
import { FiShoppingCart } from "react-icons/fi";
import { BiEditAlt } from "react-icons/bi";
import { FiUpload } from "react-icons/fi";
import { multiImageUpload } from '../admin/upload'
import toast from 'react-hot-toast'
import Navbar from '../navbar'
import FooterSection from '../footer'
import ImageSlider from './productImage'
import heart from "../../assets/images/logo/heart.png"
import { RiImageAddFill } from "react-icons/ri";
import { HiInformationCircle } from "react-icons/hi";
import { TbTruckDelivery } from "react-icons/tb";
import { HtmlHeaderElements } from '../common/CommonLayout'
import Slider from 'react-slick'
import FinalAuthModal from '../navbar/finalAuth'
import { FaAngleRight } from "react-icons/fa6";


function ProductView() {

    const inputRef = useRef(null);
    const inputRef1 = useRef(null);
    const [profileImages, setProfileImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [load, setLoad] = useState(false);
    const [dataa, setData] = useState();
    const [dataa1, setData1] = useState();
    const { id } = useParams();
    const [quantity, setQuantity] = useState(1);
    const [quantityCount, setQauntityData] = useState();
    const [quantityRate, setQuantityRate] = useState();
    const [payloadData, setPayloadData] = useState({})
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const [selectedSize, setSelectedSize] = useState('');
    const [isImageUploaded, setIsImageUploaded] = useState(false);
    const [loader, setLoader] = useState(false);
    const [minimumQuantity, setMinimumQuantity] = useState()
    const [maximumQuantity, setMaximumQuantity] = useState()
    const [quantityError, setQuantityError] = useState('');
    const [open, setOpen] = useState('1');
    const location = useLocation();
    const toggle1 = () => setModal1(!modal1);
    const [isDescription, setIsDescription] = useState(true);
    const [isFaq, setIsFaq] = useState(false);
    const [isReview, setIsReview] = useState(false);
    const [modal1, setModal1] = useState(false);
    const [isToggle, setToggle] = useState(false)
    const [wishlistData, setWishlistData] = useState()
    const [review, setReview] = useState()

    console.log(review)

    const wishlist = async () => {
        try {
            const response = await axios.get(`${wishList}`)
            setWishlistData(response?.data?.result)
        } catch (error) {

        }
    }
    useEffect(() => {
        wishlist()
    }, [])


    const toggleAccordion = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    const toggleEmail = () => {
        setToggle(!isToggle)
    }

    const productName = window.location.pathname.split('/').pop();

    const CardImg = [
        ...(dataa?.image ? [dataa.image] : []),
        ...(dataa?.galleryImages ?? [])
    ]

    const amount = quantityRate?.amount
    const totalAmount = amount * quantity;
    const actualAmount = dataa?.amount * quantity;
    const offerAmount = dataa?.offerAmount * quantity;

    const final = quantity * amount;


    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const [selectedOptions, setSelectedOptions] = useState({});

    const handleOptionChange = async (fieldType, selectedOption) => {
        setSelectedOptions(prevState => {
            const updatedOptions = {
                ...prevState,
                [fieldType]: selectedOption
            };
            // Convert updatedOptions to an array with fieldType included
            const optionsArray = Object.entries(updatedOptions).map(([fieldType, option]) => ({
                fieldType,
                option
            }));

            // Store as an array in localStorage
            localStorage.setItem('selectedOptions', JSON.stringify(optionsArray));
            return updatedOptions;
        });
    };

    const handleSizeChange = (event) => {
        setSelectedSize(event.target.value);
        localStorage.setItem('customizeImageSize', event.target.value);
    };

    useEffect(() => {

        const handleOnChange = async () => {
            if (!dataa || !dataa._id || !dataa.fieldType) {
                return; // Exit early if dataa or its required properties are undefined
            }

            const payload = {
                product: dataa?._id,
                quantity: quantity,
                field: dataa?.fieldType?.map((eachItem) => (
                    {
                        fieldType: eachItem?.name?._id,
                        option: selectedOptions[eachItem?.name?._id] || null,
                    }
                )),
            };
            try {
                const response = await axios.post(getCartRate, payload);
                setQuantityRate(response?.data?.result)
            } catch (error) {
            }
        }

        handleOnChange();
        const optionsArray = dataa?.fieldType?.map((eachItem) => (
            {
                fieldType: eachItem?.name?._id,
                option: selectedOptions[eachItem?.name?._id] || null,
            }
        ));

        localStorage.setItem('selectedOptions', JSON.stringify(optionsArray)); // Save selected options to localStorage
        localStorage.setItem('quantity', JSON.stringify(quantity));
    }, [selectedOptions, dataa, quantity])

    useEffect(() => {
        if (dataa?.customizeImageSize && dataa.customizeImageSize.length > 0) {
            setSelectedSize(dataa.customizeImageSize[0]);
            localStorage.setItem('customizeImageSize', dataa.customizeImageSize[0]);
        }


    }, [dataa]);

    // const handleQuantityInput = (event) => {
    //     const value = event.target.value;

    //     if (value.length >= 2) {
    //         handleQuantityChange(event); // Trigger the main function only after two digits
    //     } else {
    //         setQuantity(value); // Update the state immediately with the input value
    //     }
    // };

    // const handleQuantityInput = (event) => {
    //     const value = event.target.value;

    //     // Clear the error message as user starts typing
    //     setQuantityError("");

    //     // Condition: Clear the field when entering value manually (empty to allow manual input)
    //     if (value === "") {
    //         setQuantity("");
    //     } else if (value.length <= 1) {
    //         handleQuantityChange(event);  // Update state for every input
    //     } else {
    //         handleQuantityChange(event); // Call main function after minimum length reached
    //     }
    // };



    const handleQuantityChange = async (event) => {

        const newQuantity = parseInt(event.target.value);

        // Clear the input field if quantity type is manual and the new value is invalid
        if (dataa?.quantityType !== 'dropdown' && isNaN(newQuantity)) {
            setQuantity(''); // Clear the input box for manual input
            setQuantityError(''); // Clear error when the field is cleared
            localStorage.removeItem('quantity');
            return;
        }

        if (newQuantity <= 0) {
            setQuantity(0);
            localStorage.setItem('quantity', 0);
            return;

        }
        if (newQuantity >= minimumQuantity && newQuantity <= maximumQuantity) {
            setQuantity(newQuantity);
            localStorage.setItem('quantity', newQuantity);

            const payload = {
                product: dataa?._id,
                quantity: newQuantity,
                field: dataa?.fieldType?.map((eachItem) => ({
                    fieldType: eachItem?.name?._id,
                    option: selectedOptions[eachItem?.name?._id] || null,
                })),
            };

            try {
                const response = await axios.post(getCartRate, payload);
                setQuantityRate(response?.data?.result);
            } catch (error) {
            }
        } else {
            if (newQuantity < minimumQuantity) {
                setQuantityError(`Minimum quantity allowed is ${minimumQuantity}`);
            } else if (newQuantity > maximumQuantity) {
                setQuantityError(`Maximum quantity allowed is ${maximumQuantity}`);
            }
        }
    };



    useEffect(() => {
        if (Object.keys(selectedOptions).length === 0) {
            const initialOptions = {};
            dataa?.fieldType?.forEach((eachItem) => {
                const fieldType = eachItem?.name?._id;
                const firstOption = eachItem?.options[0]?._id;
                initialOptions[fieldType] = firstOption;
            });

            setSelectedOptions(initialOptions);
        }
    }, [dataa]);

    const isLogin = localStorage.getItem('role')

    const checkUserLogin = () => {

        inputRef?.current?.click();

    };

    const navToCustomize = () => {
        navigate('/products/customizeProduct')
    }


    // const handleProfileUpload = async (e) => {
    //     if (e.target.files) {
    //         try {
    //             setLoader(true)
    //             const formData = new FormData();
    //             const uploadedFiles = [];

    //             for (let i = 0; i < e.target.files.length; i++) {
    //                 formData.append('files', e.target.files[i]);
    //             }

    //             const uploadData = await multiImageUpload(formData);

    //             if (uploadData && uploadData.result && uploadData.result.length) {
    //                 uploadData.result.forEach((file) => {
    //                     uploadedFiles.push({
    //                         name: file.originalname,
    //                         url: file.location,
    //                     });
    //                 });

    //                 setProfileImages([...profileImages, ...uploadedFiles]);
    //                 setIsImageUploaded(true);
    //                 setModal1(false)
    //             }
    //         } catch (error) {
    //             toast.error('Something went wrong...');
    //         } finally {
    //             setLoader(false)

    //         }
    //     }
    // };

    const handleProfileUpload = async (e) => {
        if (e.target.files) {
            try {
                setLoader(true);
                const uploadedFiles = [];

                const files = Array.from(e.target.files);
                const formData = new FormData();

                // Optionally compress or process images here before uploading
                files.forEach(file => {
                    formData.append('files', file);
                });

                // Uploading the files in parallel
                const uploadData = await multiImageUpload(formData);

                if (uploadData && uploadData.result && uploadData.result.length) {
                    uploadData.result.forEach((file) => {
                        uploadedFiles.push({
                            name: file.originalname,
                            url: file.location,
                        });
                    });

                    setProfileImages(prevImages => [...prevImages, ...uploadedFiles]);
                    setIsImageUploaded(true);
                    setModal1(false);
                }
            } catch (error) {
                toast.error('Something went wrong...');
            } finally {
                setLoader(false);
            }
        }
    };


    const handleRemoveImage = (index) => {
        const updatedImages = [...profileImages];
        updatedImages.splice(index, 1);
        setProfileImages(updatedImages);
        setSelectedImageIndex(null);
    };



    const token = localStorage.getItem('token')

    const toggleUpload = () => {
        if (token) {
            toggle1()
        } else {
            toggleEmail()
        }
    }

    const additionalData = async () => {
        try {
            setLoading(true);
            if (token) {
                const response = await axios.get(`${productOG}/${productName}`)
                setData(response?.data?.result)
            } else {
                const response = await axios.get(`${product}/${productName}`)
                setData(response?.data?.result)
            }
        } catch (error) {

        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        additionalData();
    }, [location.pathname]);


    const rating = async () => {
        try {
            const response = await axios.get(`${ratingReview}?product_id=${dataa?._id}`)
            setReview(response?.data)
            console.log(review)
        } catch (error) {

        }
    }
    useEffect(() => {
        rating()
    }, [dataa?._id])


    const additionalData1 = async () => {
        try {
            setLoading(true);
            let url = product;
            const response = await axios.get(`${url}?productCategory=${dataa?.productCategory?._id ? dataa?.productCategory?._id : ''}`);
            setData1(response?.data?.result);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        additionalData1();
    }, [dataa?.productCategory?._id]);


    const quantityData = async () => {
        try {
            const response = await axios.get(`${quantityCountApi}/${productName}`);
            setQauntityData(response?.data?.result);

            const min = response?.data?.result?.[0]?.from ?? 1;
            const lastIndex = response?.data?.result?.length - 1;
            const max = response?.data?.result?.[lastIndex]?.to;

            setMinimumQuantity(min);
            setMaximumQuantity(max);
            setQuantity(min);
        } catch (error) {
        }
    };
    useEffect(() => {
        additionalData()
    }, [])

    useEffect(() => {
        quantityData(dataa?._id)
    }, [dataa?._id])

    const formSchema = yup.object().shape({

        image: yup.string().required('Please Upload Your Design'),
    })
    const {
        reset,
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({ mode: 'onChange', resolver: yupResolver(formSchema) });

    const handleRest = () => {
        reset({

        })
    }
    const likeProduct = async (id) => {
        if (!localStorage.getItem('token')) {
            toggle();
        }

        try {
            const response = await axios.post(wishList, { product: id });
            toast.success(response?.data?.msg);
            wishlist()
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };

    const likeProduct1 = async (id) => {
        if (!id) {
            console.error(id, "Invalid product ID");
            return;
        } else {
        }

        if (!localStorage.getItem('token')) {
            toggle();
        }

        try {
            const response = await axios.post(wishList, { product: id });
            toast.success(response?.data?.msg);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };

    const submitForm = async () => {
        if (dataa?.quantityType !== 'dropdown') {
            if (quantity < minimumQuantity) {
                toast.error(`Minimun Quantity is ${minimumQuantity}`)
            }
        }
        if (!isImageUploaded) {
            toast.error('Please upload your custom design.');
            return;
        }
        const image = profileImages
        const payload = {
            product: dataa?._id,
            quantity: quantity,
            field: dataa?.fieldType?.map((eachItem) => ({
                fieldType: eachItem?.name?._id,
                option: selectedOptions[eachItem?.name?._id] || null,
            })),
            size: selectedSize,
            file: profileImages?.map((el) => (

                el?.url

            ))

        };

        setPayloadData(payload)

        if (isLogin === 'USER') {
            try {
                const response = await axios.post(addCart, payload);
                toast.success(response?.data?.msg)
                localStorage.removeItem('AddCart')
                navigate('/myCart');
                localStorage.removeItem('selectedOptions');
                localStorage.removeItem('quantity');
            } catch (error) {
                toast.error(error?.response?.data?.msg)

            }
        } else {
            toggle();

        }


    }

    const addCartKey = localStorage.getItem('AddCart')

    const checkPincode = async (pincode) => {
        setLoad(true);
        try {
            const response = await axios.get(
                `${CheckPincode}/${dataa?._id}?pincode=${pincode}&quantity=${quantity}`,
                {}
            );
            // Handle response
            setDeliveryCheck(response?.data?.result)
        } catch (error) {
            toast.error(error?.response?.data?.msg || 'Error checking pincode');
        } finally {
            setLoad(false);
        }
    };
    // useEffect(() => {

    //     if (addCartKey == 'true') {
    //         autoAddCartFn()
    //     }

    // }, [addCartKey == 'true'])


    const dataImg = [
        img1,
        img2,
        img3,
    ]

    const navigate = useNavigate()

    const navToProduct = (category_url) => {
        navigate(`/products/${dataa?.productCategory?.category_url}`)
    }

    const navToHome = () => {
        navigate(`/`)
    }

    const navToProducts = () => {
        navigate('/products')
    }

    const [visibleImages, setVisibleImages] = useState(3);


    const handleShowMore = () => {
        setVisibleImages(CardImg.length);
    };

    const typeSpellings = {
        'general': 'General',
        'recemended': 'Recommended',
        'bulckprice': 'Bulk Price',
        'bestprice': 'Best Price'
    };

    const [inputValue, setInputValue] = useState('');
    const [deliveryCheck, setDeliveryCheck] = useState('');
    const expectedDelivery = deliveryCheck?.expectedDelivery;
    const formattedDate = expectedDelivery ? new Date(expectedDelivery).toLocaleDateString() : '';

    // const handleChange = (e) => {
    //     setInputValue(e.target.value);
    //     checkpincode()
    // };

    const handleChange = (e) => {
        const pincode = e.target.value;

        // Allow only numeric values and ensure the input is not more than 6 digits
        if (/^\d*$/.test(pincode) && pincode.length <= 6) {
            setInputValue(pincode);

            // Call checkPincode only when exactly 6 digits are entered
            if (pincode.length === 6) {
                checkPincode(pincode);
                setDeliveryCheck()  // API call only when length is 6
            }
        }
    };

    const truncateTitle = (title) => {
        return title.length > 70 ? `${title.slice(0, 70)}...` : title;
    };

    const handleDrop = (event) => {
        event.preventDefault();
        setLoader(true);
        const files = Array.from(event.dataTransfer.files);
        const newImages = files.map(file => ({
            url: URL.createObjectURL(file),
            file
        }));
        setProfileImages([...profileImages, ...newImages]);
        setLoader(false);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const [showAll, setShowAll] = useState(false);
    const [showAll2, setShowAll2] = useState(false);

    const handleToggleShowAll = () => {
        setShowAll(!showAll);
    };
    const handleToggleShowAll2 = () => {
        setShowAll2(!showAll2);
    };

    const descriptions = dataa?.productOverview || [];
    const displayedDescriptions = showAll ? descriptions : descriptions.slice(0, 1.5);
    const descriptions1 = dataa?.description || [];
    const displayedDescriptions2 = showAll2 ? descriptions1 : descriptions1.slice(0, 1.5);
    const [activeTab, setActiveTab] = useState('description');

    const handleDescriptionClick = () => {
        setIsDescription(true);
        setIsFaq(false);
        setIsReview(false);
        setActiveTab('description');
    };

    const handleFaqClick = () => {
        setIsFaq(!isFaq);
        setIsDescription(false);
        setIsReview(false);
        setActiveTab('faq');
    };

    const handleReviewClick = () => {
        setIsReview(!isReview);
        setIsFaq(false);
        setIsDescription(false);
        setActiveTab('review');
    };

    const getTabStyle = (tab) => ({
        color: activeTab === tab ? '#E4510B' : '#000',
        borderBottom: activeTab === tab ? '2px solid #E4510B' : 'none',
        cursor: 'pointer'
    });

    const navToViewPage = (product_url) => {
        navigate(`/products/viewProduct/${product_url}`);
    };

    const [showAll1, setShowAll1] = useState(false);

    const handleViewAll = () => {
        setShowAll1(true);
        navToProduct()
    };

    const handleClick = () => {
        if (!isLogin) {
            // toast.error('Please login');
            toggleEmail();
        } else {
            navigate(`/products/productCustomize/${dataa?._id}`);
        }
    };


    const cardDetails = [
        {
            id: "1",
            img: man4,
            name: "Palanikumar",
            date: "02 April 2024",
            description: "I needed new business cards in a hurry for a networking event, and Printon delivered with speed and precision. The online design tool was intuitive, and I had no trouble creating a professional-looking card."
        },
        {
            id: "2",
            img: man,
            name: "Riyazkhan",
            date: "24 May 2020",
            description: "I run a small online business and depend on eye-catching stickers and labels for packaging. The printing website has become my go-to for these essential branding elements. The stickers are of high quality and smooth finish.",
        },
        {
            id: "1",
            img: man2,
            name: "Logeshwaran",
            date: "04 April 2022",
            description: "I've been using this Printon for diaries, and I'm impressed with the durability and attention to detail. The paper quality is excellent. I appreciate the consistency in quality and will continue to be a loyal customer. ",
        },
        {
            id: "2",
            img: man3,
            name: "Rajesh",
            date: "02 May 2021",
            description: "I recently ordered custom calendars for a family reunion, and the Printon exceeded my expectations. The design process was user-friendly, allowing me to personalize each month with memorable photos.",
        },
        {
            id: "1",
            img: man1,
            name: "Arun",
            date: "30 July 2021",
            description: "I'm extremely satisfied with the quality, service, and variety offered by this Printon. Their commitment to excellence is evident in every product they deliver. Highly recommended for all your printing needs!",
        },
        {
            id: "2",
            img: man5,
            name: "Jabeer",
            date: "15 August 2020",
            description: "The giving the booklets a professional look and feel. The entire process, from designing to delivery, was seamless. I highly recommend their booklet printing services for anyone looking to make a strong impact at their events.",
        },
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        centerMode: false,
        arrow: false,
        autoplay: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    arrows: false,
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                },
            },
        ],

    };

    const sliderRef = useRef(null);

    const nextSlide = () => {
        sliderRef.current.slickNext();
    };

    const prevSlide = () => {
        sliderRef.current.slickPrev();
    };

    const reviews = review?.result || [];

    // Calculate total number of reviews
    const reviewCount = reviews.length;

    // Check if there are any reviews before calculating the average rating
    let averageRating = 0;
    if (reviewCount > 0) {
        averageRating = (reviews.reduce((acc, review) => acc + review.rating, 0) / reviewCount).toFixed(1);
    }

    // Generate stars based on the average rating
    const fullStars = Math.floor(averageRating); // Full stars based on average rating
    const halfStar = averageRating - fullStars >= 0.5; // Check if we need a half star
    const totalStars = 5; // Total number of stars



    return (
        <>
            <HtmlHeaderElements
                title={dataa?.metaTitle}
                description={dataa?.metaDescription}
                keywords={dataa?.metaKeywords}
            />
            <Navbar wishlistProps={wishlistData} />
            {loading ? (
                <div style={{ height: "80vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Spinner
                        color="primary"
                        size=""
                    >
                        Loading...
                    </Spinner>
                </div>
            ) : (
                <div className='pt-3 pb-5' style={{ paddingLeft: '5%', paddingRight: '5%' }}>

                    <p className='pt-3' style={{ cursor: 'pointer', color: '#333333', opacity: '0.8' }}><span onClick={navToHome}>Home</span> / <span onClick={navToProducts}>Products</span> / <span onClick={navToProduct}>{dataa?.productCategory?.name}</span > / <span style={{ color: '#E4510B' }}>{dataa?.name}</span> </p>

                    <Row>
                        <Col sm={12} lg={6}>
                            <Card style={{ border: 'none', width: '100%', height: 'auto' }} className='mb-3'>

                                <ImageSlider images={CardImg} />

                            </Card>
                            {/* <div className='d-none d-lg-block'>
                                <p className='pt-0' style={{ cursor: 'pointer' }}>
                                    <span>{dataa?.fullDescription}</span>
                                </p>
                            </div> */}
                            {/* <div >
                                <Accordion open={open} toggle={toggleAccordion}>
                                    <Row>
                                        {dataa?.description?.map((detail, i) => (
                                            <div key={detail?.id}>
                                                <AccordionItem>
                                                    <AccordionHeader targetId={i}><h5>{truncateTitle(detail?.title)}</h5></AccordionHeader>
                                                    <AccordionBody accordionId={i}>
                                                        {detail?.description}
                                                    </AccordionBody>
                                                </AccordionItem>
                                            </div>
                                        ))}
                                    </Row>
                                </Accordion>
                            </div> */}
                        </Col>

                        <Col sm={12} lg={6}>
                            <Card style={{ border: 'none' }}>
                                <div className='d-block'>
                                    <div>
                                        <h1 style={{ fontSize: '32px', fontWeight: '400', lineHeight: '21px', font: 'Lato', height: '22px' }} className='mb-2 mt-3 mt-lg-0'>{dataa?.name && dataa?.name?.length > 35 ? `${dataa?.name?.substring(0, 40)}...` : dataa?.name}</h1>
                                    </div>
                                    <div className='mt-sm-3'>
                                        {/* Render full stars */}
                                        {[...Array(fullStars)].map((_, index) => (
                                            <img key={index} src={star} alt={`star${index + 1}`} className='ms-1' />
                                        ))}

                                        {/* Render half star if necessary */}
                                        {halfStar && <img src={star5} alt="half-star" className='ms-1' />}

                                        {/* Render remaining empty stars */}
                                        {[...Array(totalStars - fullStars - (halfStar ? 1 : 0))].map((_, index) => (
                                            <img key={index} src={emptyStarIcon} alt={`empty-star${index + 1}`} className='ms-1' />
                                        ))}

                                        {/* Display the number of customer reviews */}
                                        <span
                                            style={{
                                                color: '#858585',
                                                font: 'Lato',
                                                fontWeight: '400',
                                                fontSize: '16px',
                                                lineHeight: '20px',
                                                marginLeft: '7px'
                                            }}
                                        >
                                            ({reviewCount} customer reviews)
                                        </span>
                                    </div>
                                </div>
                                {/* <div className='d-none d-lg-block mt-3'>
                                    {dataa?.productOverview?.map((eachItem, i) => (
                                        <p style={{ font: 'Lato', fontWeight: '400', fontSize: '16px', color: '#858585' }} className='pt-0'><span style={{ color: '#E4510B' }}><GoDotFill /></span> {eachItem?.description}</p>
                                    ))}
                                </div> */}
                                <div className='d-none d-lg-block mt-2'>
                                    {displayedDescriptions.map((eachItem, i) => (
                                        <div key={i} style={{ display: 'flex', alignItems: 'start' }}>
                                            <span style={{ color: '#E4510B', marginRight: '8px' }}><GoDotFill /></span>
                                            <p style={{ fontFamily: 'Lato', fontWeight: '400', fontSize: '16px', color: '#858585', margin: 0 }} className='pt-0'>
                                                {eachItem?.description}
                                            </p>
                                        </div>
                                    ))}
                                    {descriptions.length > 2 && (
                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                                            <button style={{ border: 'none', background: 'none', textDecoration: 'underline', color: '#E4510B', fontsize: '14px' }} onClick={handleToggleShowAll}>
                                                {showAll ? 'See Less' : 'See More'}
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <div className='d-lg-none mt-2'>
                                    {displayedDescriptions.map((eachItem, i) => (
                                        <p key={i} style={{ font: 'Lato', fontWeight: '400', fontSize: '16px', color: '#858585' }} className='pt-0'>
                                            <span style={{ color: '#E4510B' }}><GoDotFill /></span> {eachItem?.description}
                                        </p>
                                    ))}
                                    {descriptions.length > 2 && (
                                        <button style={{ border: 'none', background: 'none', textDecoration: 'underline', color: '#E4510B', fontsize: '14px' }} onClick={handleToggleShowAll}>
                                            {showAll ? 'See Less' : 'See More'}
                                        </button>
                                    )}
                                </div>
                                <Col sm={12}>
                                    <div><img style={{ width: '100%', height: '1px' }} src={line} alt="line" /></div>
                                </Col>
                                <div>
                                    <Row>

                                        {dataa?.fieldType?.map((eachItem, i) => (
                                            <Col sm={12} lg={6}>
                                                <React.Fragment key={i}>
                                                    <div className='d-lg-block'>
                                                        <Col sm={8}>
                                                            <h4 className='m-0 mt-2' style={{ fontWeight: '400', fontSize: '18px', font: 'Lato', color: '#262525' }}>
                                                                {eachItem?.name?.name}
                                                            </h4>
                                                        </Col>
                                                        <Col sm={8} className="position-relative w-100">
                                                            <select
                                                                name={eachItem?.name?._id}
                                                                className='form-control mt-md-3 pr-6'
                                                                onChange={(e) => handleOptionChange(eachItem?.name?._id, e.target.value)}
                                                            >
                                                                {eachItem?.options.map((each, k) => (
                                                                    <option key={k} value={each?._id}>{each?.name}</option>
                                                                ))}
                                                            </select>
                                                            <i className=" dropicon bi bi-caret-down position-absolute translate-middle-y"><ChevronDown />
                                                            </i>
                                                        </Col>
                                                    </div>
                                                </React.Fragment>
                                            </Col>
                                        ))}

                                        <Col lg={6}>
                                            <div>
                                                <Col sm={4}>
                                                    <h4 className='m-0 mt-2' style={{ fontWeight: '400', fontSize: '18px', font: 'Lato', color: '#262525' }}>Quantity</h4>
                                                </Col>
                                                <Col className='w-100' sm={8}>
                                                    <div>

                                                        {dataa?.quantityType === 'dropdown' ? (
                                                            <select
                                                                name='quantityCounter'
                                                                className='form-control mt-1 mt-md-3'
                                                                onChange={handleQuantityChange}
                                                            >

                                                                {quantityCount?.map((eachQuantity, i) => (
                                                                    <option key={i} value={eachQuantity?.to}>
                                                                        {eachQuantity?.to}
                                                                        {eachQuantity?.type !== 'general' && (
                                                                            <span className='ms-4'> ({typeSpellings[eachQuantity?.type] || eachQuantity?.type})</span>
                                                                        )}
                                                                    </option>
                                                                ))}

                                                            </select>
                                                        ) : (
                                                            <div>
                                                                <input
                                                                    type="number"
                                                                    name='quantity'
                                                                    style={{ border: '1px solid #D9D9D9' }}
                                                                    className='form-control mt-1 mt-md-3'
                                                                    value={quantity}
                                                                    onChange={handleQuantityChange}
                                                                    pattern="[0-9]+"
                                                                />
                                                                {quantityError && <span className="text-danger">{quantityError}</span>}
                                                            </div>
                                                            // <div>
                                                            //     <input
                                                            //         type="number"
                                                            //         name='quantity'
                                                            //         style={{ border: '1px solid #D9D9D9' }}
                                                            //         className='form-control mt-1 mt-md-3'
                                                            //         value={quantity}
                                                            //         onChange={handleQuantityInput}
                                                            //         required // Makes sure input is mandatory
                                                            //     />
                                                            //     {quantityError && <span className="text-danger">{quantityError}</span>}
                                                            // </div>
                                                        )}
                                                    </div>
                                                </Col>
                                            </div>
                                        </Col>
                                        {dataa?.customizeImageSize?.length > 0 ? (
                                            <Col lg={6}>
                                                <div>
                                                    <Col sm={4}>
                                                        <h4 className='m-0 mt-2' style={{ fontWeight: '400', fontSize: '18px', fontFamily: 'Lato', color: '#262525' }}>Size</h4>
                                                    </Col>
                                                    <Col className='w-100 position-relative' sm={8}>
                                                        <div>
                                                            <select
                                                                name='quantityCounter'
                                                                className='form-control mt-1 mt-md-3'
                                                                onChange={handleSizeChange}
                                                                value={selectedSize}
                                                            >
                                                                {dataa?.customizeImageSize?.map((size, index) => (
                                                                    <option key={index} value={size}>
                                                                        {size}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <i className="dropicon bi bi-caret-down position-absolute translate-middle-y">
                                                                <ChevronDown />
                                                            </i>
                                                        </div>
                                                    </Col>
                                                </div>
                                            </Col>
                                        ) : (null)}

                                        {/* <Col sm={12} md={6}>
                                            <div className='ms-2'>
                                                <Controller
                                                    name='image'
                                                    id='image'
                                                    control={control}
                                                    defaultValue=''
                                                    render={({ field }) => (<div className="d-flex" style={{ cursor: 'pointer' }}>
                                                        <input style={{ display: 'none' }} id="image" type="file" {...field} invalid={errors.image && true} ref={inputRef} onChange={handleProfileUpload} />
                                                        {errors.image && <FormFeedback>{errors.image.message}</FormFeedback>}
                                                    </div>)} />
                                            </div>
                                        </Col> */}
                                    </Row>
                                </div>
                                <Row>
                                    <div className='d-lg-flex d-md-flex d-sm-block'>
                                        <Col lg={6} md={6} sm={12}>
                                            <div className='d-block'>
                                                <div>
                                                    {totalAmount ? (
                                                        <>
                                                            <h5 className='price-head mt-3'>
                                                                {dataa?.offer ? (
                                                                    <>
                                                                        <span className='text-muted' style={{ textDecoration: 'line-through' }}>{actualAmount?.toLocaleString('en-IN', {
                                                                            maximumFractionDigits: 2,
                                                                            style: 'currency',
                                                                            currency: 'INR'
                                                                        })}</span>
                                                                        <span className='fw-bolder ms-1' style={{ color: 'green', fontSize: '22px' }}>{offerAmount?.toLocaleString('en-IN', {
                                                                            maximumFractionDigits: 2,
                                                                            style: 'currency',
                                                                            currency: 'INR'
                                                                        })}</span>
                                                                    </>
                                                                ) : (
                                                                    <span className='fw-bolder' style={{ color: '#E4510B', fontSize: '22px', lineHeight: '21px' }}>{totalAmount?.toLocaleString('en-IN', {
                                                                        maximumFractionDigits: 2,
                                                                        style: 'currency',
                                                                        currency: 'INR'
                                                                    })}</span>
                                                                )}<br />
                                                                <span style={{ fontWeight: '400', marginLeft: "1px", fontSize: '14px', color: '#464646' }}>inclusive of all taxes </span>
                                                                <span style={{ fontWeight: '400', fontSize: '14px', color: '#464646' }}>
                                                                    for <span className='fw-bold'>{quantity}</span> Qty (<span className='fw-bold'>{amount?.toLocaleString('en-IN', {
                                                                        maximumFractionDigits: 2,
                                                                        style: 'currency',
                                                                        currency: 'INR'
                                                                    })}</span> / piece)
                                                                </span>

                                                                {quantityRate?.savings > 0 && (
                                                                    <>
                                                                        <br />
                                                                        <span style={{ fontSize: '20px' }} className=''>₹{quantityRate?.savings?.toLocaleString('en-IN', {
                                                                            maximumFractionDigits: 2,
                                                                            style: 'currency',
                                                                            currency: 'INR'
                                                                        })}</span>
                                                                        <span style={{ fontSize: '16px', marginTop: '-10px' }} className='fw-normal'> - savings</span>
                                                                    </>
                                                                )}
                                                            </h5>
                                                            <div className="product-bulk">
                                                                <div class="myDIV">
                                                                    <p className='product-bulk-content'>
                                                                        Buy in bulk and save
                                                                        <span className="hover-icon ms-2">
                                                                            <HiInformationCircle size={20} />
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                                <div class="hide">
                                                                    <p>Bulk Quantities of Product at Discounted Rates</p>
                                                                </div>
                                                            </div>

                                                        </>
                                                    ) : (
                                                        null
                                                    )}
                                                </div>
                                                <div className='d-flex'>
                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start' }} >
                                                        {dataa?.upload ? (
                                                            <>
                                                                <button className='viewButton' disabled={!profileImages} onClick={submitForm}>Add to Cart <FiShoppingCart size={20} /></button>
                                                                <div>
                                                                    {!profileImages && <p className='mt-2' style={{ width: '250px', fontSize: '12px' }}><span className='text-danger'>*</span>Upload your Custom design to proceed!</p>}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <button className='viewButton' disabled={!profileImages} onClick={submitForm}>Add to Cart <FiShoppingCart /></button>
                                                        )}
                                                    </div>
                                                    {/* <Link to='/myCart'>
                                                        <div className='ms-3' style={{ border: '1px solid #C8C7C7', width: '50px', height: '50px', borderRadius: '4px', cursor: 'pointer' }}>
                                                            <span style={{ color: '#515151', fontSize: '32px', display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '9px' }}><FiShoppingCart /></span>
                                                        </div>
                                                    </Link> */}
                                                    <div className='ms-3' style={{ width: '50px', height: '50px', border: '1px solid #C8C7C7', borderRadius: '4px' }}>
                                                        {
                                                            isLogin ?
                                                                <div className="con-like">
                                                                    <input
                                                                        className="like"
                                                                        defaultChecked={dataa?.wishList}
                                                                        onClick={() => likeProduct(dataa?._id)}
                                                                        type="checkbox"
                                                                        title="like"
                                                                    />
                                                                    <div className="checkmark">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="outline" viewBox="0 0 24 24">
                                                                            <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Zm-3.585,18.4a2.973,2.973,0,0,1-3.83,0C4.947,16.006,2,11.87,2,8.967a4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,11,8.967a1,1,0,0,0,2,0,4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,22,8.967C22,11.87,19.053,16.006,13.915,20.313Z"></path>
                                                                        </svg>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="filled" viewBox="0 0 24 24">
                                                                            <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Z"></path>
                                                                        </svg>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="100" width="100" className="celebrate">
                                                                            <polygon className="poly" points="10,10 20,20"></polygon>
                                                                            <polygon className="poly" points="10,50 20,50"></polygon>
                                                                            <polygon className="poly" points="20,80 30,70"></polygon>
                                                                            <polygon className="poly" points="90,10 80,20"></polygon>
                                                                            <polygon className="poly" points="90,50 80,50"></polygon>
                                                                            <polygon className="poly" points="80,80 70,70"></polygon>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className='heartEmoji ms-1 mt-1 cursor-pointer' onClick={toggleEmail}>
                                                                    <img src={heart} height={40} width={40} />
                                                                </div>

                                                        }
                                                    </div>

                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={6} md={6} sm={12}>
                                            <div className='d-block mt-3'>
                                                {profileImages.length === 0 ? (
                                                    <div className=' button' style={{ display: 'flex', justifyContent: "end", textAlign: "end" }}>
                                                        <button style={{ width: '320px', height: '50px', fontSize: "14px", fontWeight: '700', font: 'Lato', color: 'white', background: '#E4510B', border: '1px solid #E4510B' }} className='btn' onClick={toggleUpload}> <span><FiUpload /></span> Upload Your Files</button>
                                                    </div>) : (
                                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                        {profileImages.map((image, index) => (
                                                            <div style={{ padding: '5px' }} key={index} className="m-2">
                                                                <div className='d-block ms-1'>
                                                                    <div className='d-flex mt-1'>
                                                                        <img
                                                                            alt={`preview image ${index + 1}`}
                                                                            src={image.url.endsWith('.pdf') ? 'https://aimmgr.s3.ap-south-1.amazonaws.com/mgr/public/socialpost/file-blvvhhfusm11y4jw4.png' : image.url}
                                                                            style={{
                                                                                width: '80px',
                                                                                height: '80px',
                                                                                border: '2px dashed #DFDFDF',
                                                                                borderRadius: '4px',
                                                                                cursor: 'pointer',
                                                                                position: 'relative'
                                                                            }}
                                                                            onClick={() => setSelectedImageIndex(index)}
                                                                        />
                                                                        <div className="cursor-pointer image-close" style={{ cursor: 'pointer' }} onClick={() => handleRemoveImage(index)}>
                                                                            <X style={{ width: '15px', height: '15px', backgroundColor: '#EEEEEE', borderRadius: '2px', position: 'absolute', marginLeft: '-18px', marginTop: '3px' }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <div style={{ padding: '5px' }} className="m-2">
                                                            <div className=''>
                                                                <div style={{
                                                                    width: '80px',
                                                                    height: '80px',
                                                                    border: '2px dashed #DFDFDF',
                                                                    borderRadius: '4px',
                                                                    cursor: 'pointer',
                                                                    position: 'relative'
                                                                }}
                                                                    className='d-flex justify-content-center align-items-center mt-1'>

                                                                    <input
                                                                        style={{ display: 'none' }}
                                                                        type="file"
                                                                        ref={inputRef1}
                                                                        accept=".jpeg,.png,.jpg"
                                                                        onChange={handleProfileUpload}
                                                                        multiple
                                                                    />
                                                                    <IoIosAddCircle
                                                                        style={{
                                                                            width: '30px',
                                                                            height: '30px'
                                                                        }}
                                                                        onClick={() => inputRef1?.current?.click()}
                                                                    />
                                                                    {/* <img
                                                                        alt="upload"
                                                                        src={upload}
                                                                        style={{
                                                                            width: '30px',
                                                                            height: '30px'
                                                                        }}
                                                                        onClick={() => inputRef1?.current?.click()}
                                                                    /> */}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {profileImages?.length > 0 ?
                                                    (
                                                        null
                                                    ) : (
                                                        <>
                                                            {/* <div style={{ width: '100%' }} className='d-flex align-items-center justify-content-center'>

                                                                <p
                                                                    className='m-0 mb-1 p-0'
                                                                    style={{
                                                                        color: '#333333D1',
                                                                        minWidth: 'fit-content',
                                                                        fontSize: '20px',
                                                                        fontWeight: '400',
                                                                    }}
                                                                >
                                                                    (or)
                                                                </p>

                                                            </div> */}
                                                            {dataa?.customizeImages?.length > 0 ? (
                                                                <div className='mt-2 button' style={{ display: 'flex', justifyContent: "end", textAlign: "end" }}>
                                                                    <button style={{ width: '320px', height: '50px', fontSize: "14px", fontWeight: '700', font: 'Lato', color: '#E4510B', background: 'transparant', border: '1px solid #E4510B' }} className='btn' onClick={handleClick} > <span><BiEditAlt /></span> Create Your Design</button>
                                                                </div>
                                                            ) : (null)}
                                                        </>
                                                    )}


                                            </div>
                                        </Col>
                                    </div>
                                    <Col lg={6} md={6} sm={12}>
                                        <div className='mt-4'>
                                            <div className='d-flex'><h4 style={{ fontWeight: '400', fontSize: '18px', font: 'Lato', color: '#262525' }}>Delivery</h4></div>
                                            <div className='mt-1 w-100'>
                                                <Input
                                                    type='tel'
                                                    value={inputValue}
                                                    onChange={handleChange}
                                                    style={{ width: '100%', color: '#858585' }}
                                                    placeholder="Enter 6-digit Pincode"
                                                    maxLength="6"
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6} md={6} sm={12}>
                                        {load ? (
                                            <Spinner>loading...</Spinner>
                                        ) : (
                                            inputValue?.length === 6 && (
                                                deliveryCheck?.deliveryCharge ? (
                                                    <>
                                                        <h6 className='mt-5'>
                                                            <TbTruckDelivery style={{ width: '30px', height: '30px' }} />
                                                            Standard Delivery :<br />
                                                            <span style={{ color: 'grey' }}>
                                                                Estimated delivery by {formattedDate}
                                                            </span>
                                                        </h6>
                                                    </>
                                                ) : (
                                                    <p className="text-danger mt-5">Delivery not available for this location</p>
                                                )
                                            )
                                        )}
                                    </Col>
                                </Row>
                            </Card >
                        </Col >
                    </Row >
                </div >
            )
            }
            <div className='des' style={{ paddingLeft: '5%', paddingRight: '5%', marginTop: '20px' }}>
                <div style={{ border: '1.5px solid #D5D5D5' }}>
                    <div style={{ borderBottom: '1.5px solid #DFDFDF' }} className='d-flex mt-3 mb-3 ms-3 me-3'>
                        <div style={getTabStyle('description')} onClick={handleDescriptionClick}>
                            <h5>Description</h5>
                        </div>
                        <div style={{ ...getTabStyle('faq'), marginLeft: '2rem' }} onClick={handleFaqClick}>
                            <h5>FAQ’s</h5>
                        </div>
                        <div style={{ ...getTabStyle('review'), marginLeft: '2rem' }} onClick={handleReviewClick}>
                            <h5>Review</h5>
                        </div>
                    </div>

                    {isDescription && (
                        <div className='ms-3 mb-3' style={{ marginTop: '20px' }}>
                            <div className='d-lg-block'>
                                <p className='pt-0' style={{ cursor: 'pointer', color: '#858585' }}>
                                    <span>{dataa?.fullDescription}</span>
                                </p>
                            </div>
                            {dataa?.description ? (
                                <div className='d-none d-lg-block mt-2'>
                                    {displayedDescriptions2?.map((eachItem, i) => (
                                        <div key={i} style={{ display: 'flex', alignItems: 'start' }}>
                                            <span style={{ color: '#E4510B', marginRight: '8px' }}><GoDotFill /></span>
                                            <p style={{ fontFamily: 'Lato', fontWeight: '400', fontSize: '16px', color: '#858585', margin: 0 }} className='pt-0'>
                                                {eachItem?.description}
                                            </p>
                                        </div>
                                    ))}
                                    {descriptions1?.length > 2 && (
                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                                            <button style={{ border: 'none', background: 'none', textDecoration: 'underline', color: '#E4510B', fontsize: '14px' }} onClick={handleToggleShowAll2}>
                                                {showAll2 ? 'See Less' : 'See More'}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            ) : (null)}
                        </div>
                    )}
                    {isReview && (
                        // <div className='ms-3 mb-3' style={{ marginTop: '20px' }}>
                        //     <div className='d-lg-block'>
                        //         <p className='pt-0' style={{ cursor: 'pointer' }}>
                        //             <span>There is no review for this product</span>
                        //         </p>
                        //     </div>
                        // </div>
                        <div className='mt-5 position-relative'>
                            <Slider {...settings} className="custom-slider" ref={sliderRef}>
                                {review?.result?.map((eachDetail) => (
                                    <div className='pt-5 pb-5' key={eachDetail.id}>
                                        <Card className='testmonialsCard mt-1 mb-5'>
                                            <div className='d-flex justify-content-between'>
                                                <div className='d-block'>
                                                    <img src={man1} className='testimonialImage img-fluid' alt='image' />
                                                    <div>
                                                        <h5 className='pt-3 ms-2'><b>{eachDetail?.user?.name}</b></h5>
                                                        <p className='testimonialLocation ms-2 d-flex'>
                                                            <span className='ms-1'>
                                                                {new Date(eachDetail.updatedAt).toLocaleDateString('en-GB', {
                                                                    day: '2-digit',
                                                                    month: 'long',
                                                                    year: 'numeric'
                                                                })}
                                                            </span>
                                                        </p>

                                                    </div>
                                                </div>
                                                <div className='testimonialRatings' style={{ marginTop: '-3px' }}>
                                                    {/* <img className='testimonialRatings1' src={star} alt="star1" />
                                                    <img className='ms-1 testimonialRatings1' src={star} alt="star2" />
                                                    <img className='ms-1 testimonialRatings1' src={star} alt="star3" />
                                                    <img className='ms-1 testimonialRatings1' src={star} alt="star4" />
                                                    <img className='ms-1 testimonialRatings1' src={star5} alt="star5" /> */}
                                                    {/* Render full stars */}
                                                    {[...Array(fullStars)].map((_, index) => (
                                                        <img key={index} src={star} alt={`star${index + 1}`} className='ms-1 testimonialRatings1' />
                                                    ))}

                                                    {/* Render half star if necessary */}
                                                    {halfStar && <img src={star5} alt="half-star" className='ms-1 testimonialRatings1' />}

                                                    {/* Render remaining empty stars */}
                                                    {[...Array(totalStars - fullStars - (halfStar ? 1 : 0))].map((_, index) => (
                                                        <img key={index} src={emptyStarIcon} alt={`empty-star${index + 1}`} className='ms-1 testimonialRatings1' />
                                                    ))}
                                                </div>
                                            </div>
                                            <div>
                                                <img style={{ width: '100%' }} src={review1} alt="" />
                                            </div>
                                            <p className='testimonialDescription text-center'>{eachDetail?.review}</p>
                                        </Card>
                                    </div>
                                ))}
                            </Slider>
                            <div className="custom-nav-buttons mb-5 d-flex justify-content-end me-5">
                                <div onClick={prevSlide}>
                                    <button style={{ background: '#FBD7C6', color: '#E4510B', border: 'none', borderRadius: '50%', width: '50px', height: '50px' }} className="prev-button"><FiArrowLeft className='d-flex align-items-center justify-content-center' size={35} /></button>
                                </div>
                                <div className='ms-4' onClick={nextSlide}>
                                    <button style={{ background: '#FBD7C6', color: '#E4510B', border: 'none', borderRadius: '50%', width: '50px', height: '50px' }} className="next-button"><FiArrowRight className='d-flex align-items-center justify-content-center' size={35} /></button>
                                </div>
                            </div>
                        </div>

                    )}
                    {isFaq && (
                        <div className='' style={{ marginTop: '20px', paddingLeft: '2%', paddingRight: '2%' }}>
                            <div className='mb-3'>
                                <Row>
                                    {dataa?.FAQ?.map((details, i) => (
                                        <Col lg={6} md={6} sm={12} key={details?.id} className="mt-4">
                                            <Accordion open={open} toggle={toggleAccordion}>
                                                <AccordionItem>
                                                    <AccordionHeader targetId={i}>
                                                        <h5>{truncateTitle(details?.title)}</h5>
                                                    </AccordionHeader>
                                                    <AccordionBody accordionId={i}>
                                                        {details?.description}
                                                    </AccordionBody>
                                                </AccordionItem>
                                            </Accordion>
                                        </Col>
                                    ))}
                                </Row>

                            </div>
                        </div>
                        // <Col>
                        //     <div style={{  }} >
                        //         {style.map((item) => (
                        //             <Accordion
                        //                 className='mt-4'
                        //                 expanded={expanded === item.id}
                        //                 onChange={handleChange1(item.id)}
                        //                 key={item.id}
                        //             >
                        //                 <AccordionSummary
                        //                     style={{ height: '80px', borderBottom: '1px solid #ddd' }}
                        //                     expandIcon={expanded === item.id ? <FaMinus /> : <FaPlus />}
                        //                     aria-controls={`${item.id}-content`}
                        //                     id={`${item.id}-header`}
                        //                 >
                        //                     <Typography>{item.question}</Typography>
                        //                 </AccordionSummary>
                        //                 <AccordionDetails style={{ padding: '0 10px', borderTop: '1px solid #ddd' }}>
                        //                     <div style={{ padding: '10px 0' }}>
                        //                         <Typography style={{ color: '#666666' }}>{item.answer}</Typography>
                        //                     </div>
                        //                 </AccordionDetails>
                        //             </Accordion>
                        //         ))}
                        //     </div>
                        // </Col>
                    )}
                </div>
            </div>
            <div style={{ paddingBottom: '10%' }}>
                {
                    dataa1?.length > 1 ? (
                        <div style={{ paddingLeft: '5%', paddingRight: '5%', marginTop: '20px' }}>
                            <h4 style={{ font: 'Manrope', fontWeight: '700', fontSize: '32px', display: 'flex', justifyContent: "center", textAlign: "center", color: '#2B2B2D' }}>Related Products</h4>
                            {dataa1?.length === 0 ? (
                                <div>
                                    <h2 className="text-center pt-3 mb-5" style={{ color: "grey" }}>
                                        "Oops! It seems like we're out of stock in this category. <br />{" "}
                                        Explore other categories or visit us again soon."
                                    </h2>
                                </div>
                            ) : (
                                <div className=' d-lg-block'>
                                    <div className=' d-lg-flex'>
                                        {dataa1?.slice(0, showAll1 ? dataa1.length : 5).map((eachItem, index) => {
                                            if (dataa?._id !== eachItem?._id) {
                                                return (
                                                    <Col sm={12} md={6} lg={4} xl={3} key={index} className="mb-4">
                                                        <Card
                                                            className="fast-card"
                                                            style={{
                                                                border: "none",
                                                                marginTop: "4%",
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <div className='ms-lg-3 mt-4' style={{ border: '1px solid #F0F0F0', borderRadius: '10px', boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)" }}>
                                                                <div style={{ padding: '5%' }}>
                                                                    <div style={{ width: '100%' }} className='d-flex'>
                                                                        <div className='position-relative w-100'>
                                                                            <img
                                                                                src={eachItem?.image}
                                                                                style={{
                                                                                    borderRadius: "4px",
                                                                                    height: '250px',
                                                                                    width: "100%"
                                                                                }}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        {/* <div className='heart-like' style={{ border: 'none', position: 'absolute', right: '0', marginRight: '30px', margintop: '10px' }}>
                                                                        {isLogin ? (
                                                                            <div className="con-like">
                                                                                <input
                                                                                    className="like"
                                                                                    defaultChecked={eachItem?.wishList}
                                                                                    onClick={() => likeProduct1(eachItem?._id)}
                                                                                    type="checkbox"
                                                                                    title="like"
                                                                                />
                                                                                <div className="checkmark">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="outline" viewBox="0 0 24 24">
                                                                                        <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Zm-3.585,18.4a2.973,2.973,0,0,1-3.83,0C4.947,16.006,2,11.87,2,8.967a4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,11,8.967a1,1,0,0,0,2,0,4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,22,8.967C22,11.87,19.053,16.006,13.915,20.313Z"></path>
                                                                                    </svg>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="filled" viewBox="0 0 24 24">
                                                                                        <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Z"></path>
                                                                                    </svg>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" height="100" width="100" className="celebrate">
                                                                                        <polygon className="poly" points="10,10 20,20"></polygon>
                                                                                        <polygon className="poly" points="10,50 20,50"></polygon>
                                                                                        <polygon className="poly" points="20,80 30,70"></polygon>
                                                                                        <polygon className="poly" points="90,10 80,20"></polygon>
                                                                                        <polygon className="poly" points="90,50 80,50"></polygon>
                                                                                        <polygon className="poly" points="80,80 70,70"></polygon>
                                                                                    </svg>
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <div className='' onClick={toggle}>
                                                                                <img color='#FFFFFF' src={heart} alt={heart} height={40} width={40} />
                                                                            </div>
                                                                        )}
                                                                    </div> */}
                                                                    </div>
                                                                    <div className="d-block mt-3">
                                                                        <div className='mt-2'>
                                                                            <h6
                                                                                style={{
                                                                                    fontSize: "18px",
                                                                                    cursor: "pointer",
                                                                                    whiteSpace: "nowrap",
                                                                                    overflow: "hidden",
                                                                                    textOverflow: "ellipsis",
                                                                                    color: '#2B2B2D'
                                                                                }}
                                                                            >
                                                                                {eachItem?.name?.substring(0, 30)}{" "}
                                                                                {eachItem?.name?.length > 30 ? "..." : ""}
                                                                            </h6>
                                                                            <p
                                                                                style={{
                                                                                    fontSize: "16px",
                                                                                    cursor: "pointer",
                                                                                    lineHeight: '24px',
                                                                                    color: '#858585',
                                                                                    whiteSpace: "nowrap",
                                                                                    overflow: "hidden",
                                                                                    textOverflow: "ellipsis",
                                                                                }}
                                                                            >
                                                                                {eachItem?.productCategory?.name?.substring(0, 30)}{" "}
                                                                                {eachItem?.productCategory?.name?.length > 30 ? "..." : ""}
                                                                            </p>
                                                                        </div>
                                                                        <div style={{ marginTop: '-10px' }}>
                                                                            <img src={star} alt="star1" />
                                                                            <img className='ms-1' src={star} alt="star2" />
                                                                            <img className='ms-1' src={star} alt="star3" />
                                                                            <img className='ms-1' src={star} alt="star4" />
                                                                            <img className='ms-1' src={star5} alt="star5" />
                                                                        </div>
                                                                        <div className="mt-3">
                                                                            <p
                                                                                className="m-0"
                                                                                style={{
                                                                                    fontSize: "14px",
                                                                                    color: '#37A003',
                                                                                    whiteSpace: "nowrap",
                                                                                    overflow: "hidden",
                                                                                    textOverflow: "ellipsis",
                                                                                    maxWidth: "100%",
                                                                                }}
                                                                            >
                                                                                As Low As
                                                                            </p>
                                                                            <div className='d-flex justify-content-between'>
                                                                                <div className="d-block">
                                                                                    <div className="d-flex">
                                                                                        <h2
                                                                                            className=""
                                                                                            style={{
                                                                                                fontSize: `${eachItem?.mimimumAmount &&
                                                                                                    eachItem.mimimumAmount.toString().length > 1
                                                                                                    ? eachItem.mimimumAmount.toString().length > 4
                                                                                                        ? "18px"
                                                                                                        : "24px"
                                                                                                    : "24px"
                                                                                                    }`,
                                                                                                textAlign: `${eachItem?.mimimumAmount &&
                                                                                                    eachItem.mimimumAmount.toString().length === 1
                                                                                                    ? "center"
                                                                                                    : "center"
                                                                                                    }`,
                                                                                                color: '#37A003'
                                                                                            }}
                                                                                        >
                                                                                            {/* ₹{eachItem?.mimimumAmount * eachItem.100} */}

                                                                                            {eachItem?.offer === true ? ((eachItem?.offerAmount * eachItem?.minimunQuantity || 0).toLocaleString('en-IN', {
                                                                                                maximumFractionDigits: 0,
                                                                                                style: 'currency',
                                                                                                currency: 'INR',
                                                                                            })) : ((eachItem?.mimimumAmount * eachItem?.minimunQuantity || 0).toLocaleString('en-IN', {
                                                                                                maximumFractionDigits: 0,
                                                                                                style: 'currency',
                                                                                                currency: 'INR',
                                                                                            }))}

                                                                                        </h2>
                                                                                        {eachItem?.offer === true ? (<span style={{ fontSize: "16px", display: 'flex', marginTop: '2px' }} className='ms-2 old-price'>{(eachItem?.mimimumAmount * eachItem?.minimunQuantity).toLocaleString('en-IN', {
                                                                                            maximumFractionDigits: 0,
                                                                                            style: 'currency',
                                                                                            currency: 'INR',
                                                                                        })}</span>) : (null)}
                                                                                    </div>

                                                                                    <span style={{ color: '#858585', fontSize: "16px", display: 'flex' }} className=''>For {eachItem?.minimunQuantity} Pieces</span>
                                                                                </div>
                                                                                <Link
                                                                                    to={`/products/viewProduct/${eachItem?.product_url}`}
                                                                                    className="" style={{ textDecoration: 'none' }}
                                                                                >
                                                                                    <div className="mt-4" style={{ width: '100%' }}>
                                                                                        <button className='ms-4 ' style={{ width: '100px', height: '40px', marginTop: '-30px', fontSize: "14px", fontWeight: '700', font: 'Lato', color: 'white', background: '#E4510B', borderRadius: '4px', border: 'none', alignItems: 'start' }} onClick={() => navToViewPage(eachItem?.product_url)}>Shop Now<FaAngleRight size={15} /></button>
                                                                                    </div>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    </Col>
                                                );
                                            } else {
                                                return null; // or some placeholder if you want to show something else
                                            }
                                        })}
                                    </div>
                                    <div>
                                        {!showAll1 && dataa1?.length > 4 && (
                                            <div style={{ textAlign: 'start', marginTop: '30px' }}>
                                                <button onClick={handleViewAll} style={{ padding: '10px 20px', backgroundColor: 'transparent', color: '#E4510B', border: 'none', borderRadius: '4px', fontWeight: '500', fontSize: '24px', lineHeight: '36px', font: 'Poppins' }}>
                                                    <ArrowLeft /> View All Products
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )
                            }

                        </div >
                    ) : (null)
                }
            </div>


            {
                modal1 && (
                    <Modal isOpen={modal1} toggle={toggle1} size="lg">
                        <ModalHeader toggle={toggle1} style={{ borderBottom: 'none' }}></ModalHeader>
                        <Form>
                            <ModalBody>
                                <div className='d-block p-5'>
                                    <div>
                                        <h5 style={{ fontweight: '600', font: 'Lato', fontsize: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '-40px' }}>Upload your files</h5> <br />
                                        <p style={{ font: 'Lato', fontWeight: '400', color: '#858585', fontSize: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Please Check the guidelines before uploading the files</p> <br />
                                        <p style={{ font: 'Lato', fontWeight: '400', fontSize: '16px', color: '#646464', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '-20px' }}>Supported file formats : PNG, JPG, PSD, AI, PDF, SVG, CDR, DOCX, EPS</p>
                                        <p style={{ font: 'Lato', fontWeight: '400', fontSize: '16px', color: '#646464', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>(Maximum 5 MB /Minimum 224px x 224px)</p>
                                    </div>
                                    <div>
                                        <Card className='' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '320px', border: '2px dashed #E4510B', borderRadius: '4px' }} onDrop={handleDrop} onDragOver={handleDragOver}>
                                            {loader ? (
                                                <Spinner color="gray" />
                                            ) : (
                                                <div className="d-block">
                                                    <div style={{ cursor: 'pointer' }}>
                                                        <input
                                                            style={{ display: 'none' }}
                                                            type="file"
                                                            ref={inputRef1}
                                                            accept=".jpeg,.png,.jpg,.pdf,.doc,.docx,.xls,.xlsx,.txt,.zip,.rar,.csv,.ppt,.pptx,.gif,.svg,.bmp"
                                                            onChange={handleProfileUpload}
                                                            multiple
                                                        />

                                                        {profileImages.length === 0 ? (
                                                            <div
                                                                style={{ height: '320px', width: '670px' }}
                                                                className='text-center text-primary'
                                                                onClick={() => inputRef1?.current?.click()}
                                                            >
                                                                <>
                                                                    <h1>
                                                                        <RiImageAddFill style={{ color: '#8B8B8B', width: '80px', height: '80px', marginTop: '50px' }} />
                                                                    </h1>
                                                                    <h5 className='mt-3' style={{ color: '#2B2B2D' }}>Drag and drop a file here</h5>
                                                                    <h6 className='mt-3' style={{ color: '#2B2B2D' }}>or</h6>
                                                                    <div className='mt-3' style={{ display: 'flex', justifyContent: "center", textAlign: "center" }}>
                                                                        <h4
                                                                            style={{
                                                                                width: '250px',
                                                                                height: '40px',
                                                                                fontSize: "17px",
                                                                                fontWeight: '700',
                                                                                fontFamily: 'Lato',
                                                                                color: 'white',
                                                                                background: '#E4510B',
                                                                                border: '1px solid #E4510B',
                                                                                lineHeight: '21px'
                                                                            }}
                                                                            className='btn'
                                                                        // onClick={() => inputRef1?.current?.click()}
                                                                        >
                                                                            Select a file
                                                                        </h4>
                                                                    </div>
                                                                </>

                                                            </div>
                                                        ) : (
                                                            <>
                                                                {/* Render the uploaded images or any other content here */}
                                                            </>

                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </Card>
                                    </div>
                                </div>
                            </ModalBody >
                        </Form>
                    </Modal >)
            }

            {/* <MobileOTPModal isOpen={modal} toggleOTP={toggle} setCartKey={'yes'} /> */}
            <FinalAuthModal isOpen={isToggle} toggle={toggleEmail} />
            {/* <InteractiveHome /> */}
            <FooterSection />
        </>
    )
}

export default ProductView