import React, { useEffect, useState } from 'react'
import Navbar from '../navbar'
import FooterSection from '../footer'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { getOfferProduct } from '../../ApiConfigs/ApiConfig';
import { Card, Col, Row, Spinner } from 'reactstrap';
import './index.css';
import { FaAngleRight } from "react-icons/fa6";
import star from "../../assets/images/logo/Star 2.png"
import star5 from "../../assets/images/logo/Star 5.png"

function OfferTargets() {


    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const navigate = useNavigate()
    const firstObject = data[0]
    const additionalData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${getOfferProduct}`)
            setData(response?.data?.result)

        } catch (error) {

        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {

        additionalData()


    }, [])


    const navToHome = () => {
        navigate('/')
    }

    const navToProduct = () => {
        navigate('/products')
    }

    const navToPage = (id) => {
        navigate(`/products/viewProduct/${id}`)
    }

    const [showFullDescription, setShowFullDescription] = useState(false);

    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };

    const truncateDescription = (description, maxLength) => {
        return description.length > maxLength
            ? `${description.slice(0, maxLength)}...`
            : description;
    };
    const navToViewPage = (product_url) => {
        navigate(`/products/viewProduct/${product_url}`);
    };



    return (
        <div>
            <Navbar />
            {loading ? (
                <div style={{ height: "80vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Spinner
                        color="primary"

                    >
                        Loading...
                    </Spinner>
                </div>
            ) : (
                <div className='pt-3' style={{ paddingLeft: '8%', paddingRight: '8%', paddingBottom: '10%' }}>
                    <p className='pt-3' style={{ cursor: 'pointer', color: '#333333', opacity: '0.8' }}><span onClick={navToHome}>Home</span> / <span style={{ color: '#E4510B' }}>Offer Products</span></p>
                    {/* <h1 className='product-overall-title'>Offer Products</h1> */}
                    <div style={{ width: '100%' }} className='d-flex align-items-center mt-5'>
                        <h1 className='product-overall-title'>Offer Products</h1>
                        <hr className='ms-3 call2' style={{ height: '2px', width: '100%', background: 'black' }} />
                    </div>
                    <div className='product-card-con'>
                        <Row>
                            {data?.map((eachItem, i) => (
                                <Col sm={12} md={6} lg={4} xl={3} key={i}>
                                    <Card className='mt-3'
                                        onClick={() => navToViewPage(`${eachItem?.product?.product_url}`)}
                                        style={{ border: '1px solid #F0F0F0', borderRadius: '15px', cursor: 'pointer' }}
                                    >
                                        <div>
                                            <div style={{ padding: '5%' }}>
                                                <div style={{ width: '100%' }} className='d-flex'>
                                                    <div className='position-relative w-100'>
                                                        <img
                                                            src={eachItem?.product?.image}
                                                            style={{
                                                                borderRadius: "4px",
                                                                height: '250px',
                                                                width: "100%"
                                                            }}
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                                <div className="d-block mt-3">
                                                    <div className='mt-2'>
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <h6
                                                                    style={{
                                                                        fontSize: "18px",
                                                                        cursor: "pointer",
                                                                        whiteSpace: "nowrap",
                                                                        overflow: "hidden",
                                                                        textOverflow: "ellipsis",
                                                                        color: '#2B2B2D'
                                                                    }}
                                                                >
                                                                    {eachItem?.product?.name?.substring(0, 15)}{" "}
                                                                    {eachItem?.product?.name?.length > 15 ? "..." : ""}
                                                                </h6>
                                                            </div>
                                                            <div style={{ marginTop: '-3px' }}>
                                                                <img src={star} alt="star1" />
                                                                <img className='ms-1' src={star} alt="star2" />
                                                                <img className='ms-1' src={star} alt="star3" />
                                                                <img className='ms-1' src={star} alt="star4" />
                                                                <img className='ms-1' src={star5} alt="star5" />
                                                            </div>
                                                        </div>
                                                        <p
                                                            style={{
                                                                fontSize: "16px",
                                                                cursor: "pointer",
                                                                lineHeight: '24px',
                                                                color: '#858585',
                                                                whiteSpace: "nowrap",
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                            }}
                                                        >
                                                            {eachItem?.product?.productCategory?.name?.substring(0, 30)}{" "}
                                                            {eachItem?.product?.productCategory?.name?.length > 30 ? "..." : ""}
                                                        </p>
                                                    </div>
                                                    <div className="mt-3">
                                                        <p
                                                            className="m-0"
                                                            style={{
                                                                fontSize: "14px",
                                                                color: '#37A003',
                                                                whiteSpace: "nowrap",
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                                maxWidth: "100%",
                                                            }}
                                                        >
                                                            As Low As
                                                        </p>
                                                        <div className='d-flex justify-content-between'>
                                                            <div className="d-flex flex-column ">
                                                                <h2
                                                                    className=""
                                                                    style={{
                                                                        fontSize: `${eachItem?.price &&
                                                                            eachItem?.price.toString().length > 1
                                                                            ? eachItem?.price.toString().length > 4
                                                                                ? "18px"
                                                                                : "24px"
                                                                            : "24px"
                                                                            }`,
                                                                        textAlign: `${eachItem?.price &&
                                                                            eachItem?.price.toString().length === 1
                                                                            ? "start"
                                                                            : "start"
                                                                            }`,
                                                                        color: '#37A003',
                                                                    }}
                                                                >
                                                                    {/* ₹{eachItem?.mimimumAmount * eachItem.100} */}

                                                                    {eachItem?.product?.offer === true ? ((eachItem?.price * eachItem?.product?.minimunQuantity || 0).toLocaleString('en-IN', {
                                                                        maximumFractionDigits: 0,
                                                                        style: 'currency',
                                                                        currency: 'INR',
                                                                    })) : ((eachItem?.price * eachItem?.product?.minimunQuantity || 0).toLocaleString('en-IN', {
                                                                        maximumFractionDigits: 0,
                                                                        style: 'currency',
                                                                        currency: 'INR',
                                                                    }))}

                                                                </h2>

                                                                <span style={{ color: '#858585', fontSize: "16px", display: 'flex', marginTop: '0px' }} className=''>For {eachItem?.product?.minimunQuantity} Pieces</span>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <Link
                                                                    to={`/products/viewProduct/${eachItem?.product?.product_url}`}
                                                                    className="" style={{ textDecoration: 'none' }}
                                                                >
                                                                    <button style={{ minWidth: '100px' }} className='ms-4 show-now' onClick={() => navToViewPage(eachItem?.product?.product_url)}>Shop Now <FaAngleRight size={15} /></button>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>

                            ))}
                        </Row>
                    </div>
                </div>
            )}

            <FooterSection />
        </div>
    )
}

export default OfferTargets